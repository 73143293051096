package net.oc_soft.mswp.ui

import kotlin.math.pow

import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.Float32Array

/**
 * manage blinking anamation
 */
class Blinking {


    /**
     * class instance
     */
    companion object {
            
        /**
         * set databuffer into buffer which is WebGLBuffer
         */
        fun attachDataBuffer(gl: WebGLRenderingContext,
            buffer: WebGLBuffer,
            bufferData: Float32Array) {
            val savedBuffer = gl.getParameter(
                WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?

            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, buffer)
            gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
                bufferData,
                WebGLRenderingContext.STATIC_DRAW)

            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer)
        }
     }

    /**
     * gradient color from dark to blight
     */
    var darkBrightColors: Array<Float32Array>? = null


    /**
     * count of colors
     */
    val countOfColors: Int
        get() {
            val colors = this.darkBrightColors
            var result = 0
            if (colors != null) {
                result = colors.size
            }
            return result
        }
    /**
     * count of frames
     */
    val countOfFrames: Int
        get() = countOfColors * 2 - 1

    /**
     * count of the blank among frames
     */
    val countOfDisplacement: Int
        get() = countOfFrames -1 

    /**
     * color frame index
     */
    private var colorFrameIndex: Int = 0


    /**
     * color index
     */
    private var colorIndex: Int = 0
    

    /**
     * you get true if blinking animation is running.
     */
    var active: Boolean = false


    
    /**
     *  next frame ation
     */
    var nextFrameAction: (()->Unit) = {}

    /**
     * proceed a frame
     */
    fun nextFrame(
        gl: WebGLRenderingContext,
        buffer: WebGLBuffer) {

        val colors = nextFrame()
        if (colors != null) {
            attachDataBuffer(gl, buffer, colors)
        }   
    }

    /**
     * synchronize color 
     */
    fun syncColorsWithColorIndex(
        gl: WebGLRenderingContext,
        buffer: WebGLBuffer) {
        val colors = currentFrame()
        if (colors != null) {
            attachDataBuffer(gl, buffer, colors)
        }
    }
    


    /**
     * proceed frame
     */
    fun nextFrame(): Float32Array? {
        var result: Float32Array? = null
        val darkBrightColors = this.darkBrightColors
        if (active && darkBrightColors != null) {
            val countOfColors = this.countOfColors
            if (countOfColors > 0) {
                
                result = currentFrame()

                val countOfDisplacement = this.countOfDisplacement
                var colorFrameIndex = this.colorFrameIndex 
                val darkerIndicesCount = countOfDisplacement / 2
                val dirIndex = colorFrameIndex / darkerIndicesCount
                val displacement = (-1f).pow(dirIndex.toFloat()).toInt()

                var colorIndex = this.colorIndex
                colorIndex += displacement
                this.colorIndex = colorIndex
                colorFrameIndex++ 
                colorFrameIndex %= countOfDisplacement 
                this.colorFrameIndex = colorFrameIndex
                nextFrameAction()
            }
        } 
        return result
    }


    /**
     * get current frame
     */
    fun currentFrame(): Float32Array? {
        var result: Float32Array? = null
        val darkBrightColors = this.darkBrightColors
        if (active && darkBrightColors != null) {
            val countOfColors = this.countOfColors
            if (countOfColors > 0) {
                result = darkBrightColors[colorIndex]
            }
        } 
        return result
    }

    
    
    /**
     * start at darkest index
     */
    fun startAtDark() {
        if (!active) {
            colorFrameIndex = 0
            colorIndex = 0
            active = true
        }
    }

    /**
     * start at brightest index
     */
    fun startAtBright() {
        if (!active) {
            colorFrameIndex = countOfDisplacement / 2 
            colorIndex = countOfColors - 1 
            active = true
        }
    }

    /**
     * stop at britest frame
     */
    fun stopAtBright() {
        val countOfColors = this.countOfColors
        if (countOfColors > 0) {
            stopAt(countOfColors - 1)
        }
    }


    /**
     * stop at darkest frame
     */
    fun stopAtDark() {
        stopAt(0)
    }

    /**
     * stop at a frame index
     */
    fun stopAt(index: Int) {
        val resetAction: (()->Unit) = { }
        val stopAction: (()->Unit) = { 
            var colorIndex = colorIndex % (2 * countOfColors)
            if (colorIndex == index) {
                active = false
                nextFrameAction = resetAction 
            }
        } 
        nextFrameAction = stopAction
    }


    /**
     * get string representation
     */
    override fun toString(): String {
        return "colorIndex: ${colorIndex}"
    }
}
// vi: se ts=4 sw=4 et:
