package net.oc_soft.mswp

import kotlin.math.pow

/**
 * represent pointing light
 */
class PointLight(
    point: FloatArray = floatArrayOf(-1f, 1f, 2.5f),
    light: Light = Light(
        floatArrayOf(1f, 1f, 1f),
        floatArrayOf(1f, 1f, 1f),
        floatArrayOf(1f, 1f, 1f))) {

    companion object {
        /**
         * format point value
         */
        fun formatPointValue(value: Float): Float {
            var result = kotlin.math.round(value * 10f.pow(5))
            result = result * 10f.pow(-5)
            return result 
        }

        /**
         * format point
         */
        fun formatPoint(point: FloatArray): FloatArray {
            return FloatArray(point.size) {
                formatPointValue(point[it])
            }
        }
    }
    /**
     * light origin
     */
    var point = point.copyOf()
        set (value) {
            for (i in 0 until kotlin.math.min(field.size, value.size)) {
                field[i] = formatPointValue(value[i])
            } 
        }
        get() {
            return field.copyOf()
        }


    /**
     * represent light source
     */
    var light = Light(light)    
        set(value) {
            field.copy(value)
        }

}
// vi: se ts=4 sw=4 et:
