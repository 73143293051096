package net.oc_soft.mswp.ui

import org.khronos.webgl.Float32Array
import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.WebGLProgram

import net.oc_soft.mswp.Polygon
import net.oc_soft.gl.Viewport


/**
 * screen on which you see image.
 */
class Screen {


    /**
     * class instance
     */
    companion object {

        
        
        /**
         * calculate viewport
         */
        fun calcViewport(
            grid: Grid,
            gl: WebGLRenderingContext): IntArray {
            val viewRatioInScene = grid.calcCanvasViewportRatioInScene(gl)
            val devViewRatio = FloatArray(viewRatioInScene.size) {
                viewRatioInScene[it] * 2 - 1f
            }
            val screenSize = grid.getScreenSize(gl)
            val viewSize = FloatArray(2) {
                screenSize[it] * 2f / 
                    (devViewRatio[it + 2] - devViewRatio[it]) 
            }
            val viewLoc = FloatArray(2) {
                - viewSize[it] * (devViewRatio[it] + 1) / 2f
            } 
            val result = intArrayOf(
                kotlin.math.round(viewLoc[0]).toInt(),
                kotlin.math.round(viewLoc[1]).toInt(),
                kotlin.math.round(viewSize[0]).toInt(),
                kotlin.math.round(viewSize[1]).toInt())
            return result
        } 
    }

    /**
     * screen size
     */
    internal val screenSize = floatArrayOf(2f, 2f)

    val leftBottomScreen: FloatArray
        get() {
            return floatArrayOf(-screenSize[0] / 2f, -screenSize[1] / 2f)
        }

    /**
     * vertex coordinate
     */
    val coordinates = Polygon.divideSquare2d2(screenSize, 1, 1)


    /**
     * texture coordinates
     */
    val textureCoordinates: FloatArray
        get() {
            val coordinates = 
                Polygon.divideSquare2d2(floatArrayOf(1f, 1f), 1, 1)
            return FloatArray(coordinates.size) {
                coordinates[it] + 0.5f
            }
        }


    /**
     * coordinate
     */
    val countOfCoordinates: Int
        get() {
            return coordinates.size / 2
        } 

    /**
     * vertex coordinates
     */
    val coordinatesForGl : Float32Array
        get() {
            val coordinates = this.coordinates
            val result = Float32Array(
                Array<Float>(coordinates.size, { coordinates[it] }))
            return result
        } 


    /**
     * texture coordinates for gl
     */
    val textureCoordinatesForGl: Float32Array
        get() {
            val coordinates = this.textureCoordinates
            val result = Float32Array(
                Array<Float>(coordinates.size, { coordinates[it] }))
            return result
        }

    /**
     * scene texture index
     */
    val sceneTextureIndex: Int
        get() {
            return Textures.sceneTextureIndex
        } 

    /**
     * effect texture index
     */
    val effectTextureIndex: Int
        get() {
            return Textures.effectTextureIndex
        }

    /**
     * setup rendering context
     */
    fun setup(
        grid: Grid,
        gl: WebGLRenderingContext) {

        grid.renderingCtx.screenVerticesBuffer = createScreenVertexBuffer(gl) 
        grid.renderingCtx.screenTextureCoordinatesBuffer =
            createScreenTextureCoordinatesBuffer(gl) 
         
    }


    /**
     * create screen vertex array buffer.
     */
    fun createScreenVertexBuffer(gl: WebGLRenderingContext): WebGLBuffer {
        val result = gl.createBuffer()!!
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, result)

        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            coordinatesForGl, 
            WebGLRenderingContext.STATIC_DRAW) 

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER,
            savedBuffer) 
        return result
    }

    /**
     * create screen texture coodinates buffer.
     */
    fun createScreenTextureCoordinatesBuffer(
        gl: WebGLRenderingContext): WebGLBuffer {
        val result = gl.createBuffer()!!
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, result)

        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            textureCoordinatesForGl, 
            WebGLRenderingContext.STATIC_DRAW) 

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer) 
        return result
    }

    /**
     * clear context
     */
    fun clearFramebuffer(
        grid: Grid,
        gl: WebGLRenderingContext) {
        grid.setupEnv(gl)
        // gl.clearColor(0f, 0f, 1f, 1f) 
    }

    /**
     * draw
     */
    fun draw(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val screenProg = grid.renderingCtx.screenShaderProgram  
        if (screenProg != null) {
            val savedProgram = gl.getParameter(
                WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
            gl.useProgram(screenProg)
            clearFramebuffer(grid, gl)
            drawI(grid, gl, true)
            gl.useProgram(savedProgram) 
        }
    }


    /**
     * draw screen
     */
    fun drawI(
        grid: Grid,
        gl: WebGLRenderingContext,
        enableEffect: Boolean = false) {
        val prog = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?
        val savedActiveTexture = gl.getParameter(
            WebGLRenderingContext.ACTIVE_TEXTURE) as Int 
        if (prog != null) {
            val uSceneLoc = gl.getUniformLocation(prog, "uSceneSampler")
            if (uSceneLoc != null) {
                var sceneTxtNum = sceneTextureIndex
                gl.activeTexture(sceneTxtNum)
                gl.bindTexture(WebGLRenderingContext.TEXTURE_2D,
                    grid.renderingCtx.sceneTexture)
                sceneTxtNum -= WebGLRenderingContext.TEXTURE0
                gl.uniform1i(uSceneLoc, sceneTxtNum)
            }

            val uEffectLoc = gl.getUniformLocation(prog, "uEffectSampler")
            var enableEffect0 = enableEffect 
            if (uEffectLoc != null) {
                var effectTxtNum = effectTextureIndex
                gl.activeTexture(effectTxtNum)
                gl.bindTexture(WebGLRenderingContext.TEXTURE_2D,
                    grid.renderingCtx.effectTexture)
                effectTxtNum -= WebGLRenderingContext.TEXTURE0
                gl.uniform1i(uEffectLoc, effectTxtNum)
            } else {
                enableEffect0 = false
            }
            val uEnableEffectLoc = gl.getUniformLocation(prog, "uEnableEffect")
            if (uEnableEffectLoc != null) {
                gl.uniform1i(uEnableEffectLoc,
                    if (enableEffect0) { 1 } else { 0 }) 
            }

            val texLoc = gl.getAttribLocation(prog, "aTextureCoord")
            if (texLoc >= 0) {
                gl.bindBuffer(
                    WebGLRenderingContext.ARRAY_BUFFER,
                    grid.renderingCtx.screenTextureCoordinatesBuffer)
                gl.vertexAttribPointer(
                    texLoc, 2, WebGLRenderingContext.FLOAT,
                    false, 0, 0)
                gl.enableVertexAttribArray(texLoc)
            }
              
            val vposLoc = gl.getAttribLocation(prog, "aVertexPosition")
            if (vposLoc >= 0) { 
                gl.bindBuffer(
                    WebGLRenderingContext.ARRAY_BUFFER,
                    grid.renderingCtx.screenVerticesBuffer)
                gl.enableVertexAttribArray(vposLoc)
                gl.vertexAttribPointer(
                    vposLoc, 2, WebGLRenderingContext.FLOAT,
                    false, 0, 0)
                gl.drawArrays(WebGLRenderingContext.TRIANGLES, 0, 
                    countOfCoordinates)
            }
            if (vposLoc >= 0) {
                gl.disableVertexAttribArray(vposLoc)
            }
            if (texLoc >= 0) {
                gl.disableVertexAttribArray(texLoc)
            }
        }
        gl.activeTexture(savedActiveTexture)
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer)
    }


    /**
     * convert screen coordinate to scene window coordinate
     */
    private fun convertWindowToScreenDevice(
        grid: Grid,
        gl: WebGLRenderingContext,
        x: Int, y: Int): FloatArray {
        val screenViewport = grid.getScreenViewport(gl)
        return Viewport.toDeviceCoordinate(x, y, screenViewport)
    }


    /**
     * convert texture coordiate to scene window coordinate
     */
    fun windowToSceneWindowCoordinate(
        grid: Grid,
        gl: WebGLRenderingContext,
        x: Int, y: Int): FloatArray {
        val screenDeviceCoord = convertWindowToScreenDevice(grid, gl, x, y)
        val texCoord = deviceToTextureCoordinate(
            screenDeviceCoord[0], screenDeviceCoord[1])

        val sceneDeviceCoord = FloatArray(2) {
            texCoord[it] * 2f - 1f
        }
        val sceneViewport = grid.getSceneViewport(gl) 

        return Viewport.toWindowCoordinate(
            sceneDeviceCoord[0], sceneDeviceCoord[1],
            sceneViewport)
    } 

    



    /**
     * device to texture coordinate
     */
    fun deviceToTextureCoordinate(
        x: Float, y: Float): FloatArray {
        val lb = leftBottomScreen

        return floatArrayOf(
            (x - lb[0]) / screenSize[0], 
            (y - lb[1]) / screenSize[1])
    }
}


// vi: se ts=4 sw=4 et:
