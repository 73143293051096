package net.oc_soft.mswp.ui
import kotlin.collections.ArrayList
import kotlinx.browser.document
import org.w3c.dom.events.Event
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTemplateElement
import org.w3c.dom.Element
import org.w3c.dom.Node
import org.w3c.dom.get
import org.w3c.dom.set

/**
 * editor for point light
 */
class PointLightSetting(val option: Option) {

    /**
     * option for setting instance
     */
    data class Option(val syncIconTemplateQuery: String)
    /**
     * grid
     */
    var grid: Grid? = null
    

    /**
     * point lighting
     */
    val pointLight: net.oc_soft.mswp.PointLight?
        get() {
            var result: net.oc_soft.mswp.PointLight? = null
            val grid = this.grid
            if (grid != null) { 
                result = grid.pointLight
            }
            return result
        }
 
    /**
     * handler to back to main
     */
    var handleToBackToMain: ((Event)-> Unit)? = null


    /**
     * visible main menu
     */
    var visibleMainMenu: Boolean
        get() {
            return grid?.let {
                it.mainMenuItemQuery?.let {
                    document.querySelector(it)?.let {
                        val mainMenuItem = it as HTMLElement
                        mainMenuItem.style.visibility != "hidden"
                    }?: false
                }?: false
            }?: false
        }
        set(value) {
            if (visibleMainMenu != value) {
                grid?.let {
                    it.mainMenuItemQuery?.let {
                        document.querySelector(it)?.let {
                            val mainMenuItem = it as HTMLElement
                            mainMenuItem.style.visibility = 
                                if (value) "visible" else "hidden"
                        }
                    }
                }
            }
        }

    /**
     * visible back to main
     */
    var visibleBackToMain: Boolean
        get() {
            return grid?.let {
                it.backToMainQuery?.let {
                    document.querySelector(it)?.let {
                        val backToMain = it as HTMLElement
                        backToMain.style.visibility == "visible" 
                    }?: false
                }?: false
            }?: false
        }
        set(value) {
            if (visibleBackToMain != value) {
                grid?.let {
                    it.backToMainQuery?.let {
                        document.querySelector(it)?.let {
                            val backToMain = it as HTMLElement
                            backToMain.style.display =
                                if (value) "block" else "none" 
                            backToMain.style.visibility =
                                if (value) "visible" else "hidden" 
                        }
                    }
                }
            }
        }

    /**
     * back to main contents
     */
    var backToMainContents: MutableList<Element>? = null 

    /**
     * you get true if user edits point light location
     */
    var isEditing: Boolean
        get() {
            return grid?.let {
                it.backToMainQuery?.let {
                    document.querySelector(it)?.let {
                        val backToMain = it as HTMLElement 
                        backToMain.dataset["editor"] == "point-light"
                    }?: false
                }?: false
            }?: false
        }

        set(value) {
            if (isEditing != value) {
                grid?.let  {
                    it.backToMainQuery?.let {
                        document.querySelector(it)?.let {
                            val backToMain = it as HTMLElement
                            if (value) {
                                backToMain.dataset["editor"] = "point-light"
                            } else {
                                backToMain.dataset["editor"] = ""
                            }
                        } 
                    }
                }
            }
        }


    /**
     * visible editor
     */
    fun show(handleClosed: (()->Unit)? = null) {
        grid?.let {
            it.backToMainQuery?.let {
                document.querySelector(it)?.let {
                    val backToButton = it
                    visibleBackToMain = true
                    visibleMainMenu = false 
                    isEditing = true
                    handleToBackToMain = {
                        it.stopPropagation()
                        doSave(handleClosed) 
                    }
                    backToButton.addEventListener(
                        "click", handleToBackToMain!!,
                        object {
                            @JsName("once")
                            val once = true
                        })
                } 
            }
        }
    }


    /**
     * save point light setting
     */
    fun doSave(finishedOperation: (()->Unit)? = null) {
        val pointLight = this.pointLight           
        if (pointLight != null) {
            changeBackToMainToSync()
            Persistence.savePointLight(pointLight).then({
                restoreToBackToMain()
                visibleBackToMain = false
                visibleMainMenu = true
                isEditing = false
                if (finishedOperation != null) {
                    finishedOperation()
                }
            }, {
                restoreToBackToMain()
                visibleBackToMain = false
                visibleMainMenu = true
                isEditing = false
                if (finishedOperation != null) {
                    finishedOperation()
                }
            })
        }
    }

    /**
     * change back to main icon to synchronizing icon
     */
    fun changeBackToMainToSync() {
        document.querySelector(option.syncIconTemplateQuery)?.let {
            val temp = it as HTMLTemplateElement 
            temp.content.firstElementChild?.let {
                val syncIcon = it.cloneNode(true) as Element
                grid?.let {
                    it.backToMainQuery?.let {
                        document.querySelector(it)?.let {
                            val backToMainElem = it
                            val savedContents = ArrayList<Element>()
                            while (backToMainElem.childElementCount > 0) {
                                backToMainElem.firstElementChild?.let {
                                    savedContents.add(it)
                                    it.remove()
                                }
                            }
                            backToMainElem.append(syncIcon)
                            backToMainContents = savedContents
                       }
                    }
                } 
            }
        }
        
    }

    /**
     * restore back to main icon from sychronzing icon
     */
    fun restoreToBackToMain() {
        backToMainContents?.let {
            val savedContents = it
            grid?.let {
                it.backToMainQuery?.let {
                    document.querySelector(it)?.let {
                        val backToMainElem = it
                        while (backToMainElem.childElementCount > 0) {
                            backToMainElem.firstElementChild?.let {
                                it.remove()
                            }
                        }
                        savedContents.forEach {
                            backToMainElem.appendChild(it)
                        } 
                    }
                }
            }
            backToMainContents = null
        }
    }
}

// vi: se ts=4 sw=4 et:
