package line

/**
 *  flex text
 */
external interface FlexText: FlexComponent {

    /**
     * text
     */
    val text: String?
    

    /**
     * contents
     */
    val contents: Array<FlexSpan>?


    /**
     * adjust mode
     */
    val adjustMode: String?

    
    /**
     * ratio width or height
     */
    val flex: Int?


    /**
     * margin
     */
    val margin: String?


    /**
     * position
     */
    val position: String?

    /**
     * offset top
     */
    val offsetTop: String?

    /**
     * offset bottom
     */
    val offsetBottom: String?

    /**
     * offset start
     */
    val offsetStart: String?

    /**
     * offset end 
     */
    val offsetEnd: String?



    /**
     * font size
     */
    val size: String?


    /**
     * horizontal alignment
     */
    val align: String?

    /**
     * vertical alignment
     */
    val gravity: String?


    /**
     * text wrapping
     */
    val wrap: Boolean?

    /**
     * maximum number of lines
     */
    val maxLines: Int?
    
    /**
     * font weight
     */
    val weight: String?


    /**
     * text color
     */
    val color: String?

    /**
     * action
     */
    val action: Action?

    /**
     * style
     */
    val style: String?

    /**
     * decoration
     */
    val decoration: String? 
}

/**
 * create flex text 
 */
fun createFlexText(
    text: String? = null,
    contents: Array<FlexSpan>? = null,
    adjustMode: String? = null,
    flex: Int? = null,
    margin: String? = null,
    position: String? = null,
    offsetTop: String? = null,
    offsetBottom: String? = null,
    offsetStart: String? = null,
    offsetEnd: String? = null,
    size: String? = null,
    align: String? = null,
    gravity: String? = null,
    wrap: Boolean? = null,
    maxLines: Int? = null,
    weight: String? = null,
    color: String? = null,
    action: Action? = null,
    style: String? = null,
    decoration: String? = null): FlexText {

    val nameAndValues = arrayOf(
        Pair<String, Any?>("text", text),
        Pair<String, Any?>("contents", contents),
        Pair<String, Any?>("adjustMode", adjustMode),
        Pair<String, Any?>("flex", flex),
        Pair<String, Any?>("margin", margin),
        Pair<String, Any?>("position", position),
        Pair<String, Any?>("offsetTop", offsetTop),
        Pair<String, Any?>("offsetBottom", offsetBottom),
        Pair<String, Any?>("offsetStart", offsetStart),
        Pair<String, Any?>("offsetEnd", offsetEnd),
        Pair<String, Any?>("size", size),
        Pair<String, Any?>("align", align),
        Pair<String, Any?>("gravity", gravity),
        Pair<String, Any?>("wrap", wrap),
        Pair<String, Any?>("maxLines", maxLines),
        Pair<String, Any?>("weight", weight),
        Pair<String, Any?>("color", color),
        Pair<String, Any?>("action", action),
        Pair<String, Any?>("style", style),
        Pair<String, Any?>("decoration", decoration)) 

    val result: dynamic = object { }
    result["type"] = "text"

    nameAndValues.forEach {
        if (it.second != null) {
            result[it.first] = it.second
        }
    }
    return result
}
// vi: se ts=4 sw=4 et:
