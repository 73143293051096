package net.oc_soft.gl

/**
 * helper functions for glViewport
 */
class Viewport {


    /**
     * class instance
     */
    companion object {
        /**
         * convert coordinate from device to window
         */
        fun toWindowCoordinate(xd: Float, yd: Float,
            x: Int, y: Int, width: Int, height: Int): FloatArray {
            val origin = floatArrayOf(
                x.toFloat() + width.toFloat() / 2f,
                y.toFloat() + height.toFloat() / 2f)
            return floatArrayOf(
                (width.toFloat() * xd) / 2f + origin[0],
                (height.toFloat() * yd) / 2f + origin[1])
        }


        /**
         * convert coordinate from device to window
         */
        fun toWindowCoordinate(xd: Int, yd: Int,
            x: Int, y: Int, width: Int, height: Int): FloatArray {
            return toWindowCoordinate(xd.toFloat(), yd.toFloat(),
                x, y, width, height)
        }

        /**
         * convert coordinate from device to window
         */
        fun toWindowCoordinate(xd: Int, yd: Int,
            viewport: IntArray): FloatArray {
            return toWindowCoordinate(xd, yd,
                viewport[0], viewport[1], viewport[2], viewport[3])
        }

        /**
         * convert coordinate from device to window
         */
        fun toWindowCoordinate(xd: Float, yd: Float,
            viewport: IntArray): FloatArray {
            return toWindowCoordinate(xd, yd,
                viewport[0], viewport[1], viewport[2], viewport[3])
        }


        
        /**
         * convert coordinates from window to device
         */
        fun toDeviceCoordinate(wx: Int, wy: Int,
            x: Int, y: Int, width: Int, height: Int): FloatArray {

            val center = floatArrayOf(
                x.toFloat() + width.toFloat() / 2f,
                y.toFloat() + height.toFloat() / 2f)
        
            return floatArrayOf(
                2 * (wx.toFloat() - center[0]) / width,
                2 * (wy.toFloat() - center[1]) / height)
        } 
        /**
         * convert coordinates from window to device
         */
        fun toDeviceCoordinate(wx: Int, wy: Int,
            viewport: IntArray): FloatArray {
            return toDeviceCoordinate(wx, wy,
                viewport[0], viewport[1], viewport[2], viewport[3])
        } 


    }
}

// vi: se ts=4 sw=4 et:
