package line.liff

import kotlin.collections.Map
import kotlin.collections.MutableList
import kotlin.collections.ArrayList

/**
 * url
 */
class URL {

    /**
     * class instance
     */
    companion object {

        /**
         * create end point url
         */
        fun endPointUrl(
            liffId: String,
            subPath: String? = null,
            params: Map<String, String>? = null): String {

            val urlElements: MutableList<String> = ArrayList<String>()

            urlElements.add("https://liff.line.me/${liffId}")
            if (subPath != null) {
                urlElements.add(subPath)
            }
            if (params != null && params.size > 0) {
                val paramStr = params.map(
                    { "${it.key}=${it.value}" }).joinToString("&")
                urlElements.add("/") 
                urlElements.add("&${paramStr}")
            } 

            val result = urlElements.joinToString("")
             
            return result
        }
    }

}
// vi: se ts=4 sw=4 et:
