package net.oc_soft.mswp.storage

import kotlin.js.Promise
import kotlin.collections.Map
import kotlin.collections.HashMap
import kotlinx.browser.window
import kotlin.text.toIntOrNull
import kotlin.math.roundToInt
import kotlin.math.min
import kotlin.math.max
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.xhr.FormData
import org.khronos.webgl.ArrayBuffer


import net.oc_soft.mswp.ColorScheme
import net.oc_soft.mswp.ColorMaterialConvert
import net.oc_soft.mswp.ui.Effector
import net.oc_soft.mswp.ui.Bloom
import net.oc_soft.mswp.ui.ColorMaterial
import net.oc_soft.mswp.ui.ShaderPrograms

import net.oc_soft.mswp.settings.LineSession

/**
 * manage user interface persistence data.
 */
class Session {

    /**
     * class instance
     */
    companion object {
        
        /**
         * save line session
         */
        fun saveLineSession(lineSession: LineSession) {

            val sessionDynamic = Codec.lineSessionToDynamic(lineSession)
            val sessionStr = JSON.stringify(sessionDynamic)

            val storage = window.sessionStorage 
            storage.setItem("lineSession", sessionStr)
        }

        /**
         * load line session
         */
        fun loadLineSession(): LineSession? {
            val storage = window.sessionStorage 
            val sessionStr = storage.getItem("lineSession")
            var result: LineSession? = null
            if (sessionStr != null) {
                val sessionDynamic = JSON.parse<dynamic>(sessionStr)
                if (sessionDynamic != null) {
                    result = Codec.dynamicToLineSession(sessionDynamic)
                }
            }

            return result
        }

        /**
         * delete line session
         */
        fun deleteLineSession() {
            val storage = window.sessionStorage 
            storage.removeItem("lineSession")
        }


        /**
         * get screen image id
         */
        fun getScreenImageId(): String? {
            val storage = window.sessionStorage
            return storage.getItem("screenImageId")
        }

        /**
         * set screen image id
         */
        fun setScreenImageId(id: String?) {
            val storage = window.sessionStorage
            if (id != null) {
                storage.setItem("screenImageId", id)
            } else {
                storage.removeItem("screenImageId")
            }
        }
    }

    
}

// vi: se ts=4 sw=4 et: 
