package net.oc_soft.filter

import kotlin.math.pow

/**
 * gaussian filter
 */
class Gaussian(
    /**
     * standard deviation
     */
    val standardDeviation: Double) {


    /**
     * class instance
     */
    companion object {
        /**
         * calculate 1 dimension gaussian value
         */
        fun calculate(
            sigma: Double,
            x: Double): Double {
            val sigma2 = sigma.pow(2.0)
            val cof = 1.0 / (2.0 * kotlin.math.PI * sigma2).pow(0.5)
            val powCof = - (x.pow(2.0) / (2.0 * sigma2))
            return cof * kotlin.math.E.pow(powCof)
        } 
 
        /**
         * calculate 2 demension gaussian value
         */
        fun calculate(
            sigma: Double,
            x: Double,
            y: Double): Double {
            val sigma2 = sigma.pow(2.0)
            val cof = 1.0 / (2.0 * kotlin.math.PI * sigma2)
            val powCof = -(x.pow(2.0) + y.pow(2.0)) / (2.0 * sigma2)
            return cof * kotlin.math.E.pow(powCof) 
        } 

        /**
         * calculate gause value at an integer value
         */
        fun calculate(
            sigma: Double,
            size: Int): DoubleArray {
            var size0: Int
            size0 = if (size < 0) { 1 } else { size }
            size0 = if (size0 % 2 == 0) { size0 + 1 } else { size0 }
            val sigma0 = if (sigma < 0) { calculateSigma(size) } else { sigma }
            
            val gi = DoubleArray(size0) {
                calculate(sigma0, it - ((size0 - 1).toDouble() / 2.0))
            } 
            val giSum = gi.sum()
            val result = DoubleArray(gi.size) {
                gi[it] / giSum
            }
            return result
        }
        /**
         * calculate gause value at an integer value
         */
        fun calculate2(
            sigma: Double,
            size: Int): DoubleArray {
            val giValues = calculate(sigma, 2 * size - 1)
            val result = DoubleArray(giValues.size / 2 + 1) {
                giValues[it + giValues.size / 2]
            }
            return result
        } 
        /**
         * calculate sigma from size
         */
        fun calculateSigma(size: Int): Double {
            return 0.3 * ((size.toDouble() - 1) * 0.5) + 0.8
        }
    }

    /**
     * constructor
     */
    constructor(standardDeviation: Float)
        : this(standardDeviation.toDouble()) {
    }
    /**
     * calculate gaussian value
     */
    fun calculate(
        x: Double): Double {
        return Gaussian.calculate(standardDeviation, x) 
    } 

    /**
     * calculate gaussian value
     */
    fun calculate(
        x: Int): Double {
        return Gaussian.calculate(standardDeviation,
            standardDeviation * x.toDouble()) 
    } 

    /**
     * calculate samples
     */
    fun calculateSamples(
        size: Int): DoubleArray {
        return Gaussian.calculate(standardDeviation, size)
    }
      
    /**
     * calculate gaussian value
     */
    fun calculate(
        x: Double,
        y: Double): Double {
        return Gaussian.calculate(standardDeviation, x, y) 
    } 

    /**
     * calculate gaussian value with starndard deviation
     */
    fun calculate(
        x: Int,
        y: Int): Double {
        return Gaussian.calculate(
            standardDeviation,
            x.toFloat() * standardDeviation,
            y.toFloat() * standardDeviation) 
    } 


    /**
     * calculate 1 demension gaussian values
     */
    fun calculate(vararg values: Double): DoubleArray {
        return DoubleArray(values.size) {
            calculate(values[it])
        } 
    }

    /**
     * calculate gaussian values
     */
    fun calculate(vararg values: Float): FloatArray{
        return FloatArray(values.size) {
            calculate(values[it].toDouble()).toFloat()
        }
    }


    /**
     * calculate 2 demension gaussian values
     */
    fun calculate(vararg values: Pair<Double, Double>): DoubleArray {
        return DoubleArray(values.size) {
            calculate(values[it].first, values[it].second)
        } 
    }
     
    /**
     * calculate 1 demension gaussian values with standard deviation
     */
    fun calculate(vararg values: Int): DoubleArray {
        return DoubleArray(values.size) {
            calculate(values[it])
        } 
    }

    /**
     * calculate 2 demension gaussian values with standard deviation
     */
    fun calculate(vararg values: IntArray): DoubleArray {
        return DoubleArray(values.size) {
            calculate(values[it][0], values[it][1])
        } 
    }

    /**
     * calculate coefficients with size
     */
    fun calculate2(size: Int): DoubleArray {
        return calculate2(standardDeviation, size)
    }
}
// vi: se ts=4 sw=4 et:
