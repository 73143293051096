package net.oc_soft.mswp

/**
 * material
 */
class Material(
    light: Light,
    shiness: Float = 0.8f) {

    /**
     * light parameter
     */
    var lightParameter: Light = Light(light)
        set(value) {
            if (field !== value) {
                field.copy(value)
            }
        }

    /**
     * ambient
     */
    var ambient: FloatArray    
        get() {
            return lightParameter.ambient
        }
        set(value) {
            lightParameter.ambient = value 
        }

    /**
     * diffuse
     */
    var diffuse: FloatArray
        get() {
            return lightParameter.diffuse
        }
        set(value) {
            lightParameter.diffuse = value 
        }


    /**
     * specular
     */
    var specular: FloatArray
        get() {
            return lightParameter.specular
        }
        set(value) {
            lightParameter.specular = value 
        }

    /**
     * shiness
     */
    var shiness: Float = shiness

    /**
     * constructor
     */
    constructor(other: Material)
        : this(other.lightParameter, other.shiness) {
    }

    /**
     * calculate hash code
     */
    override fun hashCode(): Int {
        var result = lightParameter.hashCode()
        result = result xor shiness.hashCode()
        return result
    }

    /**
     * equals
     */
    override fun equals(other: Any?) : Boolean {
        var result = this === other
        if (!result) {
            result = other is Material
            if (result) {
                val otherMaterial = other as Material
                result = lightParameter == otherMaterial.lightParameter
                if (result) {
                    result = shiness == otherMaterial.shiness
                } 
            }
        }
        return result
    }
    
    /**
     * copy each field
     */
    fun copy(other: Material) {
        if (this !== other) {
            lightParameter = other.lightParameter
            shiness = other.shiness
        }
    }
}

// vi: se ts=4 sw=4 et:
