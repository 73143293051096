package net.oc_soft.mswp.ui

import kotlin.math.min
import fontawesome.Icons
import kotlinx.browser.window
import kotlinx.browser.document

import kotlin.text.toIntOrNull
import kotlin.collections.List
import kotlin.collections.ArrayList
import kotlin.collections.HashSet
import kotlin.collections.Map
import kotlin.collections.HashMap
import net.oc_soft.mswp.Activity
import net.oc_soft.mswp.*
import org.w3c.dom.Node
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTemplateElement
import org.w3c.dom.DOMStringMap

import org.w3c.dom.get
import org.w3c.dom.events.Event
import org.w3c.dom.svg.SVGElement


import net.oc_soft.mswp.storage.Codec


/**
 * you get dataset if element has dataset property
 */
val Element.dataset: DOMStringMap?
    get() {
        return when (this) {
            is HTMLElement -> dataset
            is SVGElement -> dataset
            else -> null
        }
    }

/**
 * icon selector
 */
class IconSelector(val option : Option) {


    /**
     * option 
     */
    data class Option(val modalQuery: String, 
        val itemListQuery: String,
        val itemsQuery: String,
        val pagingContainerQuery: String,
        val okQuery: String,
        val iconKindSelectorQuery: String,
        /**
         * runtime icon item query
         */
        val rtIconItemQuery: String,
        val itemTemplateQuery: String,
        val blankItemTemplateQuery: String,
        val pagingCtrlFullTemplateQuery: String,
        val pagingCtrlMediumTemplateQuery: String,
        val pagingCtrlSimpleTemplateQuery: String,
        val pagingItemTemplateQuery: String,
        val syncIconTemplateQuery: String,
        val iconSetting: IconSetting)

    /**
     * icon page contents meta infomation
     */
    data class ContentsMeta(
        val colSize: Int,
        val rowSize: Int,
        val iconIdentifiers: List<Icons.Identifier>)
    

    /**
     * runtime configuration
     */
    var runtimeConfig: dynamic = null

    /**
     * contents meta information
     */
    var contentsMeta: ContentsMeta? = null

    /**
     *  icon id to page map
     */
    var iconPageMap: Map<Codec.Icon, Int>? = null
        get() {

            if (field == null) {
                val ctMeta = this.contentsMeta 
                if (ctMeta != null) {
                    field = createIconPageMap(ctMeta)
                }
            }
            var result: Map<Codec.Icon, Int>?
            result = field
            return result
        }

    /**
     * count of icon items in page
     */
    val countOfIconItemsInPage: Int
        get() {
            val items = document.querySelectorAll(option.itemsQuery)
            return items.length
        }

    /**
     * handle number control event
     */
    var numberCtrlHdlr: ((Event)->Unit)? = null
    
    /**
     * handle go to fist page event
     */
    var firstPageHdlr: ((Event)->Unit)? = null
     
    /**
     * handle go to last page
     */
    var lastPageHdlr: ((Event)->Unit)? = null 

    /**
     * handle previous pages ui event 
     */
    var prevPageHdlr: ((Event)->Unit)? = null

    /**
     * handle next pages ui event 
     */
    var nextPageHdlr: ((Event)->Unit)?  = null
 
    /**
     * ok button handler
     */
    var okHdlr: ((Event)->Unit)? = null

    /**
     * icon item click handler
     */
    var iconItemClickHdlr: ((Event)->Unit)? = null


    /**
     * icon kind change handler
     */
    var iconKindChangeHdlr: ((Event)->Unit)? = null

    /**
     * called when modal dialog is hidden.
     */
    var modalHiddenHdlr: ((Event)->Unit)? = null
    
    /**
     * contens of ok ui control, this list is keeping while ajax request. 
     */
    var okContents: MutableList<Node>? = null 


    /**
     * currently selected icon 
     */
    var selectedIcon: Codec.Icon? 
        get() {
            return when (iconKindUI) {
                IconSetting.NG_ICON -> selectedNgIcon 
                IconSetting.OK_ICON -> selectedOkIcon
                IconSetting.FLAG_ICON -> selectedFlagIcon
                else -> null
            }
        }
        set(value) {
            when (iconKindUI) {
                IconSetting.NG_ICON -> selectedNgIcon = value
                IconSetting.OK_ICON -> selectedOkIcon = value
                IconSetting.FLAG_ICON -> selectedFlagIcon = value
            }
        }



    /**
     * currently selected ng icon
     */
    var selectedNgIcon: Codec.Icon? = null

    /**
     * currently selected ok icon
     */
    var selectedOkIcon: Codec.Icon? = null

    /**
     * currently selected flag icon
     */
    var selectedFlagIcon: Codec.Icon? = null


    /**
     * icon kind in which user interface control
     */
    val iconKindUI: String?
        get() {
            return document.querySelector(option.iconKindSelectorQuery)?.let {
                val selected = (it as HTMLSelectElement).selectedOptions
                if (selected.length > 0) {
                    (selected[0] as HTMLOptionElement).value
                } else {
                    null
                }
            }
        }
    
    /**
     * bind into html
     */
    fun bind() {
    }

    /**
     * unbind from html
     */
    fun unbind() {
    }


    /**
     * visible modal dialog
     */
    fun show() {
        bindModal()
        document.querySelector(option.modalQuery)?.let {
            val modal = bootstrap.Modal(it as HTMLElement)
            modal.show()
        }
    }
    /**
     * connect functions into modal user interface.
     */
    fun bindModal() {
        modalHiddenHdlr = { onModalHidden(it) }
        okHdlr = { onOk(it) }
        iconItemClickHdlr = { onIconItemClick(it) }
        iconKindChangeHdlr = { onIconKindChange(it) } 
       
        document.querySelector(option.modalQuery)?.let {
            val modalElem = it as HTMLElement

            modalElem.addEventListener("hidden.bs.modal", modalHiddenHdlr!!)
        }
        selectedNgIcon = option.iconSetting.ngIcon 
        selectedOkIcon = option.iconSetting.okIcon
        selectedFlagIcon = option.iconSetting.flagIcon
        document.querySelector(option.okQuery)?.let {
            it.addEventListener("click", okHdlr!!)
        }
        document.querySelector(option.iconKindSelectorQuery)?.let {
            it.addEventListener(
                "change", iconKindChangeHdlr!!)
        }
        setupContents()
    }

    /**
     * disconnect functions from modal user interface
     */
    fun unbindModal() {
        destroyContents()

        modalHiddenHdlr?.let {
            val hdlr = it
            document.querySelector(option.modalQuery)?.let {
                val modalElem = it as HTMLElement
                modalElem.removeEventListener(
                    "hidden.bs.modal", hdlr)
            }
            modalHiddenHdlr = null
        }
        okHdlr?.let {
            val hdlr = it
            document.querySelector(option.okQuery)?.let {
                it.removeEventListener("click", hdlr)
                
            }
            okHdlr = null
        }
        iconKindChangeHdlr?.let  {
            val hdlr = it
            document.querySelector(option.iconKindSelectorQuery)?.let {
                it.removeEventListener("change", hdlr)
            }
            iconKindChangeHdlr = null
        }
        selectedOkIcon = null
        selectedNgIcon = null
        selectedFlagIcon = null
        iconItemClickHdlr = null
    }

    /**
     * handle modal hidden event
     */
    @Suppress("UNUSED_PARAMETER")
    fun onModalHidden(e: Event) {
        unbindModal() 
    }

    /**
     * handle ok
     */
    @Suppress("UNUSED_PARAMETER")
    fun onOk(e: Event) {
        postSave()
    }

    /**
     * save data into host 
     */
    fun postSave() {
        window.setTimeout({
            doSave()
        }, 100)
    }

    /**
     * save setting into server
     */
    fun doSave() {
        changeUiOkToSync()
        val selectedNgIcon = this.selectedNgIcon!!
        val selectedOkIcon = this.selectedOkIcon!!
        val selectedFlagIcon = this.selectedFlagIcon!!
        val iconMap = HashMap<String, Codec.Icon>()
        iconMap[IconSetting.NG_ICON] = selectedNgIcon
        iconMap[IconSetting.OK_ICON] = selectedOkIcon 
        iconMap[IconSetting.FLAG_ICON] = selectedFlagIcon
        Persistence.saveIcon(iconMap).then({

            document.querySelector(option.modalQuery)?.let {
                bootstrap.Modal.getInstance(it as HTMLElement)?.let {
                    it.hide()
                }
            }
            restoreOkFromSync()
            option.iconSetting.ngIcon = selectedNgIcon
            option.iconSetting.okIcon = selectedOkIcon
            option.iconSetting.flagIcon = selectedFlagIcon
        }, {
            // todo: you have to display waring 
            restoreOkFromSync()   
        })
   } 

   
    /**
     * change ok to synchronizing icon
     */
    fun changeUiOkToSync() {
        document.querySelector(option.syncIconTemplateQuery)?.let {
            val tmp = it as HTMLTemplateElement
            tmp.content.firstElementChild?.let {
                val syncElem = (it as Node).cloneNode(true) as HTMLElement
                val savedContents = ArrayList<Node>()
                document.querySelector(option.okQuery)?.let {
                    val okElem = it as HTMLElement
                    val nodes = okElem.childNodes
                    for (idx in 0 until nodes.length) {
                        val node = nodes[idx] as Node
                        savedContents.add(node)
                        okElem.removeChild(node)
                    }
                    okElem.appendChild(syncElem)
                }
                okContents = savedContents
            }
        }
    }
    /**
     * restore ok from synchronizing icon
     */
    fun restoreOkFromSync() {
        okContents?.let {
            val savedContents = it
            document.querySelector(option.okQuery)?.let {
                val okElem = it as HTMLElement
                while (okElem.childElementCount > 0) {
                    okElem.firstElementChild?.let {
                        it.remove()
                    }
                }
                savedContents.forEach {
                    okElem.append(it)
                }
            }
            okContents = null
        } 
    }
       

    /**
     * set up user interface contents
     */
    fun setupContents() {
        var ids = readIconIdentfiers() 
        var tableSize = findTableSize()
        if (tableSize != null) {
            var (colSize, rowSize) = tableSize
            if (colSize != null && rowSize != null) {
                contentsMeta = ContentsMeta(colSize, rowSize, ids)
                val selectedIcon = this.selectedIcon
                if (selectedIcon != null) {
                    syncPageWithItem(selectedIcon)
                }
            }
        }
    }

   
    /**
     * synchronize ui page with item
     */
    fun syncPageWithItem(item: Codec.Icon) {
        val pageNumber = findPageContainingItem(item)
        updatePage(pageNumber, 
            contentsMeta!!)
        setupPagination(pageNumber)
        postFocusInNumberPage(pageNumber)
    }

    /**
     * synchronzie ui page with item lately
     */
    fun postSyncPageWithItem(item: Codec.Icon) {
        window.setTimeout({
            syncPageWithItem(item)
        }, 100) 
    }
    
   

    /**
     * find the page in which selected icon is.
     */
    fun findPageSelectedItemIn(): Int {
        var result = 0
        val selectedIcon = this.selectedIcon
        if (selectedIcon != null) {
            result = findPageContainingItem(selectedIcon)      
        }
        
        return result 
    }
    /**
     * find page index in which contains item
     */ 
    fun findPageContainingItem(item : Codec.Icon): Int {
        var result = 0
        val iconPageMap = this.iconPageMap
        if (iconPageMap != null) {
            val pageIndex = iconPageMap[item]
            if (pageIndex!= null) {
                result = pageIndex
            }
        }      

        return result
    }

    

    
    /**
     * create icon page map
     */
    fun createIconPageMap(ctMeta: ContentsMeta): Map<Codec.Icon, Int> {
        val result = HashMap<Codec.Icon, Int>()
        val itemCountInPage = ctMeta.colSize * ctMeta.rowSize
        ctMeta.iconIdentifiers.forEachIndexed {
            index, elem ->
            val pageIdx = index / itemCountInPage
            val icon = Codec.Icon(elem.prefix, elem.name)
            result[icon] = pageIdx
        }

        return result
    }

    /**
     * read icon identifier list
     */
    fun readIconIdentfiers(): List<Icons.Identifier> {
        var ids = Icons.getAllIdentifiersCodeOrder() 
        val rc = runtimeConfig["icon_list"]
        var result = ids
        if (rc != null) {
            if (rc.icons != null) {
                if (rc.icons.exclude != null) {
                    if (rc.icons.exclude["font-awesome"] != null) {
                        val excCode = HashSet<String>() 
                        val faEx = rc.icons.exclude["font-awesome"]
                        for (i in 0 until faEx.length) {
                            excCode.add(faEx[i].code) 
                        }
                        result = ids.filter({ it.code !in excCode })
                    }
                }
            }
        }
        return result
    }
    /**
     * setup pagination ui
     */
    fun setupPagination(firstPageNumber: Int = 0) {
        var ctMeta = contentsMeta
        if (ctMeta != null) {
            val pageCount = calcCountOfPages(ctMeta)       
            if (pageCount > 1) {
                val pagingSize = findPagingSize()
                if (pagingSize != null) {
                    var templateQuery = option.pagingCtrlSimpleTemplateQuery

                    if (pageCount > pagingSize) {
                        val pagingCount = pageCount / pagingSize
                        if (pagingCount > 2) {
                            templateQuery = option.pagingCtrlFullTemplateQuery 
                        } else {
                            templateQuery = 
                                option.pagingCtrlMediumTemplateQuery 
                        } 
                    } 
                    setupPagingCtrl(templateQuery, 
                        Pair(firstPageNumber, pagingSize))
                } 
            }
        } 
    }

    /**
     * update pagination ui
     */
    @Suppress("UNUSED_PARAMETER")
    fun updatePagination(
        pageIndex: Int) {
    }

    /**
     * create paging control
     */
    fun setupPagingCtrl(templateQuery: String,
        page: Pair<Int, Int>) {
        val (startIndex, size) = page
        document.querySelector(templateQuery)?.let {
            val tmp = it as HTMLTemplateElement
            tmp.content.firstElementChild?.let {
                val pagingCtrl = it.cloneNode(true) as HTMLElement
                val insertEndIdx = pagingCtrl.childElementCount / 2 
                for (i in startIndex until startIndex + size) {
                    document.querySelector(
                        option.pagingItemTemplateQuery)?.let {
                        val pageItemTemp = it as HTMLTemplateElement
                        val pagingItem = 
                            pageItemTemp.content.firstElementChild!!.cloneNode(
                                true) as HTMLElement
                        pagingItem.innerText = "${i + 1}"
                        val insertIdx = pagingCtrl.childElementCount -
                            insertEndIdx
                        pagingCtrl.insertBefore(
                            pagingItem,
                            pagingCtrl.children[insertIdx] as Node)
                    }
                }
                unbindPagingCtrl()
        
                document.querySelector(option.pagingContainerQuery)?.let {
                    val pagingContainer = it as HTMLElement
                    if (pagingContainer.childElementCount > 0) {
                        pagingContainer.firstElementChild?.let {
                            val elem = it as HTMLElement
                            pagingContainer.replaceChild(pagingCtrl, elem)
                        }
                    } else {
                         pagingContainer.append(pagingCtrl)
                    }
                }
            }
        }
        bindPagingCtrl()
    }

    /**
     * bind paging user interface.
     */
    fun bindPagingCtrl() {
        firstPageHdlr = { onFirstPage(it) }
        lastPageHdlr = { onLastPage(it) }
        prevPageHdlr = { onPrevPage(it) }
        nextPageHdlr = { onNextPage(it) }
        numberCtrlHdlr = { onNumberUi(it) }

        document.querySelector(option.pagingContainerQuery)?.let {
            val pagingContainer = it as HTMLElement
            pagingContainer.firstElementChild?.let {
                val pagingCtrl = it as HTMLElement
                pagingCtrl.querySelector(".first")?.let {
                    it.addEventListener("click", firstPageHdlr!!)
                } 
                pagingCtrl.querySelector(".last")?.let {
                    it.addEventListener("click", lastPageHdlr!!)
                } 
                pagingCtrl.querySelector(".prev")?.let {
                    it.addEventListener("click", prevPageHdlr!!)
                } 
                pagingCtrl.querySelector(".next")?.let {
                    it.addEventListener("click", nextPageHdlr!!)
                } 
                val numbers = pagingCtrl.querySelectorAll(".page-number")
                for (idx in 0 until numbers.length) {
                    numbers[idx]?.let {
                        it.addEventListener("click", numberCtrlHdlr!!)
                    }
                }
            } 
        }
    }


    /**
     * unbind handler from paging control
     */
    fun unbindPagingCtrl() {
        document.querySelector(option.pagingContainerQuery)?.let {
            val pagingContainer = it as HTMLElement
            pagingContainer.firstElementChild?.let {
                val pagingCtrl = it as HTMLElement
                firstPageHdlr?.let  {
                    val hdlr = it
                    pagingCtrl.querySelector(".first")?.let {
                        it.removeEventListener("click", hdlr)
                    }
                }

                lastPageHdlr?.let {
                    val hdlr = it
                    pagingCtrl.querySelector(".last")?.let {
                        it.removeEventListener("click", hdlr)
                    }
                }
                prevPageHdlr?.let {
                    val hdlr = it
                    pagingCtrl.querySelector(".prev")?.let {
                        it.removeEventListener("click", hdlr)
                    }
                }
                nextPageHdlr?.let  {
                    val hdlr = it
                    pagingCtrl.querySelector(".next")?.let{
                        it.removeEventListener("click", hdlr)
                    }
                }
                numberCtrlHdlr?.let {
                    val hdlr = it
                    val numbers = pagingCtrl.querySelectorAll(".page-number")
                    for (idx in 0 until numbers.length) {
                        numbers[idx]?.let {
                            it.removeEventListener("click", hdlr)
                        }
                    }
                }
            }
        }
        firstPageHdlr = null
        lastPageHdlr = null 
        prevPageHdlr = null
        nextPageHdlr = null
        numberCtrlHdlr = null
    }


    /**
     * handle number control  
     */
    @Suppress("UNUSED_PARAMETER")
    fun onNumberUi(e: Event) {
        contentsMeta?.let {
            val ctMeta = it
            val elem = e.target as HTMLElement
            elem.innerText.toIntOrNull()?.let {
                postUpdatePage(it - 1, ctMeta)
                Activity.record()
            }
        }
    }

    /**
     * handle go to fist page event
     */
    @Suppress("UNUSED_PARAMETER")
    fun onFirstPage(e :Event) {
        postRenumberPageCtrl(0) 
        Activity.record()
    }
     
    /**
     * handle go to last page
     */
    @Suppress("UNUSED_PARAMETER")
    fun onLastPage(e :Event) {
        contentsMeta?.let {
            val ctMeta = it
            val pageUiCount = calcCountOfPageNumUi()
            val pageCount = calcCountOfPages(ctMeta)
            val nextIndex = pageCount - pageUiCount
            postRenumberPageCtrl(nextIndex)
            Activity.record()
        }
    }

    /**
     * handle previous pages ui event 
     */
    @Suppress("UNUSED_PARAMETER")
    fun onPrevPage(e: Event) {
        readPageNumbersFromUi()?.let {
            val pageRange = it
            val pageUiCount = calcCountOfPageNumUi()
            var prevIndex = pageRange.first - pageUiCount + 1
            if (prevIndex < 0) {
                prevIndex = 0
            }
            postRenumberPageCtrl(prevIndex)
            Activity.record()
        }
    }

    /**
     * handle next pages ui event 
     */
    @Suppress("UNUSED_PARAMETER")
    fun onNextPage(e :Event) {
        val pageRange = readPageNumbersFromUi()
        val ctMeta = contentsMeta
        if (pageRange != null && ctMeta != null) {
            val pageUiCount = calcCountOfPageNumUi()           
            var nextIndex = pageRange.second 
            val endIndex = nextIndex + pageUiCount - 1
            val pageCount = calcCountOfPages(ctMeta)
            if (endIndex > pageCount) {
                nextIndex = pageCount - pageUiCount
            }
            postRenumberPageCtrl(nextIndex)
            Activity.record()
        }
    }


    /**
     * renumber page index
     */    
    fun postRenumberPageCtrl(pageIndex: Int) {
        window.setTimeout({
            renumberPageCtrl(pageIndex)
        })    
    } 

    /**
     * renumber page control interface
     */
    fun renumberPageCtrl(pageIndex: Int) {
        document.querySelector(option.pagingContainerQuery)?.let {
            it.firstElementChild?.let {
                val pagingCtrl = it
                val pageNumbers = pagingCtrl.querySelectorAll(".page-number") 

                for (idx in 0 until pageNumbers.length) {
                    val pageNumber = pageIndex + idx + 1
                    (pageNumbers[idx] as HTMLElement).innerText = 
                        "${pageNumber}"
                    
                }
            }
        }
    } 

    /**
     * focus in pageNumber if pageIndex is in numbers lateley.
     */
    fun postFocusInNumberPage(pageIndex: Int) {
        window.setTimeout({
            focusInNumberPage(pageIndex)
        }, 100)
    }

    /**
     * focus in pageNumber if pageIndex is in numbers.
     */
    fun focusInNumberPage(pageIndex: Int) {
        document.querySelector(option.pagingContainerQuery)?.let {
            it.firstElementChild?.let {
                val pagingCtrl = it
                val pageNumbers = pagingCtrl.querySelectorAll(".page-number")
                val pageNumber = pageIndex + 1
                var elemIndex : Int? = null
                for (idx in 0 until pageNumbers.length) {
                    var notMatched = true 
                    val elem = pageNumbers[idx] as HTMLElement
                    elem.innerText.toIntOrNull()?.let {
                        val elemNum = it
                        notMatched = elemNum != pageNumber  
                        if (!notMatched) {
                            elemIndex = idx.toInt()
                        }
                    }
                    if (!notMatched) {
                        break
                    }
                }
                elemIndex?.let {
                    (pageNumbers[it] as HTMLElement).focus()
                }
            }
        }
    }

    

    /**
     * read page numbers from user inteface
     */
    fun readPageNumbersFromUi(): Pair<Int, Int>? {
        return document.querySelector(option.pagingContainerQuery)?.let {
            it.firstElementChild?.let {
                val pagingCtrl = it 
                val pageNumbers = pagingCtrl.querySelectorAll(".page-number") 
                
                if (pageNumbers.length > 0) { 
                    val firstNode = pageNumbers[0] as HTMLElement
                    val lastNode = pageNumbers[pageNumbers.length - 1]
                        as HTMLElement
                    val firstNum = firstNode.innerText.toIntOrNull()
                    val lastNum = lastNode.innerText.toIntOrNull()
                    if (firstNum != null && lastNum != null) {
                        Pair(firstNum - 1, lastNum - 1)
                    } else {
                        null
                    }
                } else {
                    null
                }
            }    
        }
    }

    /**
     * update icon page later
     */
    fun postUpdatePage(pageIndex: Int,
        contentsMeta: ContentsMeta,
        selectionInPage: Int? = null) {
        window.setTimeout({
            updatePage(pageIndex, contentsMeta, selectionInPage)
        }, 100)
    } 
    
    /**
     * update icon  page
     */
    @Suppress("UNUSED_PARAMETER")
    fun updatePage(pageIndex : Int,
        contentsMeta: ContentsMeta,
        selectionInPage: Int? = null) {
        val ids = contentsMeta.iconIdentifiers
        val iconCount = contentsMeta.colSize * contentsMeta.rowSize
        val pageCount = (ids.size + iconCount - 1) / iconCount
       
        while (countOfIconItemsInPage > iconCount) {
            removeItem(countOfIconItemsInPage - 1)
        } 

        if (pageIndex in 0 until pageCount) {
            val iconStart = iconCount * pageIndex
            val iconEnd = iconCount * (pageIndex + 1)
            for (i in iconStart until iconEnd) {  
                var iconElem: Icons.Identifier? = null
                var idxInPage = i - iconStart
                if (i < ids.size) {
                    iconElem = ids[i]
                }
                replaceItem(iconElem, idxInPage)
            }
        }
    } 

    /**
     * calc count of page button ui
     */
    fun calcCountOfPageNumUi(): Int {
        return document.querySelector(option.pagingContainerQuery)?.let {
            it.firstElementChild?.let {
                val pageNumbers = it.querySelectorAll(".page-number")
                pageNumbers.length
            }?: 0
         }?: 0
    }
    /**
     * calculate count of pages
     */
    fun calcCountOfPages(contentsMeta: ContentsMeta): Int {
        val ids = contentsMeta.iconIdentifiers
        val iconCount = contentsMeta.colSize * contentsMeta.rowSize
        val result = (ids.size + iconCount - 1) / iconCount
        return result
    }
    /**
     * tear down user interface contents
     */
    fun destroyContents() {
        
        iconItemClickHdlr?.let {
            val hdlr = it
            val items = document.querySelectorAll(option.itemsQuery)
        
            for (idx in 0 until items.length) {
                val elem = items[idx] as Element
                elem.classList.remove("selected")
                elem.removeEventListener("click", hdlr)
                elem.remove()
            }
        }
        unbindPagingCtrl()
        document.querySelector(option.pagingContainerQuery)?.let {
            it.firstElementChild?.let {
                val numbers = it.querySelectorAll(".page-number")
                for (idx in 0 until numbers.length) {
                    numbers[idx]?.let {
                        val elem = it as Element
                        elem.remove()
                    }
                }
            }
        }
    } 

    /**
     * find table size from runtime configuration
     */
    fun findTableSize(): Pair<Int?, Int?>? {
        var result : Pair<Int?, Int?>? = null
        val rc = runtimeConfig["icon_list"]
        if (rc != null) {
            var rowCount : Int? = null 
            if (rc.rowSize != null) {
                rowCount = Responsive.findRowCount(
                    rc.rowSize,
                    "minHeight", "maxHeight")
            }
            if (rowCount == null) {
                rowCount = rc.rowSizeDefault
            }

            var colCount : Int? = null
            if (rc.colSize != null) {
                colCount = Responsive.findRowCount(
                    rc.ColSize,
                    "minWidth", "maxWidth") 
            }
            if (colCount == null) {
                colCount = rc.colSizeDefault
            }
            result = Pair(colCount, rowCount)
        } 

        return result 
    }

    /**
     * find paging size from runtime configuration
     */
    fun findPagingSize(): Int? {
        var result : Int? = null
        val rc = runtimeConfig["icon_list"]
        if (rc != null) {
            if (rc.pagingSize != null) {
                result = Responsive.findXCount(
                    rc.pagingSize,
                    "minWidth", "maxWidth")
            }
            if (result == null) {
                result = rc.pagingSizeDefault
            }
        }
        return result
    }

 
    /**
     * append item
     */
    fun appendItem(item : Icons.Identifier?) {
        replaceItem(item, countOfIconItemsInPage)
    }
    /**
     * replace item
     */
    fun replaceItem(item: Icons.Identifier?,
        index: Int) {
        var items = document.querySelectorAll(option.itemsQuery)
        if (index in 0 .. items.length) {
            var selected = false
            val newNode = if (item != null) {
                val temp = document.querySelector(option.itemTemplateQuery)
                    as HTMLTemplateElement
                val contentNode = temp.content.firstElementChild!!.cloneNode(
                    true) as HTMLElement
                val idNode = contentNode.querySelector("i")!! as HTMLElement
                idNode.classList.add(item.prefix, "fa-${item.name}")
                selected = selectedIcon == Codec.Icon(item.prefix, item.name)
                contentNode 
            } else {
                val temp = document.querySelector(
                    option.blankItemTemplateQuery) as HTMLTemplateElement
                temp.content.firstElementChild!!.cloneNode(true) as HTMLElement
            }
            if (index < items.length) {
                iconItemClickHdlr?.let {
                    (items[index] as Element).removeEventListener(
                        "click", it) 
                }
                (items[index] as Element).replaceWith(newNode) 
            } else {
                val itemList = document.querySelector(
                    option.itemListQuery) as Element
                itemList.append(newNode)
            }
            if (iconItemClickHdlr != null && item != null) {
                newNode.addEventListener("click", iconItemClickHdlr!!)
                if (selected) {
                    newNode.classList.add("selected")
                }
            }
        }
    }
    /**
     * remove item from icon list
     */
    fun removeItem(index: Int) {
        val items = document.querySelectorAll(option.itemsQuery)
        if (iconItemClickHdlr != null) {
            (items[index] as Element).removeEventListener(
                "click", iconItemClickHdlr!!) 
        }
        (items[index] as Element).remove()
    }

    /**
     * handle selector changed event
     */ 
    @Suppress("UNUSED_PARAMETER")
    fun onIconKindChange(e: Event) {
        if ("change" == e.type) {
            postSyncPageWithItem(selectedIcon!!)
        }
    }



    /**
     * handle item click
     */
    @Suppress("UNUSED_PARAMETER")
    fun onIconItemClick(e: Event) {
        postSelectIconNode(e.currentTarget as Element)
    }
    /**
     * synchronize with icon kind lately.
     */ 
    fun postSyncWithIconKindUI() {
        window.setTimeout({
            syncWithIconKindUI()
        }, 100)
    }

    /**
     * synchronize with icon kind selector
     */
    fun syncWithIconKindUI() {
        println(iconKindUI)
    }

    /**
     * select icon node lately
     */
    fun postSelectIconNode(iconNode: Element) {
        window.setTimeout({
            selectIconNode(iconNode)
        }, 100)
    }

    /**
     * select icon node
     */
    fun selectIconNode(iconNode: Element) {
        val children = (iconNode.parentNode as Element).children
        for (idx in 0 until children.length) {
            (children[idx] as Element).classList.remove("selected")
        }
        iconNode.classList.add("selected")

        iconNode.querySelector(option.rtIconItemQuery)?.let {
            createIconIdFromNode(it)?.let {
                selectedIcon = it
            }
        }
    }

    /**
     * creawte persistence icon from node
     */
    fun createIconIdFromNode(node : Element): Codec.Icon? {
        return node.dataset?.let {
            val prefix = it["prefix"]
            val iconName = it["icon"]
            Codec.Icon(prefix as String, iconName as String)
        }
    }
}
// vi: se ts=4 sw=4 et:
