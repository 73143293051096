package net.oc_soft.mswp.ui

import org.khronos.webgl.*

class RenderingCtx() {
    /**
     * shader programs
     */
    var shaderPrograms: Array<WebGLProgram?>? = null

    /**
     * main shader program
     */
    val shaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 0) {
                result = shaderPrograms[0]
            }
            return result 
        }
    /**
     * program for location reader
     */
    val locReaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 1) {
                result = shaderPrograms[1]
            }
            return result 
        }

    /**
     * program for rendering point
     */
    val pointShaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 2) {
                result = shaderPrograms[2]
            }
            return result 
        } 

    /**
     * shadow map shader program
     */
    val shadowMapShaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 3) {
                result = shaderPrograms[3]
            }
            return result 
        }

    /**
     * screen shader program
     */
    val screenShaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 4) {
                result = shaderPrograms[4]
            }
            return result
        }

    /**
     * glow shader program
     */
    val glowShaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 5) {
                result = shaderPrograms[5]
            }
            return result
        }

    /**
     * shader program for blur
     */
    val blurShaderProgram: WebGLProgram?
        get() {
            var result: WebGLProgram? = null
            val shaderPrograms = this.shaderPrograms
            if (shaderPrograms != null
                && shaderPrograms.size > 6) {
                result = shaderPrograms[6]
            }
            return result
         }

    /**
     * glrs interface
     */
    var glrs : glrs.InitOutput? = null

 
    /**
     * vertex buffer for button 
     */
    var buttonBuffer : WebGLBuffer? = null


    /**
     * vertex normal vector for buttons 
     */
    var buttonNormalVecBuffer : WebGLBuffer? = null

    /**
     * specular buffer for button
     */
    var buttonSpecularBuffer : WebGLBuffer? = null


    /**
     * ambient buffer for button
     */
    var buttonAmbientBuffer: WebGLBuffer? = null


    /**
     * duffuse buffer for button
     */
    var buttonDiffuseBuffer: WebGLBuffer? = null


    /**
     * shiness buffer for button
     */
    var buttonShinessBuffer: WebGLBuffer? = null


    /**
     * glow buffer for button
     */
    var buttonGlowBuffer: WebGLBuffer? = null

    /**
     * button texture coordinates buffer
     */
    var buttonTextureCoordinatesBuffer : WebGLBuffer? = null
     

    /**
     * color buffer for picking
     */
    var buttonPickingColorBuffer : WebGLBuffer? = null

    /**
     * color buffer for hint with effect
     */
    var hintButtonColorBuffer: WebGLBuffer? = null
    
    /**
     * color buffer for opening button with effect
     */
    var openingButtonColorBuffer:WebGLBuffer? = null

    /**
     * buffer for point light editing
     */
    var pointLightMarkerBuffer: WebGLBuffer? = null


    /**
     * buffer for screen rect 
     */
    var screenVerticesBuffer: WebGLBuffer? = null 


    /**
     * buffer for screen texture buffer
     */
    var screenTextureCoordinatesBuffer: WebGLBuffer? = null

    /**
     * texture for button 
     */
    var buttonTexture : WebGLTexture? = null
    
    /**
     * vertex buffer for board
     */
    var boardBuffer : WebGLBuffer? = null


    /**
     * vertex buffer for lighting editing
     */
    var lightingTableBuffer: WebGLBuffer? = null


    /**
     * board specular buffer for display 
     */
    var boardSpecularBuffer : WebGLBuffer? = null

    /**
     * board ambient buffer for display 
     */
    var boardAmbientBuffer : WebGLBuffer? = null

    /**
     * board diffuse buffer for display 
     */
    var boardDiffuseBuffer : WebGLBuffer? = null

    /**
     * board shiness buffer for display 
     */
    var boardShinessBuffer : WebGLBuffer? = null


    /**
     * board color buffer for picking
     */
    var boardPickingColorBuffer : WebGLBuffer? = null

    /**
     * vertex normal vector buffer for board
     */
    var boardNormalVecBuffer : WebGLBuffer? = null
    
    /**
     * texture for board
     */
    var boardTexture : WebGLTexture? = null

    /**
     * shadow depth texture
     */
    var shadowDepthTexture: WebGLTexture? = null


    /**
     * web gl texture
     */
    var sceneTexture: WebGLTexture? = null


    /**
     * effect #0 texture. this texture is also initial source for effect.
     */
    val effect0Texture: WebGLTexture? 
        get() {
            return glowTexture
        }


    /**
     * effect #1 texture. this texture is alse final effect
     */
    val effect1Texture: WebGLTexture? 
        get() {
            return effectTexture
        }

    /**
     * effect texture for screen
     */
    var effectTexture: WebGLTexture? = null


    /**
     * texture for glow scene
     */
    var glowTexture: WebGLTexture? = null


    /**
     * board texture coordinate buffer
     */
    var boardTextureCoordinateBuffer : WebGLBuffer? = null
    
    /**
     * offscreen buffer for picking
     */
    var workableFramebuffer : WebGLFramebuffer? = null

    /**
     * shadow depth frame buffer
     */
    var shadowDepthFramebuffer: WebGLFramebuffer? = null


    /**
     * scene frame buffer
     */
    var sceneFramebuffer: WebGLFramebuffer? = null


    /**
     * effect framebuffer
     */
    var effectFramebuffer: WebGLFramebuffer? = null

    /**
     * glow framebuffer
     */
    var glowFramebuffer: WebGLFramebuffer? = null


    /**
     * depth buffer for working
     */
    var depthBufferForWorking : WebGLRenderbuffer? = null

    /**
     * depth buffer for shadow depth rendering
     */
    var depthForShadowDepth: WebGLRenderbuffer? = null

    /**
     * buffer for picking some objects
     */
    var pickingBuffer: WebGLRenderbuffer? = null

    /**
     * depth buffer for rendering main scene
     */
    var sceneDepthbuffer: WebGLRenderbuffer? = null

    /**
     * depth buffer for effect
     */
    var effectDepthbuffer: WebGLRenderbuffer? = null



    /**
     * detph buffer for glow scene.
     */
    var glowDepthbuffer: WebGLRenderbuffer? = null


    /**
     * buttons location
     */
    var buttonMatrices : Array<FloatArray>? = null

    /**
     * matrix for button's normal vector.
     */
    var buttonNormalVecMatrices : Array<FloatArray>? = null

    /**
     * button matrices for displaying
     */
    var buttonMatricesForDrawing : Array<FloatArray>? = null 


    /**
     * matrix for button's normal vector.
     */
    var buttonNormalVecMatricesForDrawing : Array<FloatArray>? = null

    /**
     * spin vertical motion matrices and highest point indices
     */
    var spinVMotionMatricesIndex: Pair<Array<FloatArray>, IntArray>? = null


    /**
     * spin and vertical motion matrices
     */
    val spinAndVMotionMatrices: Array<FloatArray>? 
        get() {
            return spinVMotionMatricesIndex?.first
        }

    /**
     * spin motion matrices
     */
    var spinMotionMatrices: Array<FloatArray>? = null


    /**
     * board location
     */
    var boardMatrix : FloatArray? = null

    /**
     * board normal vector matrix
     */
    var boardNormalVecMatrix : FloatArray? = null


    /**
     * lighting table model matrix
     */
    var lightingTableMatrix : Float32Array? = null


    /**
     * manage blinking button 
     */
    var hintButtonBlinking: Blinking? = null

    /**
     * manage blinking button 
     */
    var openingButtonBlinking: Blinking? = null
     
      
    /**
     * create clone buttons matrices 
     */
    fun cloneButtonMatrices() : Array<FloatArray>? {
        var result : Array<FloatArray>? = null
        val buttonMatrices = this.buttonMatrices
        if (buttonMatrices != null) {
            result = Array<FloatArray>(buttonMatrices.size) {
                i ->
                buttonMatrices[i].copyOf()
            }
        }
        return result
    }

    /**
     * update shadow depth texture
     */
    internal fun updateShadowDepthTexture(
        gl: WebGLRenderingContext,
        texture: WebGLTexture?) {
        val depthTexture = this.shadowDepthTexture 
        if (depthTexture != texture) {
            if (depthTexture != null) {
                gl.deleteTexture(depthTexture)
            } 
            this.shadowDepthTexture = texture
        }
    }

    /**
     * update depth reder buffer for shadow depth
     */
    internal fun updateDepthForShadowDepth(gl: WebGLRenderingContext,
        buffer: WebGLRenderbuffer?) {

        val depthbuffer = this.depthForShadowDepth   
        if (buffer != depthbuffer) {
            if (depthbuffer != null) {
                gl.deleteRenderbuffer(depthbuffer)
            }
            this.depthForShadowDepth = buffer
        }
    } 

    /**
     * update shadow depth frame buffer
     */
    internal fun updateShadowDepthFramebuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLFramebuffer?) {
        val framebuffer = this.shadowDepthFramebuffer
        if (buffer != framebuffer) {
            if (framebuffer != null) {
                gl.deleteFramebuffer(framebuffer)
            }
            this.shadowDepthFramebuffer = buffer
        }
    } 

    /**
     * update effect texture 
     */
    internal fun updateEffectTexture(
        gl: WebGLRenderingContext,
        texture: WebGLTexture?) {
        val effectTexture = this.effectTexture
        if (effectTexture != texture) {
            if (effectTexture != null) {
                gl.deleteTexture(effectTexture)
            } 
            this.effectTexture = texture
        }
    }

    /**
     * update glow texture
     */
    internal fun updateGlowTexture(
        gl: WebGLRenderingContext,
        texture: WebGLTexture?) {
        val glowTexture = this.glowTexture 
        if (glowTexture != texture) {
            if (glowTexture != null) {
                gl.deleteTexture(glowTexture)
            } 
            this.glowTexture = texture
        }
    }


 
    /**
     * update scene texture
     */
    internal fun updateSceneTexture(
        gl: WebGLRenderingContext,
        texture: WebGLTexture?) {
        val sceneTexture = this.sceneTexture 
        if (sceneTexture != texture) {
            if (sceneTexture != null) {
                gl.deleteTexture(sceneTexture)
            } 
            this.sceneTexture = texture
        }
    }

    /**
     * update depth reder buffer for scene
     */
    internal fun updateSceneDepthBuffer(gl: WebGLRenderingContext,
        buffer: WebGLRenderbuffer?) {

        val depthbuffer = this.sceneDepthbuffer
        if (buffer != depthbuffer) {
            if (depthbuffer != null) {
                gl.deleteRenderbuffer(depthbuffer)
            }
            this.sceneDepthbuffer = buffer
        }
    } 

    /**
     * update depth buffer for effect
     */
    internal fun updateEffectDepthBuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLRenderbuffer?) {

        val depthbuffer = this.effectDepthbuffer
        if (buffer != depthbuffer) {
            if (depthbuffer != null) {
                gl.deleteRenderbuffer(depthbuffer)
            }
            this.effectDepthbuffer = buffer
        }
    } 



    /**
     * update depth buffer for glow 
     */
    internal fun updateGlowDepthBuffer(gl: WebGLRenderingContext,
        buffer: WebGLRenderbuffer?) {

        val depthbuffer = this.glowDepthbuffer
        if (buffer != depthbuffer) {
            if (depthbuffer != null) {
                gl.deleteRenderbuffer(depthbuffer)
            }
            this.glowDepthbuffer = buffer
        }
    } 


    /**
     * update main scene frame buffer
     */
    internal fun updateSceneFramebuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLFramebuffer?) {
        val framebuffer = this.sceneFramebuffer
        if (buffer != framebuffer) {
            if (framebuffer != null) {
                gl.deleteFramebuffer(framebuffer)
            }
            this.sceneFramebuffer = buffer
        }
    } 

    /**
     * update effect frame buffer
     */
    internal fun updateEffectFramebuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLFramebuffer?) {
        val framebuffer = this.effectFramebuffer
        if (buffer != framebuffer) {
            if (framebuffer != null) {
                gl.deleteFramebuffer(framebuffer)
            }
            this.effectFramebuffer = buffer
        }
    }



    /**
     * update glow scene frame buffer
     */
    internal fun updateGlowFramebuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLFramebuffer?) {
        val framebuffer = this.glowFramebuffer
        if (buffer != framebuffer) {
            if (framebuffer != null) {
                gl.deleteFramebuffer(framebuffer)
            }
            this.glowFramebuffer = buffer
        }
    } 


    
    fun tearDown(gl : WebGLRenderingContext) {
        teardownBuffer(gl)
        teardownRenderbuffer(gl)
        teardownShaderProgram(gl) 
        teardownFramebuffer(gl)
        teardownTextures(gl)
        teardonwMatrix()
    }
    /**
     * free shader program
     */
    fun teardownShaderProgram(gl: WebGLRenderingContext) {
        val shaderPrograms = this.shaderPrograms
        if (shaderPrograms != null) {
            shaderPrograms.forEach {
                if (it != null) {
                    val shaders = gl.getAttachedShaders(it)
                    if (shaders != null) {
                        shaders.forEach({
                            shader->
                            gl.detachShader(it, shader)
                            gl.deleteShader(shader)
                        })
                    }
                    gl.deleteProgram(it)
                }
            }
            this.shaderPrograms = null
        } 
    }
    /**
     * free buffer
     */
    private fun teardownBuffer(gl: WebGLRenderingContext) {
        teardownEffect(gl)
        teardownMaterial(gl)
        arrayOf(buttonBuffer, 
            buttonNormalVecBuffer,
            buttonPickingColorBuffer,
            buttonTextureCoordinatesBuffer,
            boardBuffer,
            boardNormalVecBuffer,
            boardTextureCoordinateBuffer,
            boardPickingColorBuffer,
            lightingTableBuffer,
            pointLightMarkerBuffer,
            screenVerticesBuffer,
            screenTextureCoordinatesBuffer).forEach {
                buffer ->
                if (buffer != null) {
                    gl.deleteBuffer(buffer)
                }
            }
        screenVerticesBuffer = null
        screenTextureCoordinatesBuffer = null
        lightingTableBuffer = null
        pointLightMarkerBuffer = null
        buttonBuffer = null
        buttonNormalVecBuffer = null
        buttonTextureCoordinatesBuffer = null
        buttonPickingColorBuffer = null
        boardBuffer = null
        boardNormalVecBuffer = null
        boardPickingColorBuffer = null
        boardTextureCoordinateBuffer = null
    }

    /**
     * set button material buffer
     */
    fun setButtonMaterialbuffer(gl: WebGLRenderingContext,
        specularBuffer: WebGLBuffer?,
        ambientBuffer: WebGLBuffer?,
        diffuseBuffer: WebGLBuffer?,
        shinessBuffer: WebGLBuffer?) {
        if (this.buttonSpecularBuffer != specularBuffer) {
            if (this.buttonSpecularBuffer != null) {
                gl.deleteBuffer(this.buttonSpecularBuffer)
            }
            this.buttonSpecularBuffer = specularBuffer
        } 
        if (this.buttonAmbientBuffer != ambientBuffer) {
            if (this.buttonAmbientBuffer != null) {
                gl.deleteBuffer(this.buttonAmbientBuffer)
            }
            this.buttonAmbientBuffer = ambientBuffer
        } 
        if (this.buttonDiffuseBuffer != diffuseBuffer) {
            if (this.buttonDiffuseBuffer != null) {
                gl.deleteBuffer(this.buttonDiffuseBuffer)
            }
            this.buttonDiffuseBuffer = diffuseBuffer
        } 
        if (this.buttonShinessBuffer != shinessBuffer) {
            if (this.buttonShinessBuffer != null) {
                gl.deleteBuffer(this.buttonShinessBuffer)
            }
            this.buttonShinessBuffer = shinessBuffer
        } 
    }


    /**
     * set board material buffer
     */
    fun setBoardMaterialbuffer(gl: WebGLRenderingContext,
        specularBuffer: WebGLBuffer?,
        ambientBuffer: WebGLBuffer?,
        diffuseBuffer: WebGLBuffer?,
        shinessBuffer: WebGLBuffer?) {
        if (this.boardSpecularBuffer != specularBuffer) {
            if (this.boardSpecularBuffer != null) {
                gl.deleteBuffer(this.boardSpecularBuffer)
            }
            this.boardSpecularBuffer = specularBuffer
        } 
        if (this.boardAmbientBuffer != ambientBuffer) {
            if (this.boardAmbientBuffer != null) {
                gl.deleteBuffer(this.boardAmbientBuffer)
            }
            this.boardAmbientBuffer = ambientBuffer
        } 
        if (this.boardDiffuseBuffer != diffuseBuffer) {
            if (this.boardDiffuseBuffer != null) {
                gl.deleteBuffer(this.boardDiffuseBuffer)
            }
            this.boardDiffuseBuffer = diffuseBuffer
        } 
        if (this.boardShinessBuffer != shinessBuffer) {
            if (this.boardShinessBuffer != null) {
                gl.deleteBuffer(this.boardShinessBuffer)
            }
            this.boardShinessBuffer = shinessBuffer
        } 
    }

    /**
     * set button effect related data
     */
    fun setButtonEffect(gl: WebGLRenderingContext,
        glowBuffer: WebGLBuffer?,
        hintColorBuffer: WebGLBuffer?,
        openingColorBuffer: WebGLBuffer?) {
        if (this.buttonGlowBuffer !== glowBuffer) {
            if (this.buttonGlowBuffer != null) {
                gl.deleteBuffer(this.buttonGlowBuffer) 
            }
            this.buttonGlowBuffer = glowBuffer
        }
        if (this.hintButtonColorBuffer !== hintColorBuffer) {
            if (this.hintButtonColorBuffer != null) {
                gl.deleteBuffer(this.hintButtonColorBuffer)
            }
            this.hintButtonColorBuffer = hintColorBuffer
        }
        if (this.openingButtonColorBuffer !== openingColorBuffer) {
            if (this.openingButtonColorBuffer != null) {
                gl.deleteBuffer(this.openingButtonColorBuffer)
            } 
            this.openingButtonColorBuffer = openingColorBuffer
        }
    }

    
    /**
     * tear down material buffers
     */
    fun teardownMaterial(gl: WebGLRenderingContext) {
        setButtonMaterialbuffer(gl, null, null, null, null)
        setBoardMaterialbuffer(gl, null, null, null, null) 
    }

    /**
     * tear down effect buffer
     */
    fun teardownEffect(gl: WebGLRenderingContext) {
        setButtonEffect(gl, null, null, null)
    }


    fun teardownRenderbuffer(gl: WebGLRenderingContext) {
        arrayOf(pickingBuffer,
            depthBufferForWorking,
            depthForShadowDepth,
            sceneDepthbuffer,
            effectDepthbuffer,
            glowDepthbuffer).forEach { 
                buffer ->
                if (buffer != null) {
                    gl.deleteRenderbuffer(buffer)
                }
            }
        depthBufferForWorking = null
        depthForShadowDepth = null
        pickingBuffer = null
        sceneDepthbuffer = null
        glowDepthbuffer = null
        effectDepthbuffer = null
    }
    fun teardownFramebuffer(gl: WebGLRenderingContext) {
        arrayOf(workableFramebuffer,
                shadowDepthFramebuffer,
                sceneFramebuffer,
                effectFramebuffer,
                glowFramebuffer).forEach { 
                buffer ->
                if (buffer != null) {
                    gl.deleteFramebuffer(buffer)
                }
            }
        shadowDepthFramebuffer = null
        workableFramebuffer = null
        sceneFramebuffer = null
        glowFramebuffer = null
        effectFramebuffer = null
    }
    /**
     * destroy all textures
     */
    fun teardownTextures(gl: WebGLRenderingContext) {
        arrayOf(
            buttonTexture,
            boardTexture,
            shadowDepthTexture,
            sceneTexture,
            effectTexture,
            glowTexture).forEach { 
                gl.deleteTexture(it)
            }
        this.buttonTexture = null
        this.boardTexture = null
        this.shadowDepthTexture = null
        this.sceneTexture = null
        this.glowTexture = null
        this.effectTexture = null
    }

    fun teardonwMatrix() {
        this.buttonMatrices = null
        this.boardMatrix = null
    }


}

// vi: se ts=4 sw=4 et:
