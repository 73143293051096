package net.oc_soft.mswp
import net.oc_soft.mswp.ui.Grid
import net.oc_soft.mswp.ui.ShaderPrograms
import kotlinx.browser.document
import kotlinx.browser.window
import kotlin.js.Promise
import kotlin.js.Json
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.MutationRecord
import org.w3c.dom.Node
import org.w3c.dom.Element
import org.w3c.dom.Image
import org.w3c.dom.HTMLElement
import org.w3c.dom.DocumentReadyState
import org.w3c.dom.LOADING
import org.w3c.dom.events.Event

import kotlinx.browser.window
import kotlinx.browser.document
import kotlin.collections.Set
import kotlin.collections.HashSet
import net.oc_soft.mswp.ui.GridSettings
import net.oc_soft.mswp.ui.AppSettings
import net.oc_soft.mswp.ui.Persistence
import net.oc_soft.mswp.ui.IconSetting
import net.oc_soft.mswp.ui.Bloom
import net.oc_soft.mswp.ui.Effector
import net.oc_soft.mswp.ui.Flag
import net.oc_soft.mswp.ui.ColorMaterial
import net.oc_soft.mswp.storage.Session

/**
 * main page display
 */
actual class MainPage {
        
    companion object {
    }
    /**
     * configuration
     */
    val config: MainPageConfig = MainPageConfig()

    /**
     * game grid
     */
    var grid : Grid? = null
    /**
     * model
     */
    var model: Model? = null

    /**
     * camera
     */
    var camera: Camera? = null

    /**
     * light
     */
    var pointLight: PointLight? = null

    /**
     * color scheme
     */
    var colorScheme: ColorScheme? = null
 
    /**
     * start gaming
     */
    var runPlayground : (()->Unit)? = null


    /**
     * setting
     */
    var appSettings: AppSettings = AppSettings(config.appSettings)

    /**
     * setup body
     */ 
    actual fun setupBody(model : Model, camera: Camera, 
        pointLight: PointLight,
        colorScheme: ColorScheme) {
        this.model = model
        this.camera = camera
        this.pointLight = pointLight        
        this.colorScheme = colorScheme
    }
    /**
     * setup for html page
     */
    actual fun setup(settings: Settings) {
    }     
    /**
     * run program
     */ 
    fun run(settings: Any?) {
        var settingObj : dynamic
        settingObj = settings
        if (settingObj != null) {
            var promises = ArrayList<Promise<Any>>()
            promises.add(loadFont(settingObj.textureText))
            promises.add(Polyfill.load())
            Promise.all(promises.toTypedArray()).then {
                setupBodyI(model!!, 
                camera!!, 
                pointLight!!,
                colorScheme!!,
                settingObj.ui) 
            }
        }
    }
    fun setupBodyI(model : Model, 
        camera: Camera, 
        pointLight: PointLight,
        colorScheme: ColorScheme,
        uiSetting: Json) {
        val hdlr = createLoadedHandler(
            model, camera, pointLight, colorScheme, uiSetting)
        appSettings.runtimeConfig = uiSetting

        if (DocumentReadyState.LOADING == document.readyState) {
            document.addEventListener(
                "DOMContentLoaded", { hdlr() }, object {
                    @JsName("once")
                    val once = true
                })
        } else {
                hdlr()
        }
    }

    /**
     * create handler to respond when window loaded.
     */
    fun createLoadedHandler(
        model : Model, 
        camera: Camera, 
        pointLight: PointLight,
        colorScheme: ColorScheme,
        uiSetting: Json): ()->Unit  {
        appSettings.runtimeConfig = uiSetting
        val result: ()->Unit = { 
            val grid = Grid(appSettings.option.pointLightSettingOption)
            val flag = Flag(appSettings.option.flagOption)
           
            var promises = ArrayList<Promise<Any>>()

            var shaderPrograms: ShaderPrograms? = null
            promises.add(
                Persistence.loadShaderPrograms().then({
                    shaderPrograms = it as ShaderPrograms
                }))
            promises.add(
                Promise({
                    resolve, reject ->
                    Assets.loadGlrsWasm().then({ 
                        it?.let {
                            glrs.init(it).then {
                                resolve(Unit)
                                grid.glrs = it
                            }
                        }?: reject(IllegalStateException())
                    })
                }))

            promises.add(
                Assets.loadLiffId().then({
                    appSettings.liffId = it!!.trim()
                }))


            promises.add(
                Persistence.loadIcon().then({ 
                    it?.let {
                        config.gridSettings.iconSetting.replaceIcons(it)
                    }
                    Unit
                }))
            

            var pointLightParam = pointLight
            promises.add(
                Persistence.loadPointLight().then({
                    it?.let {
                        this.pointLight = it
                        pointLightParam = it
                    }
                    Unit
                }))
            var colorSchemeParam = colorScheme
            promises.add(
                Persistence.loadColorScheme().then({
                    it?.let {
                        this.colorScheme = it
                        colorSchemeParam = it
                    }
                    Unit
                }))
            var effector: Effector? = null
            promises.add(
                Persistence.loadEffector().then({
                    effector = it
                }))
            var bloom: Bloom? = null
            promises.add(
                Persistence.loadBloom().then({
                    bloom = it
                }))

            var colorMaterial: ColorMaterial? = null
            promises.add(
                Persistence.loadColorMaterial().then({
                    colorMaterial = it
                }))
    
            Promise.all(promises.toTypedArray()).then({
                responses : Array<out Any> -> 
                val env = Environment(
                    appSettings.option.environmentOption,
                    colorSchemeParam)
                flag.logic = model.logic
                flag.bind(document.body!!)

                grid.bind(config.gridSettings,
                    model, camera, 
                    pointLightParam, 
                    flag,
                    colorSchemeParam,
                    colorMaterial!!,
                    env,
                    bloom!!,
                    effector!!,
                    shaderPrograms!!,
                    appSettings)
            }).then({
               appSettings.bind() 
            }).then({
                readyToPlay()
            }).then({
                postRunStaringUpOperation()
            })
            Unit
        }
        return result
    }

    /**
     * the program is ready to play now
     */
    fun readyToPlay() {

        document.querySelector(config.splashPaneId)?.let {
            it.querySelector(".loading")?.let { 
                (it as HTMLElement).style.display = "none"
            } 
            val elem = it as HTMLElement
            elem.style.height = "0" 
        }
    }


    /**
     * post the command to run starting up operation
     */
    fun postRunStaringUpOperation() {
        window.setTimeout({
            runStartingUpOperation()
        })
    }

    /**
     * run operation at start up
     */
    fun runStartingUpOperation() {
        Session.loadLineSession() 
        
    }

    /**
     * load font
     */
    fun loadFont(textToLoad : String) : Promise<Unit> {
        val result = Promise<Unit> {
            resolve, _ -> Unit  
            val activeCallback = {
                resolve(Unit) 
            }
            val inactiveCallback = {
                resolve(Unit)
                // reject(Error("failed"))
            }
            val config : dynamic = WebFontConfig(activeCallback, 
                inactiveCallback,
                textToLoad)
            WebFont.load(config)
        }
        return result
    }
}

// vi: se ts=4 sw=4 et:
