package line


/**
 * container
 */
external interface FlexBox: FlexComponent, FlexHero {
    
    /**
     * layout
     */
    val layout: String


    /**
     * contents
     */
    val contents: Array<FlexComponent>


    /**
     * background color
     */
    val backgroundColor: String?
    

    /**
     * border color
     */
    val borderColor: String?

    /**
     * border width
     */
    val borderWidth: String?

    /**
     * corner radius
     */
    val cornerRadius: String?

    /**
     * width
     */
    val width: String?
    

    /**
     * height
     */
    val height: String?


    /**
     * ratio width or height
     */
    val flex: Int?

    /**
     * spacing
     */ 
    val spacing: String?
    
    /**
     * margin
     */
    val margin: String?

    /**
     * padding
     */
    val paddingAll: String?

    /**
     * padding top
     */
    val paddingTop: String?

    /**
     * padding bottom
     */
    val paddingBottom: String?

    /**
     * padding start
     */
    val paddingStart: String?

    /**
     * padding end 
     */
    val paddingEnd: String?


    /**
     * position
     */
    val position: String?

    /**
     * offset top
     */
    val offsetTop: String?

    /**
     * offset bottom
     */
    val offsetBottom: String?

    /**
     * offset start
     */
    val offsetStart: String?

    /**
     * offset end 
     */
    val offsetEnd: String?

    /**
     * action object
     */
    val action: Action?

    /**
     * child element justification
     */
    val justifyContent: String?

    /**
     * child elements alignments
     */
    val alignments: String?
    

    /**
     * background
     */
    val background: LinearGradient?
    
}

/**
 * create flex box
 */
fun createFlexBox(
    layout: String,
    contents: Array<FlexComponent>,
    backgroundColor: String? = null,
    borderColor: String? = null,
    borderWidth: String? = null,
    cornerRadius: String? = null,
    width: String? = null,
    height: String? = null,
    flex: Int? = null,
    spacing: String? = null,
    margin: String? = null,
    paddingAll: String? = null,
    paddingTop: String? = null,
    paddingBottom: String? = null,
    paddingStart: String? = null,
    paddingEnd: String? = null,
    position: String? = null,
    offsetTop: String? = null,
    offsetBottom: String? = null,
    offsetStart: String? = null,
    offsetEnd: String? = null,
    action: Action? = null,
    justifyContent: String? = null,
    alignments: String? = null,
    background: LinearGradient? = null): FlexBox {
    val nameAndValues = arrayOf(
        Pair<String, Any?>("layout", layout),
        Pair<String, Any?>("contents", contents),
        Pair<String, Any?>("backgroundColor", backgroundColor),
        Pair<String, Any?>("borderColor", borderColor),
        Pair<String, Any?>("borderWidth", borderWidth),
        Pair<String, Any?>("cornerRadius", cornerRadius),
        Pair<String, Any?>("width", width),
        Pair<String, Any?>("height", height),
        Pair<String, Any?>("flex", flex),
        Pair<String, Any?>("spacing", spacing),
        Pair<String, Any?>("margin", margin),
        Pair<String, Any?>("paddingAll", paddingAll),
        Pair<String, Any?>("paddingTop", paddingTop),
        Pair<String, Any?>("paddingBottom", paddingBottom),
        Pair<String, Any?>("paddingStart", paddingStart),
        Pair<String, Any?>("paddingEnd", paddingEnd),
        Pair<String, Any?>("position", position),
        Pair<String, Any?>("offsetTop", offsetTop),
        Pair<String, Any?>("offsetBottom", offsetBottom),
        Pair<String, Any?>("offsetStart", offsetStart),
        Pair<String, Any?>("offsetEnd", offsetEnd),
        Pair<String, Any?>("action", action),
        Pair<String, Any?>("justifyContent", justifyContent),
        Pair<String, Any?>("alignments", alignments),
        Pair<String, Any?>("background", background)) 

    val result: dynamic = object { }
    result["type"] = "box"

    nameAndValues.forEach {
        if (it.second != null) {
            result[it.first] = it.second
        }
    }
    return result
}
 

// vi: se ts=4 sw=4 et:
