package net.oc_soft.mswp.storage

import kotlin.math.roundToInt

import kotlin.collections.Map
import kotlin.collections.HashMap

import kotlin.text.toBoolean
import kotlin.text.toIntOrNull

import net.oc_soft.mswp.Logic
import net.oc_soft.mswp.Status
import net.oc_soft.mswp.CellIndex

import net.oc_soft.mswp.ColorScheme
import net.oc_soft.mswp.ColorMaterialConvert
import net.oc_soft.mswp.ui.IconSetting
import net.oc_soft.mswp.ui.Effector
import net.oc_soft.mswp.ui.Bloom
import net.oc_soft.mswp.ui.ColorMaterial
import net.oc_soft.mswp.ui.ShaderPrograms
import net.oc_soft.mswp.settings.LineSession

/**
 * coder decoder 
 */
class Codec {

    companion object {
        /**
         * convert icon map to dynamic object
         */
        fun iconMapToDynamic(iconMap : Map<String, Icon>): dynamic {
            val result: dynamic = object {}
            val icons: dynamic = object {}
            iconMap.forEach({
                icons[it.key] = it.value 
            })
            result["version"] = "0.2"
            result["icons"] = icons
            return result
        }
        /**
         * convert dynamic object to icon map
         */
        fun dynamicToIconMap(iconData: dynamic): Map<String, Icon>? {
            val verstr = iconData["version"] as String?
            var result : Map<String, Icon>? = null
            if (verstr != null) {
                val icons = iconData["icons"] 
                if (icons != null) {
                    val strIconMap = HashMap<String, Icon>()
                    var keys: Array<String>
                    if ("0.1" == verstr) {
                        keys = arrayOf<String>(
                            IconSetting.NG_ICON,
                            IconSetting.OK_ICON)
                    } else {
                        keys = arrayOf<String>(
                            IconSetting.NG_ICON,
                            IconSetting.OK_ICON,
                            IconSetting.FLAG_ICON)
                    }

                    keys.forEach {
                        val item = icons[it]
                         
                        val prefix = item["prefix"]
                        val iconName = item["iconName"]
                        if (prefix != null && iconName != null) {
                            strIconMap[it] =
                                Icon(prefix, iconName)    
                        }
                    } 
                    result = strIconMap
                }
            } else {
                val prefix = iconData["prefix"] as String?
                val iconName = iconData["iconName"] as String?
                if (prefix != null && iconName != null) {
                    result = createIconMapFallback(Icon(prefix, iconName))
                }
                
            }
            return result
        }
        /**
         * create icon map in backward compatibility
         */
        fun createIconMapFallback(icon: Icon) : Map<String, Icon> {
            val result = HashMap<String, Icon>()
            result[IconSetting.NG_ICON] = icon

            return result
        }


        /**
         * convert dynamic object to color scheme
         */
        fun dynamicToColorScheme(
            colorScheme: dynamic) : ColorScheme? {
            var result: ColorScheme? = null
            val colorSchemeObj: dynamic = colorScheme["color-scheme"]
            val colorsSource = arrayOf(
                colorSchemeObj["colors"], 
                colorSchemeObj["environment"])
            val defaultColor = arrayOf(
                ColorScheme.colors,
                ColorScheme.envColors)

            var colorsParam: Array<Array<FloatArray>?> = arrayOf(
                null,
                null)
            for (idx0 in 0 until colorsParam.size) {
                if (colorsSource[idx0] != null) {
                    val colors0 = defaultColor[idx0]
                    val colorSource = colorsSource[idx0]
                    var replaced = false
                    if (colorSource != null) {
                        for (idx1 in 0 until colors0.size) {
                            val colorItem = colorSource["${idx1}"]
                            if (colorItem != null) {
                                for(idx2 in 0 until colors0[idx1].size) {
                                    val colorVal = colorItem["${idx2}"]
                                    if (colorVal != null) {
                                        val strColor = colorVal.toString()
                                        var intColor = strColor.toIntOrNull()
                                        if (intColor != null) {
                                            intColor = kotlin.math.min(
                                                0xff, intColor)
                                            intColor = kotlin.math.max(
                                                0, intColor)   
                                            colors0[idx1][idx2] = 
                                                intColor.toFloat() 
                                            colors0[idx1][idx2] /= 
                                                0xff.toFloat()
                                            replaced = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (replaced) {
                        colorsParam[idx0] = colors0 
                    } 
                }
            }
            if (colorsParam[0] != null
                || colorsParam[1] != null) {
                for (idx in 0 until colorsParam.size) {
                    if (colorsParam[idx] == null) {
                        colorsParam[idx] = defaultColor[idx]
                    }
                }
                result = ColorScheme(
                    colorsParam[0]!!,
                    colorsParam[1]!!)
            }
            return result
        }

        /**
         * convert color scheme to dynamic object
         */
        fun colorSchemeToDynamic(colorScheme: ColorScheme): dynamic {
            val result: dynamic = object {
            }
            val colorSchemeObj: dynamic = object {
            }
            colorSchemeObj["colors"] = Array<IntArray>(colorScheme.size) {
                val color = colorScheme[it] 
                IntArray(color.size) {
                    kotlin.math.max(kotlin.math.min(
                        (color[it] * 0xff.toFloat()).roundToInt(), 0xff), 0)
                }
            }
            colorSchemeObj["environment"] = Array<IntArray>(
                colorScheme.envColorSize) {
                val color = colorScheme.getEnvironment(it)!!
                IntArray(color.size) {
                    kotlin.math.max(kotlin.math.min(
                        (color[it] * 0xff.toFloat()).roundToInt(), 0xff), 0)
                }
            }
            result["version"] = 0
            result["color-scheme"] = colorSchemeObj
            return result
        } 

        /**
         * convert dynamic object to pointLight
         */
        fun dynamicToPointLight(
            pointLightData: dynamic) : net.oc_soft.mswp.PointLight? {
            var result : net.oc_soft.mswp.PointLight? = null
            val pointObj = pointLightData["point"] 
            if (pointObj != null) {
                if (pointObj.length > 2) {
                    val point = FloatArray(3) { 0f }
                    var valid = true 
                    for (idx in 0..2) {
                        val numStr = pointObj[idx]
                        if (numStr != null) {
                            try {
                                point[idx] = numStr.toString().toFloat()
                            } catch (ex: NumberFormatException) {
                                valid = false
                            }
                        }
                        if (!valid) {
                            break
                        }
                    }
                    if (valid) {
                        result = net.oc_soft.mswp.PointLight(point)
                    }
                }
            }
            return result
        }

        /**
         * point light to dynamic object
         */
        fun pointLightToDynamic(
            pointLight: net.oc_soft.mswp.PointLight): dynamic {
            val result: dynamic = object { } 
            result["point"] = pointLight.point 
            return result
        }


        /**
         * convert dynamic object to effector
         */
        fun dynamicToEffector(
            effectorData: dynamic): Effector {

            val postRenderingObj = effectorData["postRendering"]

            val blur = postRenderingObj["blur"]
            val stdDeviationDy = blur["standardDeviation"]
            val sizeDy = blur["size"]
            val result = Effector()
            result.postRendering.blur = Effector.GaussianBlur(
                (stdDeviationDy as Number).toFloat(),
                (sizeDy as Number).toInt())
                
            return result
        }  

        /**
         * convert dynamic object to bloom
         */
        fun dynamicToBloom(
            effectorData: dynamic): Bloom{

            val postRenderingObj = effectorData["postRendering"]

            val bloom = postRenderingObj["bloom"]
            val countOfBlur = bloom["countOfBlur"]
            val result = Bloom()
            if (countOfBlur !== null) {
                result.countOfBlur = (countOfBlur as Number).toInt()
            }
            val mineButton = bloom["mineButton"]
            if (mineButton !== null) { 
                val glowButtonScale = mineButton["glowVerticesScale"]
                if (glowButtonScale !== null) {
                    result.glowButtonVerticesScale =
                        (glowButtonScale as Number).toFloat()
                }
            }
            return result
        }  

        /**
         * convert dynamic object to color-material converter
         */
        fun dynamicToColorMaterial(
            colorMaterialObj: dynamic): ColorMaterial? {

            val materialObj = colorMaterialObj["material"]

            val conversionNames = arrayOf("mineButton", "board")
            val colorMaterialParams = Array<ColorMaterialConvert?>(
                conversionNames.size) { null }
            var validParam = true
            for (idx0 in 0 until conversionNames.size) {
                val paramMap = materialObj[conversionNames[idx0]]
                if (paramMap != null) {
                    val keys = arrayOf(
                        "specularScale",
                        "ambientScale",
                        "diffuseScale",
                        "shiness")
                    val params = Array<Float?>(keys.size) { null }
                    for (idx1 in 0 until keys.size) {
                        val param = paramMap[keys[idx1]] 
                        if (param is Number) {
                            params[idx1] = param.toFloat() 
                        } else {
                            validParam = false
                            break
                        }
                    }
                    if (validParam) {
                        colorMaterialParams[idx0] = ColorMaterialConvert(
                            params[0]!!, params[1]!!, params[2]!!, params[3]!!)
                    }
                } else {
                    validParam = false
                }
                if (!validParam) {
                    break
                }
            }
            var result: ColorMaterial? = null
            if (validParam) {
                result = ColorMaterial(
                    colorMaterialParams[0]!!,
                    colorMaterialParams[1]!!)
            }
            return result
        }


        /**
         * convert dynamic object to ShaderPrograms
         */
        fun dynamicToShaderPrograms(
            shaderPrograms: dynamic): ShaderPrograms? {
            var result: ShaderPrograms? = null
            
            val keys = arrayOf( 
                "vertex", 
                "fragment",
                "loc-reader-vertex", 
                "loc-reader-fragment",
                "point-vertex", 
                "point-fragment",
                "shadow-vertex", 
                "shadow-fragment",
                "screen-vertex", 
                "screen-fragment",
                "glow-vertex", 
                "glow-fragment",
                "screen-vertex", 
                "blur-fragment")
             
            var valid = false
            for (idx in 0 until keys.size) {
                valid = shaderPrograms[keys[idx]] != null
                if (!valid) {
                    break
                } 
            }
            if (valid) {
                result = ShaderPrograms(
                    shaderPrograms[keys[0]].toString(),
                    shaderPrograms[keys[1]].toString(),
                    shaderPrograms[keys[2]].toString(),
                    shaderPrograms[keys[3]].toString(),
                    shaderPrograms[keys[4]].toString(),
                    shaderPrograms[keys[5]].toString(),
                    shaderPrograms[keys[6]].toString(),
                    shaderPrograms[keys[7]].toString(),
                    shaderPrograms[keys[8]].toString(),
                    shaderPrograms[keys[9]].toString(),
                    shaderPrograms[keys[10]].toString(),
                    shaderPrograms[keys[11]].toString(),
                    shaderPrograms[keys[12]].toString(),
                    shaderPrograms[keys[13]].toString())
            }
            return result
        }


        /**
         * line setting to dynamic
         */
        fun lineSessionToDynamic(lineSession: LineSession): dynamic {
            val result: dynamic = object {}

            result["openShareDialog"] = lineSession.openShareDialog
            return result
        }

        /**
         * dynamic to line setting
         */
        fun dynamicToLineSession(lineSession: dynamic): LineSession? {
            val openShareDialogObj = lineSession["openShareDialog"]

            var result: LineSession? = null
            if (openShareDialogObj) {
                val openShareDialog = openShareDialogObj.toString().toBoolean() 
                result = LineSession(openShareDialog)
            }
            return result
        }

        /**
         * convert dynamic to logic
         */
        fun dynamicToLogic(logic: dynamic): Logic? {

            var result: Logic? = null

            var rowSize = logic["rowSize"].toString().toIntOrNull()
            var columnSize = logic["columnSize"].toString().toIntOrNull()
            var hitCount = logic["hitCount"].toString().toIntOrNull()
            var lockableCount = logic["lockableCount"].toString().toIntOrNull()

            var hintToStart = dynamicToCellIndex(logic["hintToStart"])

            var hitLocationsSrc = logic["hitLocations"] as Array<dynamic>?

            
            if (rowSize != null
                && columnSize != null
                && hitCount != null
                && lockableCount != null
                && hintToStart != null
                && hitLocationsSrc != null) {
                result = Logic(rowSize, columnSize, hitCount, lockableCount)

                hitLocationsSrc.forEach { 
                    val cellIndex = dynamicToCellIndex(it)
                    if (cellIndex != null) {
                        result.hitLocations.add(cellIndex)
                    }
                }
                val statusSrc = logic["status"]

                if (statusSrc != null) {
                    result.status = dynamicToStatus(statusSrc)
                }
            }
            return result
        }



        /**
         * convert logic to dynamic object
         */
        fun logicToDynamic(logic: Logic): dynamic {
            val hitLocationsSrc = logic.hitLocations.toList()

            val hitLocations = Array<Any>(hitLocationsSrc.size) {
                cellIndexToDynamic(hitLocationsSrc[it]) 
            }
            val result: dynamic = object {}

            result["rowSize"] = logic.rowSize
            result["columnSize"] = logic.columnSize
            result["hitCount"] = logic.hitCount
            result["hitLocations"] = hitLocations
            result["lockableCount"] = logic.lockableCount
            val hintToStart = logic.hintToStart
            if (hintToStart != null) {
                result["hintToStart"] = cellIndexToDynamic(hintToStart)
            }
            val status = logic.status
            if (status != null) {
                result["status"] = statusToDynamic(status)
            }
            return result
        }


        /**
         * convert dynamic to logic status
         */
        fun dynamicToStatus(status: dynamic): Status? {


            val result = Status()
            val openedCells = status.opendCells 

            val openedCellSize = openedCells.length.toString().toIntOrNull()
            if (openedCellSize != null) {
                for (i in 0 until openedCellSize) {
                    val cell = dynamicToCellIndex(openedCells[i]) 
                    if (cell != null) {
                        result.registerOpened(cell[0], cell[1])
                    }
                }
            }
            val lockingCells = status.lockingCells
            val lockingCellSize = lockingCells.toString().toIntOrNull()
            if (lockingCellSize != null) {
                for (i in 0 until lockingCellSize) {
                    val cell = dynamicToCellIndex(lockingCells[i])
                    if (cell != null) {
                        result.lockCell(cell[0], cell[1])
                    }
                }
            }
            return result
        }


        /**
         * convert logic status to dynamic
         */
        fun statusToDynamic(status: Status): dynamic {
            val result: dynamic = object {}
            val openedCellsSrc = status.getOpenedCellsRef().toList()
            
            val openedCells = Array<Any>(openedCellsSrc.size) {
                cellIndexToDynamic(openedCellsSrc[it])    
            }

            val lockingCellsSrc = status.getLockingCellsRef().toList()
            val lockingCells = Array<Any>(lockingCellsSrc.size) {
                cellIndexToDynamic(lockingCellsSrc[it])
            }
            
            result["openedCells"] = openedCells
            result["lockingCells"] = lockingCells

            return result;
        }

        /**
         * cell index to dynamic
         */
        fun cellIndexToDynamic(cellIndex: CellIndex): dynamic {
            val result: dynamic = cellIndex.toIntArray() 
            return result
        }


        /**
         * dynamic to cell index
         */
        fun dynamicToCellIndex(cellIndex: dynamic): CellIndex? {
            var result: CellIndex? = null
            if (cellIndex.length > 1) {
                val rowIdx = cellIndex[0].toString().toIntOrNull()
                val colIdx = cellIndex[1].toString().toIntOrNull()
                if (rowIdx != null && colIdx != null) {
                    result = CellIndex(rowIdx, colIdx)
                }
            }
            return result 
        }

        /**
         * dynamic to map
         */
        fun dynamicToMap(map: dynamic): Map<String, Any?> {
            val keys = js("Object.keys")(map)
            val keysLength = keys.length.toString().toIntOrNull()
            val result = HashMap<String, Any?>()
            if (keysLength != null) {
                for (i in 0 until keysLength) {
                    result[keys[i].toString()] = map[keys[i]]
                }
            }
            return result
        }
    }
 
    /**
     * icon data
     */
    data class Icon(
        @JsName("prefix")
        val prefix: String,
        @JsName("iconName")
        val iconName: String) 
}
// vi: se ts=4 sw=4 et:
