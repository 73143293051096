package net.oc_soft.mswp.ui

import kotlin.math.*
import org.w3c.dom.*
import org.khronos.webgl.*

import kotlin.collections.Set
import kotlin.random.Random
import kotlin.js.Promise

import kotlinx.browser.document
import kotlinx.browser.window

import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.Image
import org.w3c.files.Blob
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLElement
import net.oc_soft.mswp.*
import net.oc_soft.mswp.ui.grid.*
import net.oc_soft.gl.Viewport

import net.oc_soft.mswp.LogicContainer


/**
 * game play ground grid
 */
class Grid(val pointLightSettingOption: PointLightSetting.Option,
    rowCount: Int = 6,
    columnCount: Int = 6,
    colorScheme: ColorScheme = ColorScheme(),
    colorMap: ColorMap = ColorMap(),
    val buttons: Buttons = Buttons(
        MineButton(colorScheme), rowCount, columnCount,
        floatArrayOf(0.02f, 0.02f), floatArrayOf(0.01f, 0.005f), colorMap),
    board: Board = Board(colorScheme),
    pointLightEdit: PointLight = PointLight(),
    effector: Effector = Effector(),
    shadowMap: ShadowMap = ShadowMap(),
    bloom: Bloom = Bloom(),
    val borderGap: FloatArray = floatArrayOf(0.02f, 0.02f),
    val boardEdge: FloatArray = floatArrayOf(0.03f, 0.03f),
    val glyph: Glyph = Glyph(colorScheme),
    val textures: Textures = Textures(),
    val scene: Scene = Scene(),

    val screen: Screen = Screen(),
    val locReader: LocReader = LocReader(),
    val renderingCtx: RenderingCtx = RenderingCtx(),
    val animation: Animation = Animation()) {
    var model : Model? = null
    /**
     * open gl shader programs
     */
    var shaderPrograms : ShaderPrograms? = null

    /**
     * application settings
     */
    var appSettings: AppSettings? = null
        set(value) {
            if (value != field) {
                val oldAppSettings = field 
                if (oldAppSettings != null) {
                    oldAppSettings.handleToEditLighting = null
                    oldAppSettings.colorSchemeContainer = null
                    oldAppSettings.capture = null
                    oldAppSettings.logicContainer = null
                }
                field = value
                if (value != null) {
                    value.handleToEditLighting = { 
                        onEditLightSetting()
                    }
                    value.capture = capture
                    value.colorSchemeContainer = colorSchemeContainer
                    value.logicContainer = logicContainer
                }
            }
        }

    /**
     * capture
     */
    val capture: Capture
        get() {
            return object: Capture {
                override fun screen(): Blob? {
                    return imageCapture.lastImage  
                 }
            }
        }

    /**
     * logic container
     */
    val logicContainer: LogicContainer
        get() {
            val grid = this
            return object: LogicContainer {
                override val logic: Logic?
                    get() {
                        var result: Logic? = null
                        val model = grid.model
                        if (model != null) {
                            result = model.logic
                        } 
                        return result
                    }
            }
        }

    /**
     * glrs interface
     */
    var glrs : glrs.InitOutput? 
        get() {
            return renderingCtx.glrs
        }
        set(value) {
            renderingCtx.glrs = value
        }

    /**
     * camera
     */
    var sceneCamera : Camera? = null
        set(value) {
            if (field != value) {
                if (field != null) {
                    detachCameraListener()
                }
                field = value
                if (field != null) {
                    attachCameraListener()
                }
            }
        }

  
    /**
     * point lighting
     */
    var pointLight: net.oc_soft.mswp.PointLight?
        get() {
            return pointLightEdit.pointLight
        }
        set(value) {
            pointLightEdit.pointLight = value
        }

    /**
     * flag 
     */
    var flag: Flag? = null

    /**
     * buttons row count
     */
    val rowCount: Int
        get() {
            return buttons.rowCount
        }
    /**
     * buttons column count
     */
    val columnCount: Int
        get() {
            return buttons.columnCount
        }

    /**
     * game board
     */
    var board = board 

    /**
     * display
     */
    var display = Display(renderingCtx, buttons, board, pointLightEdit)

    /**
     * instance to edit point light 
     */
    var pointLightEdit = pointLightEdit 

    /**
     * instance for managing shadow mapping
     */
    var shadowMap = shadowMap
      

    /**
     * bloom effect
     */
    var bloom = bloom

    /**
     * the editor for point light
     */
    val pointLightSetting: PointLightSetting
        get() {
            val result = PointLightSetting(pointLightSettingOption)
            result.grid = this
            return result
        }  
    /**
     * is editing point light
     */
    val isEditingPointLight: Boolean
        get() {
            return pointLightSetting.isEditing
        }

    /**
     * color scheme
     */
    var colorScheme: ColorScheme = colorScheme
        get() {
            return field 
        }
        set(value) {
            if (value != field) {
                field = value 
                postSyncWithColorScheme() 
            }
        }

    /**
     * color scheme container
     */
    val colorSchemeContainer: ColorSchemeContainer
        get() {
            val grid = this
            return object : ColorSchemeContainer {
                override var colorScheme: ColorScheme
                    get() {
                        return grid.colorScheme
                    } 
                    set(value) {
                        grid.colorScheme = value 
                    }
            }
        }

    /**
     * effector
     */
    var effector: Effector = effector

    /**
     * total button size
     */
    val totalButtonSize : FloatArray
        get() {
            return buttons.totalButtonSize
        }
    /**
     * total gap size
     */
    val totalButtonsGapSize : FloatArray
        get() {
            return buttons.totalGapSize
        }
    /**
     * gap for drawing
     */
    val gapForDrawing : FloatArray
        get() {
            val buttonsTotalGap = buttons.gapForDrawing
            return buttonsTotalGap
        } 
    /**
     * button z gap for drawing
     */ 
    val buttonZGapForDrawing : FloatArray
        get() {
           return buttons.zGapForDrawing
        }

    /**
     * the button to hide a mine.
     */
    val mineButton: MineButton
        get() {
            return buttons.mineButton
        }

    /**
     * border size
     */
    val borderSize : FloatArray
        get() {
            return this.totalButtonSize.mapIndexed({
                i, value -> boardEdge[i] * value }).toFloatArray()
       } 
    /**
     * board edge size
     */
    val boardEdgeSize : FloatArray
        get() {
            return totalButtonSize.mapIndexed({
                i, value -> boardEdge[i] * value }).toFloatArray()
        }
    /**
     * board size
     */
    val boardSize : FloatArray
        get() {
            val totalButtonSize = this.totalButtonSize
            val totalGapSize = this.totalButtonsGapSize
            val borderSize = this.borderSize
            val boardEdgeSize = this.boardEdgeSize 
            
            return totalButtonSize.mapIndexed({ index, size -> 
                var boardSize = size
                boardSize += totalGapSize[index]
                boardSize += borderSize[index]
                boardSize += boardEdgeSize[index]
                boardSize
            }).toFloatArray()
        }
    /**
     * environment
     */
    var environment: Environment? = null
    /**
     * web gl rendering context
     */
    var canvasId : String? = null

    /**
     * canvas element
     */
    val canvas: HTMLElement?
        get() {
            return canvasId?.let {
                document.querySelector(it) as HTMLElement?
            }
        }
    /**
     * rendering context
     */
    val renderingContext: WebGLRenderingContext?
        get() {
            return canvas?.let {
                val canvas = it as HTMLCanvasElement 
                canvas.getContext("webgl") as WebGLRenderingContext
            }
        }
    /**
     * image capture manager
     */
    val imageCapture: ImageCapture = ImageCapture()
 
    /**
     * game over modal dialog
     */
    var gameOverModalId : String? = null

    /**
     * game over modal dialog
     */
    val gameOverModalElement: HTMLElement?
        get() {
            return gameOverModalId?.let {
                document.querySelector(it) as HTMLElement?
            }
        }

    /**
     * player won the game daialog
     */
    var playerWonModalId : String? = null

   
    /**
     * the player won modal html element
     */
    val playerWonModalElement: HTMLElement? 
        get() {
            return playerWonModalId?.let {
                document.querySelector(it) as HTMLElement?
            }
        }

    /**
     * menu setting item query for html dom node
     */
    var mainMenuItemQuery : String? = null

    /**
     * query back to main ui html node
     */
    var backToMainQuery: String? = null


    /**
     * event handler
     */
    var onClickHandler : ((Event) -> Unit) ? = null

    /**
     * touch event handler
     */
    var onTouchHandler : ((TouchEvent) -> Unit) ? = null

    /**
     * game over modal hidden handler
     */
    var onHiddenGameOverModalHandler: ((Event) -> Unit)? = null

    /**
     * game over modal hidden handler
     */
    var onHiddenPlayerWonModalHandler: ((Event) -> Unit)? = null
 

    /**
     * handler to play again the game
     */
    var onClickToPlayAgainHandler: ((Event)->Unit)? = null

    /**
     * handler to play a new game
     */
    var onClickToPlayNewHandler: ((Event)->Unit)? = null



    /**
     * hadler to respond window resize
     */
    var onResizedHdlr: ((event: Event) -> Unit)? = null

    
    /**
     * next game operation
     */
    var nextGameOperation: (() -> Unit)? = null

    /**
     * icon changed handler
     */
    var onMineIconChanged: ((Any?, String)->Unit)? = null

    /**
     * set up shadow dummy
     */
    val setupShadow0: ((Grid, WebGLRenderingContext)->Unit) = {
            _, _->
        }

    /**
     * set up shadow
     */
    val setupShadow1: ((Grid, WebGLRenderingContext)->Unit) = {
        grid, gl ->
        grid.shadowMap.setup(grid, gl)
        grid.setupShadow = grid.setupShadow0 
    }

    /**
     * setting up shadow environemnt
     */ 
    var setupShadow: ((Grid, WebGLRenderingContext)->Unit) = setupShadow0


    /**
     * icon setting. you can access this setting while ui is bound.
     */
    var iconSetting: IconSetting? = null 

    /**
     * back color for drawing
     */
    val backColorForDrawing = floatArrayOf(0f, 0f, 0f, 1f)
 
    /**
     * back color for picking
     */
    val backColorForPicking = floatArrayOf(0f, 0f, 0f, 0f)

    /**
     * back ground color
     */
    var backColor = backColorForDrawing 
    
   
    /**
     * enable shadow depth rendering
     */
    var shadowDepthEnabled = true


    /**
     * create animator to open buttons
     */
    var createOpeningButtonAnimator: 
        ((CellIndex, Set<CellIndex>)->((Grid, WebGLRenderingContext)->Unit))= {
            _, _ ->
            val runner: (Grid, WebGLRenderingContext)-> Unit = {
                _, _ ->
            }
            runner
        }
    /**
     * procedure to start openging button
     */
    var startOpeningButtonProc: (Grid, WebGLRenderingContext)->Unit = {
        _, _ ->
    } 

    /**
     * initialize gl context
     */
    fun setupEnv(gl :WebGLRenderingContext) {
        setupEnv(gl, backColor)
    }
 
    /**
     * initialize gl context
     */
    fun setupEnv(gl :WebGLRenderingContext,
        backColor: FloatArray) {
        gl.clearColor(backColor[0], backColor[1], backColor[2], backColor[3])

        gl.enable(WebGLRenderingContext.DEPTH_TEST)
        gl.enable(WebGLRenderingContext.BLEND)
        gl.blendFunc(WebGLRenderingContext.SRC_ALPHA,
            WebGLRenderingContext.ONE_MINUS_SRC_ALPHA)
        gl.frontFace(WebGLRenderingContext.CW)
        gl.depthFunc(WebGLRenderingContext.LEQUAL)
        gl.clear(WebGLRenderingContext.COLOR_BUFFER_BIT or
            WebGLRenderingContext.DEPTH_BUFFER_BIT)
    }
    /**
     * draw scene
     */
    private fun updateScreen() {
        val gl = this.renderingContext
        if (gl != null) {
            updateScreen(gl) 
        }
    }

    /**
     * draw scen lately.
     */
    private fun postUpdateScreen() {
        val gl = this.renderingContext
        if (gl != null) {
            postUpdateScreen(gl)
        }
    }
      
    /**
     * draw scene lately.
     */
    internal fun postUpdateScreen(gl: WebGLRenderingContext) {
        window.setTimeout({ updateScreen(gl) })
    }

    /**
     * update screen
     */
    private fun updateScreen(gl: WebGLRenderingContext) {
        syncViewportWithSceneSize(gl)
        drawScene(gl)
        bloom.draw(this, gl)
        syncViewportWithScreenSize(gl)
        postRendering(gl)
        imageCapture.caputure(this)
    }


    /**
     * start game
     */
    fun startGame(gl: WebGLRenderingContext) {
        val model = this.model
        if (model != null) {
            model.logic.start()
        }
        setBlinkFinishAndStartOpening()
        updateScreen(gl)
        startInitialAnimation(gl)
    }

    /**
     * reset game and play again with same setting.
     */
    fun resetGame(gl: WebGLRenderingContext) {
        val model = this.model
        if (model != null) {
            val status = model.logic.status
            if (status != null) {
                status.clearAll()
            }
        }
        setBlinkFinishAndStartOpening()
        updateScreen()
        startInitialAnimation(gl)
    }


    /**
     * copy scene contents to screen with effect
     */
    private fun postRendering(gl: WebGLRenderingContext) {

        screen.draw(this, gl)    
    }

    /**
     * draw scene
     */
    private fun drawScene(gl: WebGLRenderingContext) {
        locReader.draw(this, gl)
        setupShadow(this, gl)   
        shadowMap.drawScene(this, gl)
        scene.draw(this, gl)
    }

    /**
     * invalidate shadow setting
     */
    fun invalidateShadowSetting() {
        setupShadow = setupShadow1
    }

   /**
     * setup 3d rendering environment
     */ 
    fun setup(gl: WebGLRenderingContext)  {
        setupShaderProgram(gl)
        setupCamera(gl)
        setupLocReader(gl)
        setupTextures(gl)
        setupMatrices()
        pointLightEdit.setupLightUiPlane(this)
        pointLightEdit.setupBuffer(gl, renderingCtx) 
        mineButton.setup(this, gl)
        board.setup(this, gl)
        scene.setup(this, gl)
        shadowMap.setup(this, gl)
        bloom.setup(this, gl)
        screen.setup(this, gl)
    }
    fun teardown(gl: WebGLRenderingContext) {
        renderingCtx.tearDown(gl)
    }
    /**
     * set up camera
     */
    fun setupCamera(gl: WebGLRenderingContext) {
        detachCameraListener()
        syncCameraAspectWithDrawingBufferSize(gl)
        attachCameraListener() 
    }

    /**
     * synchronize camera aspect with rendering context
     */
    fun syncCameraAspectWithScene(gl: WebGLRenderingContext) {
        val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
        
        var aspect = framebufferSize[0].toFloat()
        aspect /= framebufferSize[1].toFloat()
        this.sceneCamera?.aspect = aspect
     }

    /**
     * synchronize camera aspect with canvas size
     */
    fun syncCameraAspectWithCanvas(gl: WebGLRenderingContext) {
        val canvasSize = getScreenSize(gl)
        
        var aspect = canvasSize[0].toFloat()
        aspect /= canvasSize[1].toFloat()
        this.sceneCamera?.aspect = aspect
    }

    /**
     * synchronize camera aspect with drawing buffer size
     */
    fun syncCameraAspectWithDrawingBufferSize(gl: WebGLRenderingContext) {
        val bufferSize = getDrawingBufferSize(gl)
        
        var aspect = bufferSize[0].toFloat()
        aspect /= bufferSize[1].toFloat()
        this.sceneCamera?.aspect = aspect
    }



    /**
     * set up frame buffer for picking
     */
    fun setupLocReader(gl: WebGLRenderingContext) {
        locReader.setup(this, gl)
    }


    /**
     * setup textrues
     */
    fun setupTextures(gl: WebGLRenderingContext) {
        renderingCtx.buttonTexture = gl.createTexture()
        textures.setup(gl, glyph)
    }

    /**
     * setup shader programs
     */
    fun setupShaderProgram(gl: WebGLRenderingContext) {
        val vertexShaders = createVertexShaders(gl)
        var fragmentShaders = createFragmentShaders(gl)
        this.renderingCtx.teardownShaderProgram(gl)
        this.renderingCtx.shaderPrograms = Array<WebGLProgram?>(
            minOf(vertexShaders.size, fragmentShaders.size)) {
            val vertexShader = vertexShaders[it]
            val fragmentShader = fragmentShaders[it] 
            var shaderProg: WebGLProgram? = null
            if (vertexShader != null 
                && fragmentShader != null) { 
                shaderProg = gl.createProgram()  
                if (shaderProg != null) {
                    gl.attachShader(shaderProg, vertexShader)
                    gl.attachShader(shaderProg, fragmentShader)
                    gl.linkProgram(shaderProg)
                    assertLinkError(gl, shaderProg)
                }
            }
            shaderProg
        }
    
    }
    /**
     * on clik event
     */ 
    fun onClick(event: Event) {
        if (event is MouseEvent) {
            val mouseEvent = event 
            val canvas = document.querySelector(
                canvasId!!) as HTMLCanvasElement
            val y = (canvas.height
                - mouseEvent.offsetY)   
            val x = mouseEvent.offsetX
            postHandleUserInput(x, y)
        }
    }


    /**
     * on touch event
     */
    fun onTouch(event: TouchEvent) {
        console.log(event.type) 
    }

    /**
     * handle user input
     */ 
    fun postHandleUserInput(x : Double, y: Double) {
        window.setTimeout({
           handleUserInput(x, y)  
        }, 100)
    }
    /**
     * handle user input
     */
    fun handleUserInput(x : Double, y: Double) {
        val gl = renderingContext
        if (gl != null) {
            if (!isEditingPointLight) {
                handleUserInput(gl, round(x).toInt(), round(y).toInt())
            } else {
                handleUserInputForLightEdit(
                    gl, round(x).toInt(), round(y).toInt())
            }
        }
        
    } 
    /**
     * handle the user input event
     */
    fun handleUserInput(gl: WebGLRenderingContext,
        x: Int, y: Int) {
        val model = this.model!!
        val location = findCell(gl, x, y)
        if (location != null) {
            if (!model.logic.isOpened(location[0], location[1])) { 
                if (!model.logic.status!!.inAnimating
                    && !model.logic.isOver) {
                    handleMineCell(gl, location)
                    // update session
                    Activity.record()
                } 
            }
        }
    }


    /**
     * handle main cell
     */
    fun handleMineCell(
        gl: WebGLRenderingContext,
        location: IntArray) {
        val flag = this.flag!!
        val flagging = flag.isFlagging
        if (flagging != null && flagging) {
            toggleFlag(gl, location) 
        } else {
            openMine(gl, location)
        }
    }

    /**
     * toggle flag
     */
    fun toggleFlag(
        gl: WebGLRenderingContext, 
        location: IntArray) {

        val model = this.model!! 
        val succeded = model.logic.toggleLock(location[0], location[1])
        if (succeded) {
            postUpdateScreen(gl)
        }
    }


    /**
     * find button cell from x,y coordinate on display
     */
    fun findCell(gl: WebGLRenderingContext,
        x: Int, y: Int): IntArray? {
        val result = locReader.findCell(this, gl, x, y)
        return result
    } 


    /**
     * open mine buttons
     */
    private fun openMine(
        gl: WebGLRenderingContext,
        location: IntArray) {
        val model = this.model!!
        val cell = CellIndex(location[0], location[1])
        val cellsAndLocking = model.logic.getOpenableCells(
            cell.row, cell.column)
        if (cellsAndLocking.second == false) {
            var cells: Set<CellIndex> = cellsAndLocking.first
            if (cells.size == 0) {
                cells = model.logic.mineLocations
            }
            if (cells.size > 0) { 
                startOpeningButtonProc = createOpeningButtonAnimator(
                    cell, cells) 
                startOpeningButtonProc(this, gl)
            }
        }
    }
    


    /**
     * handle user input for editing point-light
     */
    fun handleUserInputForLightEdit(wgl: WebGLRenderingContext,
        x: Int, y: Int) {
        pointLightEdit.handleUserInput(this, wgl, x, y)
    }

    /**
     * set procedure for opening buttons with blinking finish and opening.
     */
    fun setBlinkFinishAndStartOpening() {

        var handler:
            (CellIndex, Set<CellIndex>?)
                ->((Grid, WebGLRenderingContext)->Unit) = {
            cell, cells ->
            val runner: (Grid, WebGLRenderingContext)->Unit = {
                grid, gl ->
                val hintButtonBlinking = grid.renderingCtx.hintButtonBlinking  
                if (hintButtonBlinking != null) {
                    if (hintButtonBlinking.active) {
                        hintButtonBlinking.stopAtDark()
                        grid.startOpeningButtonsAnimation(gl, cell, cells)
                        grid.setStartOpening() 
                    } else {
                        grid.startOpeningButtonsAnimation(gl, cell, cells)
                        grid.setStartOpening() 
                    } 
                } else {
                    grid.startOpeningButtonsAnimation(gl, cell, cells)
                    grid.setStartOpening()
                }
            }                         
            runner       
        }
        createOpeningButtonAnimator = handler
    }


    /**
     * set start opening procedure with open buttons simply
     */
    fun setStartOpening() {
        var handler:
            (CellIndex, Set<CellIndex>?)
                ->((Grid, WebGLRenderingContext)->Unit) = {
            cell, cells ->
            val runner: (Grid, WebGLRenderingContext)->Unit = {
                grid, gl  ->
                grid.startOpeningButtonsAnimation(gl, cell, cells)
            }
            runner
        }
        createOpeningButtonAnimator = handler
    }

    /**
     * start animation opening buttons
     */
    fun startOpeningButtonsAnimation(gl: WebGLRenderingContext,
        cell: CellIndex,
        cells: Set<CellIndex>?) {
        startAnimation(cell, cells)
        if (cells != null) { 
            val buttonIndices = Array<IntArray>(cells.size) {
                cells.elementAt(it).toIntArray()
            }
            val model = this.model 
            if (model != null) {
                animation.setupButtons(buttons, 
                    model.logic.status!!.getOpenedIndices(),
                    buttonIndices, renderingCtx)
                postStartAnimation(gl, { finishAnimation(cell, cells) })
            }
        }

    } 
    /**
     * start animation
     */
    fun startAnimation(
        cell: CellIndex,
        cells: Set<CellIndex>?) {
        val model = this.model!!
        model.logic.status!!.userInputingButton = cell 
        model.logic.status!!.inAnimating = true
        model.logic.status!!.openingButtons = cells
    }
    /**
     * finish animation
     */
    @Suppress("UNUSED_PARAMETER")
    fun finishAnimation(
        cell: CellIndex,
        cells: Set<CellIndex>?) {
        val model = this.model!!
        model.logic.status!!.userInputingButton = null
        model.logic.status!!.openingButtons = null
        model.logic.status!!.inAnimating = false 

    
        if (cells != null) {
            cells.forEach({ 
                model.logic.registerOpened(it.row, it.column)
            })
        }
        if (model.logic.isOver) {
            if (model.logic.gamingStatus == GamingStatus.LOST) {
                postDisplayGameOverModal()    
            } else {
                postDisplayPlayerWonModal()    
            }
        }
     }
    
    @Suppress("UNUSED_PARAMETER")
    fun tapButton(gl: WebGLRenderingContext,
        rowIndex: Int, colIndex: Int) {
        
    } 
    
    /**
     * connect a canvas and create grid
     */
    fun bind( 
        settings: GridSettings,
        model: Model,
        sceneCamera: Camera,
        pointLight: net.oc_soft.mswp.PointLight,
        flag: Flag,
        colorScheme: ColorScheme,
        colorMaterial: ColorMaterial,
        environment: Environment,
        bloom: Bloom,
        effector: Effector,
        shaderPrograms: ShaderPrograms,
        appSettings: AppSettings) {
        
        model.physicsEng.gravity = PhysicsEng.calcGravity(1.2f,
            animation.openingButtonDuration)

        model.physicsEng.fps = animation.framePerSec.toFloat()

        model.logic.rowSize = rowCount
        model.logic.columnSize = columnCount

        this.model = model
        this.buttons.logic = model.logic
        this.buttons.textures = this.textures
        this.mineButton.colorMaterialConvert = 
            colorMaterial.mineButtonConvert
        this.board.textures = this.textures
        this.board.colorMaterialConvert =
            colorMaterial.boardConvert
        this.sceneCamera = sceneCamera
        this.pointLight = pointLight
        this.flag = flag
        this.colorScheme = colorScheme

        this.mineButton.updateColorScheme(colorScheme)
        this.board.updateColorScheme(colorScheme)
        this.effector = effector
        this.shaderPrograms = shaderPrograms
        bloom.countOfGradation = animation.calcBlinkingGradationCount(
            0.5f)
        this.bloom = bloom
         
        this.bloom.syncColorSetting(this) 


        this.canvasId = settings.canvasId 
        this.gameOverModalId = settings.gameOverModalId
        this.playerWonModalId = settings.playerWonModalId
        this.backToMainQuery = settings.backToMainQuery
        this.mainMenuItemQuery = settings.mainMenuItemQuery
        this.environment = environment
        this.appSettings = appSettings
        this.onMineIconChanged = {
            sender, msg ->
            handleIconChanged(sender, msg)
        }
        
        this.iconSetting = settings.iconSetting
        this.iconSetting?.addListener(this.onMineIconChanged!!)

        onClickHandler = { event -> this.onClick(event) }
        onTouchHandler = { event -> this.onTouch(event) }

        onClickToPlayAgainHandler = {
            this.handleClickToPlayAgain(it)
            Activity.record()
        }
        onClickToPlayNewHandler = {
            this.handleClickToPlayNew(it)
            Activity.record()
        }
        onResizedHdlr = {
            onResized(it)
        }
        @Suppress("UNCHECKED_CAST")
        val touchEventHandler = this.onTouchHandler as ((Event)->Unit)

        setupGameOverModal()
        setupPlayerWonModal()
        val canvas = this.canvas
        syncCanvasWithClientSize() 
        window.addEventListener("resize", onResizedHdlr!!)
        canvas?.addEventListener("click", onClickHandler)
        canvas?.addEventListener("touchstart", touchEventHandler)
        canvas?.addEventListener("touchend", touchEventHandler)
        canvas?.addEventListener("touchcancel", touchEventHandler)
        canvas?.addEventListener("touchmove", touchEventHandler)

        glyph.bind(settings.glyphCanvasId, settings.iconSetting)
        renderingContext?.let {
            setup(it)
            environment.syncWithColorScheme()
            imageCapture.capturing = true
            startGame(it)
        }
    }


    /**
     * disconnect creaged grid from node.
     */
    fun unbind() {
        this.imageCapture.capturing = false
        this.environment = null
        this.iconSetting?.removeListener(this.onMineIconChanged!!)
        this.onMineIconChanged = null
        this.iconSetting = null

        teardownPlayerWonModal()
        teardownGameOverModal() 

        @Suppress("UNCHECKED_CAST")
        val touchEventHandler = this.onTouchHandler as ((Event)->Unit)

        canvas?.let {
            it.removeEventListener("touchstart", touchEventHandler)
            it.removeEventListener("touchend", touchEventHandler)
            it.removeEventListener("touchcancel", touchEventHandler)
            it.removeEventListener("touchmove", touchEventHandler)
            it.removeEventListener("click", onClickHandler) 
        }
        window.removeEventListener("resize", onResizedHdlr!!)
        onResizedHdlr = null
        onClickToPlayAgainHandler = null
        onClickToPlayNewHandler = null
        this.onTouchHandler = null
        this.onClickHandler = null
        this.appSettings = null
    }



    /**
     * capture current screen
     */
    fun captureScene():Promise<Blob?> {
        val result = Promise<Blob?> {
            resolve, _ ->
            val canvas = this.canvas as HTMLCanvasElement?  
            if (canvas != null) {
                canvas.toBlob({
                    resolve(it)
                })
            } else {
                resolve(null)
            }
        }
        return result
    }

    /**
     * response icon setting changed event.
     */
    @Suppress("UNUSED_PARAMETER")
    fun handleIconChanged(sender: Any?, msg: String) {
        
        when (msg) { 
            IconSetting.NG_ICON,
            IconSetting.OK_ICON,
            IconSetting.FLAG_ICON -> 
                syncIconImageWithSettings()
        }
    }
    /**
     * synchronize icon image with settings.
     */
    fun syncIconImageWithSettings() {
        val iconSetting = this.iconSetting
        if (iconSetting != null) {
            glyph.updateImages(iconSetting)
            var gl = renderingContext
            if (gl != null) {
                textures.updateOkTexture(gl, glyph)
                textures.updateNgTexture(gl, glyph) 
                textures.updateNumberFlagTexture(gl, glyph)
                textures.updateOkFlagTexture(gl, glyph)
                textures.updateNgFlagTexture(gl, glyph)
                textures.updateNumberFlagTexture(gl, glyph)
                postUpdateScreen(gl)
            }
        }
    }
    
    /**
     * synchronize this with color scheme lately
     */
    fun postSyncWithColorScheme() {
        window.setTimeout({
            syncWithColorScheme() 
        })
    }

    /**
     * synchronize this with color scheme
     */
    fun syncWithColorScheme() {
        buttons.updateColorScheme(colorScheme)
        glyph.updateColorScheme(colorScheme)
        board.updateColorScheme(colorScheme)
        updateColorScheme(colorScheme) 
        bloom.syncColorSetting(this) 

        val iconSetting = this.iconSetting
        if (iconSetting != null) {
            glyph.updateImages(iconSetting)
            val gl = renderingContext
            if (gl != null) {
                board.updateMaterial(this, gl)
                mineButton.updateMaterial(this, gl)
                textures.updateNumberBlankTexture(gl, glyph)
                textures.updateOkTexture(gl, glyph)
                textures.updateNgTexture(gl, glyph) 
                textures.updateOkFlagTexture(gl, glyph)
                textures.updateNgFlagTexture(gl, glyph)
                textures.updateNumberFlagTexture(gl, glyph)
                textures.updatePointLightMarkerTexture(gl, glyph)
                animation.updateButtonsGlowingBufferWithCurrentFrame(
                    gl, renderingCtx)
                postUpdateScreen(gl)
            }
        }
    }

    /**
     * update color scheme
     */
    fun updateColorScheme(colorScheme: ColorScheme) {
        environment?.colorScheme = colorScheme
        val background = colorScheme.getEnvironment(ColorScheme.Background)
        if (background != null) {
            for (i in 0 until min(backColorForDrawing.size, background.size)) {
                backColorForDrawing[i] = background[i]
            }
        }
    }

    /**
     * setup game over modal
     */
    private fun setupGameOverModal() {
        val modalHdlr: (Event)->Unit = { 
            this.handleHiddenGameOverModal(it)
        }
                
        gameOverModalElement?.let {
            val elem = it 
            onClickToPlayAgainHandler?.let {
                val hdlr = it  
                elem.querySelector(".play-again")?.let {
                    val elem0 = it as HTMLElement
                    elem0.addEventListener("click", hdlr)
                }
            }
            onClickToPlayNewHandler?.let {
                val hdlr = it
                elem.querySelector(".play-new")?.let {
                    val elem0 = it as HTMLElement
                    elem0.addEventListener("click", hdlr)
                }
            } 
            bootstrap.Modal(it)
            it.addEventListener("hidden.bs.modal",
                modalHdlr)

        }
        onHiddenGameOverModalHandler = modalHdlr
    }
    /**
     * tear down game over modal
     */
    private fun teardownGameOverModal() {
        onHiddenGameOverModalHandler?.let {
            var hdlr = it
            gameOverModalElement?.let {
                
                it.removeEventListener("hidden.bs.modal", hdlr)

                it.querySelector(".play-again")?.let {
                    val elem = it as HTMLElement
                    onClickToPlayAgainHandler?.let {
                        elem.removeEventListener("click", it)
                    }
                }
                it.querySelector(".play-new")?.let {
                    val elem = it as HTMLElement
                    onClickToPlayNewHandler?.let {
                        elem.removeEventListener("click", it)
                    }
                }
                bootstrap.Modal.getInstance(it)?.let {
                    it.dispose()
                }
            }
        }
        onHiddenGameOverModalHandler = null
    }

    /**
     * setup player won the game modal
     */
    private fun setupPlayerWonModal() {
        val modalHdlr: (Event)->Unit = { 
            this.handleHiddenPlayerWonModal(it)
        }

        playerWonModalElement?.let {

            val elem = it 
            onClickToPlayAgainHandler?.let {
                val hdlr = it
                elem.querySelector(".play-again")?.let {
                    val elem0 = it as HTMLElement
                    elem0.addEventListener("click", hdlr)
                }
            }
            onClickToPlayNewHandler?.let {
                val hdlr = it
                elem.querySelector(".play-new")?.let {
                    val elem0 = it as HTMLElement
                    elem0.addEventListener("click", hdlr)
                }
            }
            bootstrap.Modal(it)



            it.addEventListener("hidden.bs.modal",
                modalHdlr)
        }
        onHiddenPlayerWonModalHandler = modalHdlr
    }

    /**
     * tear down player won modal
     */
    private fun teardownPlayerWonModal() {
        onHiddenPlayerWonModalHandler?.let {
            var hdlr = it
            playerWonModalElement?.let {
                it.removeEventListener("hidden.bs.modal", hdlr)

                it.querySelector(".play-again")?.let {
                    val elem = it as HTMLElement
                    onClickToPlayAgainHandler?.let {
                        elem.removeEventListener("click", it)
                    }
                }
                it.querySelector(".play-new")?.let {
                    val elem = it as HTMLElement
                    onClickToPlayNewHandler?.let {
                        elem.removeEventListener("click", it)
                    }
                }
            }
        }
        onHiddenPlayerWonModalHandler = null
        

    }

    private fun postStartAnimation(gl: WebGLRenderingContext,
        animationFinishedListener: (()->Unit)) {
        window.setTimeout({ 
            startAnimation(gl, animationFinishedListener) 
        }, 100)
    }

    /**
     * start animation
     */
    private fun startAnimation(gl: WebGLRenderingContext,
        animationFinishedListener: ()->Unit) {

        var lastUpdate = 0.0
        @Suppress("UNUSED_PARAMETER")
        fun render(now : Double): Unit {
            if (now - lastUpdate > animation.frameDuration) {
                animation.doAnimate(gl, renderingCtx)
                updateScreen(gl)
                lastUpdate = now
            }
 
            if (animation.hasNextFrame(renderingCtx)) {
                animation.openingButtonsId =
                    window.requestAnimationFrame { render(it) } 
            } else {
                animation.openingButtonsId = null
                animationFinishedListener()
            }
        } 
        val buttonsBlinkingId = animation.buttonsBlinkingId
        if (buttonsBlinkingId != null) {
            window.cancelAnimationFrame(buttonsBlinkingId)
            animation.buttonsBlinkingId = null
        }
        animation.openingButtonsId =
            window.requestAnimationFrame { render(it) }
    }


    /**
     * start initial game animation
     */
    private fun startInitialAnimation(
        gl: WebGLRenderingContext,
        animationFinished:((WebGLRenderingContext)->Unit)? = null) {
        animation.startButtonsBlinking(renderingCtx)

        var lastUpdate = 0.0
        fun render(now: Double): Unit {
            if (now - lastUpdate > animation.frameDuration) {
                animation.doButtonsBlinking(gl, renderingCtx) 
                updateScreen(gl)
                lastUpdate = now
            }
            if (animation.isButtonsBlinking(renderingCtx)
                && !animation.isOpeningButtons(renderingCtx)) {
                animation.buttonsBlinkingId =
                    window.requestAnimationFrame { render(it) } 
            } else {
                animation.buttonsBlinkingId = null
                if (animationFinished != null) {
                    animationFinished(gl)
                }
            }
        }

        animation.buttonsBlinkingId = 
            window.requestAnimationFrame { render(it) }
    }
         

    /**
     * update material buffer
     */
    private fun updateMaterialBuffer(gl: WebGLRenderingContext) {
        board.updateMaterial(this, gl)
        mineButton.updateMaterial(this, gl)
    }


    /**
     * setup render buffer for scene
     */
    @Suppress("UNUSED_PARAMETER")
    private fun setupRenderbufferForScene(gl: WebGLRenderingContext) {
        
    }


    /**
     * setup game element matrix
     */
    private fun setupMatrices() {
        renderingCtx.buttonMatrices = createButtonMatrices() 
        renderingCtx.buttonNormalVecMatrices = createButtonNormalVecMatrices()
        renderingCtx.buttonMatricesForDrawing = renderingCtx.buttonMatrices 
        renderingCtx.buttonNormalVecMatricesForDrawing =
            renderingCtx.buttonNormalVecMatrices

        renderingCtx.boardMatrix = createBoardMatrix() 
        renderingCtx.boardNormalVecMatrix = createBoardNormalVecMatrix()
        renderingCtx.spinVMotionMatricesIndex = createSpinAndVMotionMatrices()
        renderingCtx.spinMotionMatrices = createSpinMatrices()
    }

    /**
     * button index buffer
     */
    private fun createButtonNormalVecBuffer(
        gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, result)
        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            mineButton.normalVectorsAsFloat32,
            WebGLRenderingContext.STATIC_DRAW)
        return result
    }
    /**
     * button texture coordinate buffer
     */
    private fun createButtonTextureCoordinateBuffer(
        gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, result)
        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            mineButton.textureCoordinatesAsFloat32,
            WebGLRenderingContext.STATIC_DRAW)
        return result
    }

    /**
     * synchronize location reader with scene size
     */
    fun syncLocReaderSizeWithScene(
        gl: WebGLRenderingContext) {
        locReader.syncSizeWithScene(this, gl) 
    }
      
   
    /**
     * update camera
     */
    private fun updateCamera(gl: WebGLRenderingContext) {
        attachCameraToProjectionMatrix(gl)
    }

    /**
     * attach camera matrix into projection matrix in shader program
     */
    internal fun attachCameraToProjectionMatrix(
        gl: WebGLRenderingContext) {
        val camMatrix = createCameraMatrix()
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val uProjMat = gl.getUniformLocation(shaderProg, 
                "uProjectionMatrix")    
            if (camMatrix != null) { 
                gl.uniformMatrix4fv(uProjMat, false,
                    camMatrix)
            }
        }
    }

    fun createCameraMatrix(): Float32Array? {
        val cam = sceneCamera
        val glrs = this.glrs
        var result: Float32Array? = null
        if (cam != null && glrs != null) {
            val camCenter = cam.center
            val camEye = cam.eye
            val camUp = cam.up

            val projMat = glrs.matrix_new_perspective(
                cam.fieldOfView, cam.aspect, cam.zNear, cam.zFar)

            glrs.matrix_look_at_mut(projMat,
                camEye[0], camEye[1], camEye[2],
                camCenter[0], camCenter[1], camCenter[2],
                camUp[0], camUp[1], camUp[2])

            result = glrs.matrix_get_components_col_order_32(projMat)

            glrs.matrix_release(projMat)
        }
        return result
    }

    /**
     * enable lighting related Attribute
     */
    private fun enableLightingAttrib(gl: WebGLRenderingContext) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val lightPosLoc = gl.getUniformLocation(shaderProg,
                "uLightPosition")
            
            gl.uniform3f(lightPosLoc as WebGLUniformLocation, 
                pointLight!!.point[0], 
                pointLight!!.point[1],
                pointLight!!.point[2])
        }
    } 

    /**
     * enable lighting
     */
    private fun enableLightingContext(
        gl: WebGLRenderingContext,
        enabled: Boolean) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val enableLoc = gl.getUniformLocation(shaderProg,
                "uEnableLighting")
            if (enableLoc != null) {
                fun Boolean.toInt() = if (this) 1 else 0 
                gl.uniform1i(enableLoc, enabled.toInt());
            }
        }
    } 

    /**
     * enable shadow depth rendering
     */
    private fun enableShadowDepth(
        gl: WebGLRenderingContext,
        enable: Boolean) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val enableLoc = gl.getUniformLocation(shaderProg,
                "uEnableShadowDepth")
            fun Boolean.toInt() = if (this) 1 else 0 
            gl.uniform1i(enableLoc as WebGLUniformLocation, 
                enable.toInt());
        }
     }

    /**
     * enable camera related Attribute
     */
    private fun enableCameraAttrib(gl: WebGLRenderingContext) {
        val cam = this.sceneCamera
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null && cam != null) {
            val eyeLoc = gl.getUniformLocation(shaderProg,
                "uEyePosition")
            
            gl.uniform3f(eyeLoc as WebGLUniformLocation, 
                cam.eye[0], 
                cam.eye[1],
                cam.eye[2])
        }
    } 
     private fun assertLinkError(
        gl: WebGLRenderingContext,
        prog: WebGLProgram): WebGLProgram? {
        var result: WebGLProgram? = null
        if (gl.getProgramParameter(prog,
            WebGLRenderingContext.LINK_STATUS)!! as Boolean) {
            result = prog
        }
        return result 
    } 
    private fun createVertexShaders(
        gl: WebGLRenderingContext): Array<WebGLShader?> {
        val shaderProg = this.shaderPrograms!!
        val strProg = arrayOf(
            shaderProg.vertexShader,
            shaderProg.locReaderVertexShader,
            shaderProg.pointVertexShader,
            shaderProg.depthVertexShader,
            shaderProg.screenVertexShader,
            shaderProg.glowVertexShader,
            shaderProg.blurVertexShader) 
        val result = Array<WebGLShader?>(strProg.size) {
            createVertexShader(gl, strProg[it])
        }
        return result 
    }
    private fun createFragmentShaders(
        gl: WebGLRenderingContext): Array<WebGLShader?> {
        val shaderProg = this.shaderPrograms!!
        val strProg = arrayOf(
            shaderProg.fragmentShader,
            shaderProg.locReaderFragmentShader,
            shaderProg.pointFragmentShader,
            shaderProg.depthFragmentShader,
            shaderProg.screenFragmentShader,
            shaderProg.glowFragmentShader,
            shaderProg.blurFragmentShader) 
        val result = Array<WebGLShader?>(strProg.size) {
            createFragmentShader(gl, strProg[it])
        }
        return result 
    }
    /**
     * compile vertex shader program
     */
    private fun createVertexShader( 
        gl: WebGLRenderingContext,
        shaderProgram: String): WebGLShader? {
        var result : WebGLShader? = null
        val shader = gl.createShader(WebGLRenderingContext.VERTEX_SHADER)
        if (shader != null) {
            gl.shaderSource(shader, shaderProgram)
            gl.compileShader(shader)
            result = assertCompileError(gl, shader)
        }
        return result
    } 
    /**
     * compile fragment shader program
     */
    private fun createFragmentShader(
        gl: WebGLRenderingContext,
        shaderProgram: String): WebGLShader? {
        var result : WebGLShader? = null
        val shader = gl.createShader(WebGLRenderingContext.FRAGMENT_SHADER)
        if (shader != null) {
            gl.shaderSource(shader, shaderProgram)
            gl.compileShader(shader)
            result = assertCompileError(gl, shader)
        }
        return result
    } 
    private fun assertCompileError(
        gl: WebGLRenderingContext,
        shader: WebGLShader): WebGLShader? {
        var result : WebGLShader? = null 
        if (gl.getShaderParameter(shader,
            WebGLRenderingContext.COMPILE_STATUS)!! as Boolean) {
            result = shader 
        } else {
            println(gl.getShaderInfoLog(shader))
        }
        return result
    }
   
    /**
     * create buttons matrices
     */
    private fun createButtonMatrices(): Array<FloatArray> {
        val boardSize = this.boardSize
        val buttonSize = this.mineButton.buttonSize
        val borderSize = this.borderSize 
        val boardEdgeSize = this.boardEdgeSize
        val boardLeftBottom = arrayOf(- boardSize[0] / 2, - boardSize[1] / 2)
        val gaps = gapForDrawing
        val locations = Array<FloatArray> (rowCount * columnCount) {
            i ->
            val indices = arrayOf(i % columnCount, i / columnCount)
            FloatArray(2) {
                j ->
                var result = boardLeftBottom[j]
                result += borderSize[j] / 2
                result += boardEdgeSize[j] / 2
                result += indices[j] * gaps[j]
                result += indices[j] * buttonSize[j] 
                result += buttonSize[j] / 2
                result
            }
        }
        val zGap = buttonZGapForDrawing
        return Array<FloatArray>(locations.size){ i ->
            floatArrayOf(
                1f, 0f, 0f, 0f,
                0f, 1f, 0f, 0f,
                0f, 0f, 1f, 0f,
                locations[i][0], locations[i][1], zGap[0], 1f); 
        }
    } 
    /**
     * create buttons normal vector matrices
     */
    private fun createButtonNormalVecMatrices(): Array<FloatArray> {
        val unitMatrix = floatArrayOf(
            1f, 0f, 0f, 0f,
            0f, 1f, 0f, 0f,
            0f, 0f, 1f, 0f,
            0f, 0f, 0f, 1f) 
        return Array<FloatArray>(rowCount * columnCount) { unitMatrix }
    } 
    
    /**
     * create spin and vertical movement matrices.
     */
    fun createSpinAndVMotionMatrices(): Pair<Array<FloatArray>, IntArray>? {
        
        val t = animation.openingButtonDuration 
        val rotationCount = 0.5f
        val axis = floatArrayOf(1f, 0f, 0f)
        var result : Pair<Array<FloatArray>, IntArray>?

        result = model!!.physicsEng.calcSpinAndVerticalMotion1(t, axis, 
            rotationCount)
        return result 
    }
    
    /**
     * create spin movement  matrices.
     */
    fun createSpinMatrices(): Array<FloatArray>? {
        val t = animation.openingButtonDuration
        val rotationCount = 0.5f
        val axis = floatArrayOf(1f, 0f, 0f)
        var result : Array<FloatArray>?

        result = model!!.physicsEng.calcSpinMotion(t, axis, 
            rotationCount)
        return result 
    }
 
    /**
     * create board matrix
     */
    fun createBoardMatrix(): FloatArray {
        val boardSize = this.boardSize
        return floatArrayOf(
            boardSize[0], 0f, 0f, 0f,
            0f, boardSize[1], 0f, 0f,
            0f, 0f, 1f, 0f,
            0f, 0f, 0f, 1f)
    }
    /**
     * create board normal vector matrix
     */
    fun createBoardNormalVecMatrix() : FloatArray {
        return floatArrayOf(
            1f, 0f, 0f, 0f,
            0f, 1f, 0f, 0f,
            0f, 0f, 1f, 0f,
            0f, 0f, 0f, 1f)
    }
    private fun attachCameraListener() {
        sceneCamera?.on(null, this::onCameraChanged)
    }
    private fun detachCameraListener() {
        sceneCamera?.off(null, this::onCameraChanged)
    }
    @Suppress("UNUSED_PARAMETER")
    private fun onCameraChanged(eventName : String, camera : Camera) {
    }

    /**
     * synchronize canvas size with client size.
     */
    private fun syncCanvasWithClientSize(resized: (()->Unit)? = null) {
        val nodeId = this.canvasId
        if (nodeId != null) {
            val canvas = document.querySelector(
                canvasId!!) as HTMLCanvasElement
            syncCanvasWithClientSize(canvas, resized)
        }  
    }

    /**
     * synchronize canvas size with client size.
     */
    private fun syncCanvasWithClientSize(
        canvas: HTMLCanvasElement,
        resized: (()->Unit)?) {

        var doResize: Boolean
        doResize = canvas.width != canvas.clientWidth
        if (!doResize) {
            doResize = canvas.height != canvas.clientHeight
        }
        if (doResize) {
            canvas.width = canvas.clientWidth
            canvas.height = canvas.clientHeight
            if (resized != null) {
                resized()
            }
        }  
    }


    /**
     * sync gl viewport with scene size
     */
    private fun syncViewportWithSceneSize(
        gl: WebGLRenderingContext) {
        val curPort = gl.getParameter(
            WebGLRenderingContext.VIEWPORT) as IntArray
        val sceneViewport = getSceneViewport(gl) 
        if (sceneViewport[0] != curPort[0]
            || sceneViewport[1] != curPort[1]
            || sceneViewport[2] != curPort[2]
            || sceneViewport[3] != curPort[3]) {
            gl.viewport(sceneViewport[0], 
                sceneViewport[1], 
                sceneViewport[2],
                sceneViewport[3])
        } 
    }



    /**
     * get scene viewport
     */
    fun getSceneViewport(gl: WebGLRenderingContext): IntArray {
        return Scene.getSceneViewport(gl)
    }


    /**
     * calculate centered viewport in scene buffer
     */
    private fun getCenterViewportInScene(
        gl: WebGLRenderingContext): IntArray {
        val screenSize = getScreenSize(gl)
        return Scene.calcCenterViewport(screenSize[0], screenSize[1], gl)
    }

    /**
     * calculate viewport ratio in scene
     */
    internal fun calcCanvasViewportRatioInScene(
        gl: WebGLRenderingContext): FloatArray {
        val centerViewport = getCenterViewportInScene(gl)
        val sceneViewport = getSceneViewport(gl) 
        val centerBounds = intArrayOf(
            centerViewport[0],
            centerViewport[1],
            centerViewport[0] + centerViewport[2],
            centerViewport[1] + centerViewport[3]) 
        val result = floatArrayOf(
            (centerBounds[0] - sceneViewport[0].toFloat())
                / sceneViewport[2].toFloat(),
            (centerBounds[1] - sceneViewport[1].toFloat())
                / sceneViewport[3].toFloat(),
            (centerBounds[2] - sceneViewport[0].toFloat())
                / sceneViewport[2].toFloat(),
            (centerBounds[3] - sceneViewport[1].toFloat())
                / sceneViewport[3].toFloat())
        return result 
    }


    



    /**
     * synchronize viewport with centered screen in scene viewport.
     */
    private fun syncViewportWithCenterInScene(
        gl: WebGLRenderingContext) {
        val curPort = gl.getParameter(
            WebGLRenderingContext.VIEWPORT) as Int32Array
         
        val viewport = getCenterViewportInScene(gl)
        if (viewport[0] != curPort[0]
            || viewport[1] != curPort[1]
            || viewport[2] != curPort[2]
            || viewport[3] != curPort[3]) {
            gl.viewport(viewport[0], viewport[1], viewport[2], viewport[3])
        } 
    }



    /**
     * sync gl viewport with screen size
     */
    private fun syncViewportWithScreenSize(
        gl: WebGLRenderingContext) {
        syncViewportWithCanvasSize(gl)
    }

    /**
     * synchronize viewport with screen 
     */
    fun syncViewportWithScreen(gl: WebGLRenderingContext) {
        val curPort = gl.getParameter(
            WebGLRenderingContext.VIEWPORT) as IntArray
        val screenViewport = Screen.calcViewport(this, gl) 
        if (screenViewport[0] != curPort[0]
            || screenViewport[1] != curPort[1]
            || screenViewport[2] != curPort[2]
            || screenViewport[3] != curPort[3]) {
            gl.viewport(screenViewport[0], 
                screenViewport[1],
                screenViewport[2],
                screenViewport[3])
        }
    }


    /**
     * sync gl viewport with canvas size
     */
    private fun syncViewportWithCanvasSize(
        gl: WebGLRenderingContext) {

        val curPort = gl.getParameter(
            WebGLRenderingContext.VIEWPORT) as Int32Array
        
        val canvasSize = getScreenSize(gl)
        if (canvasSize[0] != curPort[2] || canvasSize[1] != curPort[3]) {
            gl.viewport(0, 0, canvasSize[0], canvasSize[1])
        } 
    }



    /**
     * sync gl viewport with drawing buffer size
     */
    private fun syncViewportWithDrawingBufferSize(
        gl: WebGLRenderingContext) {

        val curPort = gl.getParameter(
            WebGLRenderingContext.VIEWPORT) as Int32Array
        
        val bufferSize = getDrawingBufferSize(gl)
        if (bufferSize[0] != curPort[2] || bufferSize[1] != curPort[3]) {
            gl.viewport(0, 0, bufferSize[0], bufferSize[1])
        } 
    }

    /**
     * get screen viewport
     */
    internal fun getScreenViewport(): IntArray? {
        val gl = renderingContext
        var result : IntArray? = null
        if (gl != null) {
            result = getScreenViewport(gl)
        }
        return result
    }
    /**
     * get screen viewport
     */
    internal fun getScreenViewport(
        gl: WebGLRenderingContext): IntArray {
        val screenSize = getScreenSize(gl)
        val result = intArrayOf(0, 0, screenSize[0], screenSize[1]) 
        return result
    }



    /**
     * get screen size
     */
    internal fun getScreenSize(
        gl: WebGLRenderingContext): IntArray {
        return getDrawingBufferSize(gl)
        // return getCanvasSize(gl) 
    }


    /**
     * get canvas size 
     */
    private fun getCanvasSize(gl: WebGLRenderingContext) : IntArray {
        val result = intArrayOf(gl.canvas.width, gl.canvas.height) 
        return result
    }

    /**
     * get webgl drawing buffer size
     * In some case, html canvas size is not equal drawingBuffer size.
     * The drawingBuffer is allocated buffer size for drawing actually.
     */
    private fun getDrawingBufferSize(gl: WebGLRenderingContext): IntArray {
        val result = intArrayOf(gl.drawingBufferWidth, gl.drawingBufferHeight)
        return result
    }

    /**
     * handle the event for resizing window
     */
    @Suppress("UNUSED_PARAMETER")
    private fun onResized(event: Event) {
        syncCanvasWithClientSize {
            val gl = renderingContext
            if (gl != null) { 
                setupCamera(gl)
                syncLocReaderSizeWithScene(gl)
                syncBloomWithSceneBuffer(gl)
                syncShadowDepthWithSceneBuffer(gl)
                syncSceneWithScreenSize(gl)
                syncPointLightEditWithSceneBuffer()
                postUpdateScreen(gl)
            }
        }

    }

    /**
     * synchronize shadow depth buffer with scene buffer
     */
    private fun syncShadowDepthWithSceneBuffer(
        gl: WebGLRenderingContext) {
        shadowMap.syncShadowDepthAndTextureWithSceneSize(gl, renderingCtx)
    }

    /**
     * synchronize bloom effect with scene buffer.
     */
    private fun syncBloomWithSceneBuffer(
        gl: WebGLRenderingContext) {
        bloom.syncSizeWithScene(this, gl)
    }

    /**
     * sychronize point edit light with scene buffer
     */
    private fun syncPointLightEditWithSceneBuffer() {
        pointLightEdit.updateWithSceneBuffer(this)
    }

    /**
     * synchronize scene size with screen size
     */
    private fun syncSceneWithScreenSize(
        gl: WebGLRenderingContext) {
        scene.syncSizeWithScreen(this, gl)
    }
    /**
     * display game over modal
     */
    private fun postDisplayGameOverModal() {
        window.setTimeout({
            displayGameOverModal()
        }, 100) 
    }
    /**
     * display player won modal
     */
    private fun postDisplayPlayerWonModal() {
        window.setTimeout({
            displayPlayerWonModal()
        }, 100) 
    }


    /**
     * display player won modal
     */
    private fun displayPlayerWonModal() {
        playerWonModalElement?.let {

            bootstrap.Modal.getInstance(it)?.let {
                it.show()
            }
        }
    }

    /**
     * display won modal
     */
    private fun displayGameOverModal() {
        gameOverModalElement?.let {
            bootstrap.Modal.getInstance(it)?.let {
                it.show()
            }
        }
    }



    /**
     * modal hidden event handler
     */
    @Suppress("UNUSED_PARAMETER")
    private fun handleHiddenGameOverModal(e : Event) {
        nextGameOperation?.let { it() }
    }

    /**
     * modal hidden event handler
     */
    @Suppress("UNUSED_PARAMETER")
    private fun handleHiddenPlayerWonModal(e: Event) {
        nextGameOperation?.let { it() }
    }

    /**
     * play again button handler
     */
    @Suppress("UNUSED_PARAMETER")
    private fun handleClickToPlayAgain(e: Event) {
        this.nextGameOperation = {
            postResetGame() 
            this.nextGameOperation = null 
        } 
        closeParentModal(e.currentTarget as HTMLElement)
    }

    /**
     * play again button handler
     */
    @Suppress("UNUSED_PARAMETER")
    private fun handleClickToPlayNew(e: Event) {
        this.nextGameOperation = {
            postNewGame() 
            this.nextGameOperation = null 
        } 
        closeParentModal(e.currentTarget as HTMLElement)
    }

    /**
     * close bootstrap modal dailog
     */
    private fun closeParentModal(elem: HTMLElement) {
        findModalElement(elem)?.let {
            bootstrap.Modal.getInstance(it)!!.hide()
        }
    }


    /**
     * find bootstrap modal element
     */
    private fun findModalElement(elem: HTMLElement): HTMLElement? {
        val modal = bootstrap.Modal.getInstance(elem)
        return if (modal != null) {
            elem 
        } else {
            elem.parentElement?.let {
                findModalElement(it as HTMLElement) 
            }
        }
    }
  
   
    /**
     * reset game lately
     */
    private fun postResetGame() {
        window.setTimeout({
            resetGame()
        }, 100)
    }
    /**
     * reset game
     */
    private fun resetGame() {
        val model = this.model!!
        val status = model.logic.status 
        if (status != null) {
            status.clearAll()
            val buttonIndices = Array<IntArray>(0) { intArrayOf(0,0) }
            animation.setupButtons(buttons, 
                status.getOpenedIndices(),
                buttonIndices, renderingCtx)
            val gl =  renderingContext
            if (gl != null) {
                while (animation.hasNextFrame(renderingCtx)) {
                    animation.doAnimate(gl, renderingCtx)
                }
                resetGame(gl) 
            }
        }
    }

    /**
     * create new game lately
     */
    private fun postNewGame() {
        window.setTimeout({
            startNewGame()
        }, 100)
    }
    /**
     * reset game
     */
    private fun startNewGame() {
        val model = this.model!!
        val status = model.logic.status 
        if (status != null) {
            status.clearAll()
            val buttonIndices = Array<IntArray>(0) { intArrayOf(0,0) }
            animation.setupButtons(buttons, 
                status.getOpenedIndices(),
                buttonIndices, renderingCtx)
            val gl =  renderingContext
            if (gl != null) {
                while (animation.hasNextFrame(renderingCtx)) {
                    animation.doAnimate(gl, renderingCtx)
                }
                startGame(gl) 
            }
        }
    }


    /**
     * edit light settings
     */
    private fun onEditLightSetting() {
        val pointLightSetting = this.pointLightSetting
        pointLightSetting.show {
            postUpdateScreen()
        }
        postUpdateScreen()
    }
}
// vi: se ts=4 sw=4 et:
