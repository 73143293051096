package net.oc_soft.mswp.storage

import org.w3c.files.Blob
import org.w3c.xhr.FormData
import org.w3c.fetch.RequestInit

import kotlinx.browser.window
import kotlin.js.Promise

/**
 * manage user storage
 */
class UserStorage {

    
    /**
     * class instance
     */
    companion object {

        /**
         * save buffer
         */
        fun saveStorage(
            data: Blob,
            type: String,
            id: String = ""): Promise<String> {
            
            val body = FormData()
            body.set("write", id)
            body.set("contents", data)
            body.set("type", type)
            val result = Promise<String> {
                resolve, reject -> 
                window.fetch("storage.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    if (it.ok) {
                        it.json()    
                    } else {
                        Promise.reject(Throwable(it.statusText)) 
                    }
                }).then({
                    val jsObj: dynamic = it 
                    resolve(jsObj["storageId"] as String)
                    Unit
                }).catch({
                    reject(it)
                })
            }
            return result
        }
        /**
         * delete storage
         */
        fun deleteStorage(
            id: String): Promise<Unit> {
            
            val body = FormData()
            body.set("delete", id)
            val result = Promise<Unit> {
                resolve, reject -> 
                window.fetch("storage.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    if (it.ok) {
                        resolve(Unit)
                        Unit
                    } else {
                        Promise.reject(Throwable(it.statusText)) 
                    }
                }).catch({
                    reject(it)
                })
            }
            return result
        }
        /**
         * update game id
         */
        fun updateGameId(
            id: String,
            gameId: String): Promise<Unit> {
            
            val body = FormData()
            body.set("update-game-id", id)
            body.set("game-id", gameId)
            val result = Promise<Unit> {
                resolve, reject -> 
                window.fetch("storage.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    if (it.ok) {
                        resolve(Unit)
                        Unit
                    } else {
                        Promise.reject(Throwable(it.statusText)) 
                    }
                }).catch({
                    reject(it)
                })
            }
            return result
        }
    }
}

// vi: se ts=4 sw=4 et:
