package net.oc_soft.mswp.ui

import kotlinx.browser.document
import org.w3c.dom.events.Event
import net.oc_soft.ui.Dropdown

/**
 * settings for user interface
 */
class GameSettings(val option: Option) {
    /**
     * handle click event on setting item
     */
    var labelSettingItemHandler : ((Event) -> Unit)? = null


    /**
     * handle click event on color setting item
     */
    var colorSettingItemHandler: ((Event) -> Unit)? = null

    /**
     * handle click event on editing light origin
     */
    var lightEditItemHandler: ((Event) -> Unit)? = null


    /**
     * handle click event on visible usage
     */
    var visibleUsageItemHandler: ((Event) -> Unit)? = null

    /**
     * dropdown handler
     */
    var dropdown = Dropdown()

    /**
     * option for game settings
     */
    data class Option(
        val queries: Queries) {
        /**
         * html dom queries 
         */
        data class Queries(
            val setting: String,
            val dropdown: String,
            val labelSetting: String,
            val colorSetting: String,
            val lightSetting: String,
            val visibleUsage: String)   
   } 
     
    /**
     * application settings
     */
    var appSettings: AppSettings? = null
	
    /**
     * attach this object to html dom.
     */
    fun bind(appSettings: AppSettings) {
        this.appSettings = appSettings
        
        labelSettingItemHandler = {
            onClickOnLabelSettingItem(it)
        }
        lightEditItemHandler = {
            onClickOnLightEditItem(it)
        }
        colorSettingItemHandler = {
            onClickOnColorSetting(it)
        }
        visibleUsageItemHandler = {
            onClickOnVisibleUsage(it)
        }
    
        // settingItem.on("click", settingItemHandler!!)
        dropdown.bind(option.queries.setting, option.queries.dropdown)
        document.querySelector(option.queries.labelSetting)?.let {
            it.addEventListener("click", labelSettingItemHandler!!)
        }
        
        document.querySelector(option.queries.lightSetting)?.let {
            it.addEventListener("click", lightEditItemHandler!!)
        }
        document.querySelector(option.queries.colorSetting)?.let {
            it.addEventListener("click", colorSettingItemHandler!!)
        }
        document.querySelector(option.queries.visibleUsage)?.let {
            it.addEventListener("click", visibleUsageItemHandler!!)
        }
    }

    /**
     * detatch this object from html dom.
     */
    fun unbind() {

        visibleUsageItemHandler?.let {
            val hdlr = it
            document.querySelector(option.queries.visibleUsage)?.let {
                it.removeEventListener("click", hdlr)
            }
            visibleUsageItemHandler = null
        }
        labelSettingItemHandler?.let {
            val hdlr = it
            document.querySelector(option.queries.labelSetting)?.let {
                it.removeEventListener("click", hdlr) 
            }
            labelSettingItemHandler = null
        }
        colorSettingItemHandler?.let {
            val hdlr = it
            document.querySelector(option.queries.colorSetting)?.let {
                it.removeEventListener("click", hdlr)
            }
            colorSettingItemHandler = null
        }
        lightEditItemHandler?.let {
            val hdlr = it
            document.querySelector(option.queries.lightSetting)?.let {
                it.removeEventListener("click", hdlr)
            }
            lightEditItemHandler = null
        }


        dropdown.unbind()
        this.appSettings = null
    }
    /**
     * handle setting item
     */
    @Suppress("UNUSED_PARAMETER")
    fun onClickOnLabelSettingItem(
        event: Event) {
        appSettings?.iconSelector?.show()
        hideDropdown()
    }


    /**
     * handle click event for color setting
     */
    @Suppress("UNUSED_PARAMETER")
    fun onClickOnColorSetting(event: Event) {
        appSettings?.editColorScheme()
        hideDropdown()
    }

    /**
     * handle setting item for light editing
     */
    @Suppress("UNUSED_PARAMETER")
    fun onClickOnLightEditItem(
        event: Event) {
        hideDropdown()
        appSettings?.editPointLight()
    }

    /**
     * handle click event to show usage
     */
    @Suppress("UNUSED_PARAMETER")
    fun onClickOnVisibleUsage(event: Event) {
        hideDropdown()
        appSettings?.visibleUsage()
    }

    /**
     * hide dropdown
     */
    fun hideDropdown() {
        dropdown.visibledDropdown = false
    }
}
// vi: se ts=4 sw=4 et: 
