package net.oc_soft.mswp
 
/**
 * color material convert
 */
class ColorMaterialConvert(
    val specularScale: Float,
    val ambientScale: Float,
    val diffuseScale: Float,
    val shiness: Float) {


    /**
     * convert from color to material
     */ 
    fun convert(color: FloatArray): Material {
        val colorLightConvert = ColorLightConvert(
            specularScale, ambientScale, diffuseScale)
        return Material(colorLightConvert(color), shiness)
    }

    /**
     * function operator
     */
    operator fun invoke(color: FloatArray): Material {
        return convert(color)
    }
}

// vi: se ts=4 sw=4 et:
