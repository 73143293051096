package net.oc_soft.mswp.ui

import kotlinx.browser.window
import kotlinx.browser.document
import kotlin.js.Promise
import org.w3c.fetch.Response
import org.w3c.fetch.RequestInit
import org.w3c.xhr.FormData
import org.w3c.dom.url.URLSearchParams
import org.w3c.dom.HTMLElement
import org.w3c.dom.Element
import org.w3c.dom.events.Event

/**
 * usage interface
 */
class Usage(
    /**
     * usage option
     */
    val option: Option) {


    data class Option( 
        /**
         * modal dialog  query
         */
        val modalQuery: String)
    

    /**
     * handler to respond the shown event.
     */
    var shownModalHdlr: ((Event)->Unit)? = null 


    /**
     * handler to respond the event at instantiating modal
     */
    var showModalHdlr: ((Event)->Unit)? = null

    /**
     * handler to respond the hidden event
     */
    var hiddenModalHdlr: ((Event)->Unit)? = null


    /**
     * loading status html element
     */
    var loadingElement: HTMLElement? = null

    /**
     * get usage contents
     */
    fun getContents(contents: String): Promise<Response> {

        val loc = window.location 

        val searchParams = URLSearchParams(loc.search)
        val lang = searchParams.get("lang")

        val frmData = FormData()
        frmData.append("contents", contents)

        if (lang != null) {
            frmData.append("lang", lang)
        }

        val param = object: RequestInit {
            override var method: String? = "POST"
            override var body: dynamic = frmData
        }
        return window.fetch(
            "usage.php",
            param
        )
    } 

    /**
     * visible modal usage dialog
     */
    fun show() {
        bindModal()

        document.querySelector(option.modalQuery)?.let {
            val modal = bootstrap.Modal(it as HTMLElement)
            modal.show()
        }
    }

    /**
     * hide modal color selector
     */
    fun hide() {
        document.querySelector(option.modalQuery)?.let {
            bootstrap.Modal.getInstance(it as HTMLElement)?.let {
                it.hide()
                it.dispose()
            }
        }
    }


    /**
     * bind this object into html node
     */
    fun bindModal() {
        showModalHdlr = { onShowModal(it) }
        shownModalHdlr = { onShownModal(it) }
        hiddenModalHdlr = { onHiddenModal(it) }

        document.querySelector(option.modalQuery)?.let {
            val modalElement = it 
            modalElement.addEventListener(
                "hidden.bs.modal", hiddenModalHdlr!!)
            modalElement.addEventListener(
                "shown.bs.modal", this.shownModalHdlr!!)
            modalElement.addEventListener(
                "show.bs.modal", this.showModalHdlr!!) 
        }
    }


    /**
     * unbind this object from html node
     */
    fun unbindModal() {

        loadingElement?.let {
            replaceModalContents(it)
            loadingElement = null
        }
 
        document.querySelector(option.modalQuery)?.let {
            val modalElem = it 

            shownModalHdlr?.let {
                modalElem.removeEventListener("shown.bs.modal", it)
                shownModalHdlr = null
            }
            showModalHdlr?.let {
                modalElem.removeEventListener("show.bs.modal", it)
                showModalHdlr = null
            }
            hiddenModalHdlr?.let {
                modalElem.removeEventListener("hidden.bs.modal", it)
                hiddenModalHdlr = null
            }
        }
    } 

    /**
     * called when the modal is instantiated
     */
    @Suppress("UNUSED_PARAMETER")
    fun onShowModal(event: Event) {
        getContents("contents-1").then({
            it.text()
        }).then({
            if (loadingElement == null) {
                val contents = document.createElement("div") as HTMLElement
                contents.innerHTML = it
                loadingElement = replaceModalContents(contents) 
            }
        })
    }

    
    /**
     * this is called when modal dailog is showing
     */
    @Suppress("UNUSED_PARAMETER")
    fun onShownModal(event: Event) {
    }

    /**
     * handle the event to hide modal
     */
    @Suppress("UNUSED_PARAMETER")
    fun onHiddenModal(e: Event) {
        unbindModal()
    }
     

    /**
     * replace modal contents
     */
    fun replaceModalContents(modalContents: HTMLElement): HTMLElement? {
        return document.querySelector(option.modalQuery)?.let {
            val modalElem = it 
            modalElem.querySelector(".modal-body")?.let {
                val bodyElem = it 
                var res:HTMLElement? = null
                bodyElem.firstElementChild?.let {
                    it.replaceWith(modalContents)  
                    res = it as HTMLElement
                }?: bodyElem.append(modalContents)
                res
            }
        }
    }
}

// vi: se ts=4 sw=4 et:
