package line

/**
 * alternative uri
 */
external interface AltURI {
    
    /**
     * desktop uri
     */
    val desktop: String
}


/**
 * create alternative url
 */
fun createAltURI(
    desktopURI: String): AltURI {

    val result: dynamic = object { }

    result["desktop"] = desktopURI 

    return result
}

// vi: se ts=4 sw=4 et:
