package net.oc_soft.mswp.ui

import net.oc_soft.mswp.ColorMaterialConvert

/**
 * color material conversion
 */
class ColorMaterial(
    val mineButtonConvert: ColorMaterialConvert,
    val boardConvert: ColorMaterialConvert) {
     
}

// vi: se ts=4 sw=4 et:
