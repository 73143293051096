package net.oc_soft.mswp.ui

import kotlin.collections.MutableList

import org.khronos.webgl.WebGLRenderingContext

import net.oc_soft.mswp.Matrix
import net.oc_soft.mswp.ui.grid.Buttons

/**
 * manage button opening animation
 */
class OpeningButton(
    var openingDuration: Float = 0.4f) {

 
    /**
     * matrices for animation
     */
    var animationMatrices: MutableList<Array<FloatArray>>? = null

    /**
     * normal matrices for animation
     */
    var animationNormalMatrices: MutableList<Array<FloatArray>>? = null

    /**
     * animation procedure
     */
    var animator: ((OpeningButton, 
        WebGLRenderingContext,
        RenderingCtx)->Unit)? = null

    /**
     * create matrices for each frame
     */
    fun setupButtons(buttons : Buttons,
        openedButtonIndices: Array<IntArray>,
        buttonIndices : Array<IntArray>,
        renderingCtx : RenderingCtx) {
        val buttonMatrices = renderingCtx.cloneButtonMatrices()    
        val spinAndVMotionMtx = renderingCtx.spinAndVMotionMatrices
        val spinMotionMtx = renderingCtx.spinMotionMatrices
        val buttonNormalVecMatrices = renderingCtx.buttonNormalVecMatrices
        if (buttonMatrices != null
            && buttonNormalVecMatrices != null
            && spinAndVMotionMtx != null
            && spinMotionMtx != null) {
            val countOfFrames = kotlin.math.min(spinAndVMotionMtx.size,
                spinMotionMtx.size)

            var animationMatrices = Array<Array<FloatArray>>(
                countOfFrames) {
                i ->
                val aniMtx = spinAndVMotionMtx[i]
                val lastAniMtx = spinMotionMtx[spinMotionMtx.size - 1]
                val frameMat = Array<FloatArray>(
                    buttons.rowCount * buttons.columnCount) {
                    j -> buttonMatrices[j]
                }
                openedButtonIndices.forEach({
                    rowCol ->
                    var locMatIdx = rowCol[0] * buttons.columnCount
                    locMatIdx += rowCol[1]
                    val locMat = frameMat[locMatIdx]
                    val newLocMat = Matrix.multiply(lastAniMtx, locMat)
                    frameMat[locMatIdx] = newLocMat!!
                }) 
                buttonIndices.forEach({
                    rowCol ->
                    var locMatIdx = rowCol[0] * buttons.columnCount
                    locMatIdx += rowCol[1]
                    val locMat = frameMat[locMatIdx]
                    val newLocMat = Matrix.multiply(aniMtx, locMat)
                    frameMat[locMatIdx] = newLocMat!!
                })
                frameMat 
                 
            }
            val normalVecAnimMatrices = Array<Array<FloatArray>>(
                countOfFrames) {
                midx ->
                val normalVecAniMtx = spinMotionMtx[midx]
                val frameMat = Array<FloatArray>(
                    buttons.rowCount * buttons.columnCount) {
                    j ->           
                    buttonNormalVecMatrices[j]
                }
                buttonIndices.forEach {
                    rowCol ->
                    var locMatIdx = rowCol[0] * buttons.columnCount
                    locMatIdx += rowCol[1]
                    val aMat = frameMat[locMatIdx]
                    val newMat = Matrix.multiply(normalVecAniMtx, aMat)
                    frameMat[locMatIdx] = newMat!!
                }
                frameMat 
            }
            this.animationMatrices = 
                animationMatrices.toMutableList()
            this.animationNormalMatrices =
                normalVecAnimMatrices.toMutableList()
            setupAnimator()
             
         }
    }

    /**
     * set up animator
     */
    fun setupAnimator() {
        animator = createDarkToBrightAnimator0()
    }

    /**
     * proceed a animation frame to next
     */
    fun doAnimate(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        val animator = this.animator
        if (animator != null) {
            animator(this, gl, renderingCtx)
        }
    }


    /**
     * create opening button animator 
     */
    fun createOpeningAnimator()
        : (OpeningButton, WebGLRenderingContext, RenderingCtx)->Unit {
        val result: (OpeningButton, 
            WebGLRenderingContext, RenderingCtx)->Unit = {
            openingButton, _, renderingCtx ->
            openingButton.animateOpening(renderingCtx)
            if (openingButton.animationMatrices == null) {
                openingButton.animator =
                    openingButton.createBrightToDarkAnimator0()
            }
        }
        return result
    }

    /**
     * create dark to bright animator phase 0 
     */
    fun createDarkToBrightAnimator0()
        : (OpeningButton, WebGLRenderingContext, RenderingCtx)->Unit {
        val result: (OpeningButton,
            WebGLRenderingContext, RenderingCtx)->Unit = {
            openingButton, gl, renderingCtx ->
            openingButton.animateGlowingDarkToBright0(gl, renderingCtx)
            openingButton.animator = 
                openingButton.createDarkToBrightAnimator1()
        }
        return result
    }

    /**
     * create dark to bright animator phase 1
     */
    fun createDarkToBrightAnimator1()
        : (OpeningButton, WebGLRenderingContext, RenderingCtx)->Unit {
        val result: (OpeningButton,
            WebGLRenderingContext, RenderingCtx) -> Unit = {
            openingButton, gl, renderingCtx ->
            openingButton.animateGlowing(gl, renderingCtx)
            val openingButtonBlinking = renderingCtx.openingButtonBlinking 
            if (openingButtonBlinking != null) {
                if (!openingButtonBlinking.active) {
                    openingButton.animator = 
                        openingButton.createOpeningAnimator()
                }
            }
        }
        return result
    }

    /**
     * create bright to dark animator phase 0 
     */
    fun createBrightToDarkAnimator0()
        : (OpeningButton, WebGLRenderingContext, RenderingCtx)->Unit {
        val result: (OpeningButton,
            WebGLRenderingContext, RenderingCtx)->Unit = {
            openingButton, gl, renderingCtx ->
            openingButton.animateGlowingBrightToDark0(gl, renderingCtx)
            openingButton.animator = 
                openingButton.createBrightToDarkAnimator1()
        }
        return result
    }

    /**
     * create bright to dark animator phase 1
     */
    fun createBrightToDarkAnimator1()
        : (OpeningButton, WebGLRenderingContext, RenderingCtx)->Unit {
        val result: (OpeningButton,
            WebGLRenderingContext, RenderingCtx) -> Unit = {
            openingButton, gl, renderingCtx ->
            openingButton.animateGlowing(gl, renderingCtx)
            val openingButtonBlinking = renderingCtx.openingButtonBlinking 
            if (openingButtonBlinking != null) {
                if (!openingButtonBlinking.active) {
                    openingButton.animator = null
                }
            }
        }
        return result
    }


    /**
     * animate glowing from dark to bright
     */
    fun animateGlowingDarkToBright0(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        val openingButtonBlinking = renderingCtx.openingButtonBlinking
        if (openingButtonBlinking != null) {
            openingButtonBlinking.startAtDark()  
            animateGlowing(gl, renderingCtx)
            openingButtonBlinking.stopAtBright()
        }
    }

    /**
     * animate glowing from bright to dark
     */
    fun animateGlowingBrightToDark0(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        val openingButtonBlinking = renderingCtx.openingButtonBlinking 
        if (openingButtonBlinking != null) {
            openingButtonBlinking.startAtBright()  
            animateGlowing(gl, renderingCtx)
            openingButtonBlinking.stopAtDark()
        }
    }


    /**
     * animate glowing
     */
    fun animateGlowing(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        val openingButtonBlinking = renderingCtx.openingButtonBlinking 
        if (openingButtonBlinking != null) {
            val colorBuffer = renderingCtx.openingButtonColorBuffer
            if (colorBuffer != null) {
                openingButtonBlinking.nextFrame(gl, colorBuffer)
            }
        }
    }  

     
    /**
     * animate opening button
     */
    fun animateOpening(
        renderingCtx: RenderingCtx) {
        val animationMatrices = this.animationMatrices
        val normalVecAniMtx = this.animationNormalMatrices 
        if (animationMatrices != null
            && normalVecAniMtx != null) {
            renderingCtx.buttonMatricesForDrawing = 
                animationMatrices[0]
            renderingCtx.buttonNormalVecMatricesForDrawing =
                normalVecAniMtx[0] 
            animationMatrices.removeAt(0)
            normalVecAniMtx.removeAt(0)
            if (animationMatrices.size == 0) {
                this.animationMatrices = null
            }
            if (normalVecAniMtx.size == 0) {
                this.animationNormalMatrices = null
            }

        }
    }
   
    /**
     * You get true if rendering context has next frame to animate.
     */
    fun hasNextFrame(): Boolean {
        val result = animator != null
        return result
    }

}

// vi: se ts=4 sw=4 et:
