package line

/**
 * flex bubble container
 */
external interface FlexBubbleContainer: FlexContainer {
    
    /**
     * size
     */
    val size: String?

    /**
     * direction
     */
    val direction: String?

    /**
     * header element
     */
    val header: FlexBox?    

    /**
     * component must be FlexImage or FlexBox
     */
    val hero: FlexHero?


    /**
     * body
     */ 
    val body: FlexBox?

    
    /**
     * footer element
     */
    val footer: FlexBox? 


    /**
     * styles
     */
    val styles: FlexBubbleStyle?

    /**
     * action
     */
    val action: Action?
}

/**
 * create flex bubble container
 */
fun createFlexBubbleContainer(
    size: String? = null,
    direction: String? = null,
    header: FlexBox? = null,
    hero: FlexHero? = null,
    body: FlexBox? = null,
    footer: FlexBox? = null,
    styles: FlexBubbleStyle? = null,
    action: Action? = null): FlexBubbleContainer {

    val result: dynamic = object { }

    val nameValues = arrayOf(
        Pair<String, Any?>("type", "bubble"),
        Pair<String, Any?>("size", size),
        Pair<String, Any?>("direction", direction),
        Pair<String, Any?>("header", header),
        Pair<String, Any?>("hero", hero),
        Pair<String, Any?>("body", body),
        Pair<String, Any?>("footer", footer),
        Pair<String, Any?>("styles", styles),
        Pair<String, Any?>("action", action))
    
    nameValues.forEach {
        if (it.second != null) {
            result[it.first] = it.second
        }
    }
    return result
}
    


// vi: se ts=4 sw=4 et:
