package line

external interface TextMessage : Message {
    

    /**
     * text message
     */
    val text: String?

    /**
     * emoji
     */
    val emojis: Array<Emoji>?
}

/**
 * create text message
 */
fun createTextMessage(
    text: String?,
    emojis: Array<Emoji>? = null): TextMessage {
    val result: dynamic = object {}
    
    result["type"] = "text" 
    if (text != null) {
        result["text"] = text
    }
    if (emojis != null) {
        result["emojis"] = emojis
    }
    return result
}

// vi: se ts=4 sw=4 et:

