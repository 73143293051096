package line

/**
 * flex image
 */
external interface FlexImage: FlexComponent, FlexHero {

    /**
     * image url
     */
    val url: String


    /**
     * ratio width or height
     */
    val flex: Int?


    /**
     * margin
     */
    val margin: String?


    /**
     * position
     */
    val position: String?

    /**
     * offset top
     */
    val offsetTop: String?

    /**
     * offset bottom
     */
    val offsetBottom: String?

    /**
     * offset start
     */
    val offsetStart: String?

    /**
     * offset end 
     */
    val offsetEnd: String?

    /**
     * alignment style in horizontal direction
     */
    val align: String?

    /**
     * alignment style in vertial direction
     */
    val gravity: String?

    /**
     * maximum image width
     */
    val size: String?


    /**
     * aspect
     */
    val aspectRatio: String?


    /**
     * aspect mode
     */
    val aspectMode: String?

    /**
     * background color
     */
    val backgroundColor: String?

    /**
     * action object
     */
    val action: Action?


    /**
     * animated image
     */
    val animated: Boolean?
}

/**
 * create flex image 
 */
fun createFlexImage(
    url: String,
    flex: Int? = null,
    margin: String? = null,
    position: String? = null,
    offsetTop: String? = null,
    offsetBottom: String? = null,
    offsetStart: String? = null,
    offsetEnd: String? = null,
    align: String? = null,
    gravity: String? = null,
    size: String? = null,
    aspectRatio: String? = null,
    aspectMode: String? = null,
    backgroundColor: String? = null,
    action: Action? = null,
    animated: Boolean? = null): FlexImage {
    val nameAndValues = arrayOf(
        Pair<String, Any?>("url", url),
        Pair<String, Any?>("flex", flex),
        Pair<String, Any?>("margin", margin),
        Pair<String, Any?>("position", position),
        Pair<String, Any?>("offsetTop", offsetTop),
        Pair<String, Any?>("offsetBottom", offsetBottom),
        Pair<String, Any?>("offsetStart", offsetStart),
        Pair<String, Any?>("offsetEnd", offsetEnd),
        Pair<String, Any?>("align", align),
        Pair<String, Any?>("gravity", gravity),
        Pair<String, Any?>("size", size),
        Pair<String, Any?>("aspectRatio", aspectRatio),
        Pair<String, Any?>("aspectMode", aspectMode),
        Pair<String, Any?>("backgroundColor", backgroundColor),
        Pair<String, Any?>("action", action),
        Pair<String, Any?>("animated", animated)) 

    val result: dynamic = object { }
    result["type"] = "image"

    nameAndValues.forEach {
        if (it.second != null) {
            result[it.first] = it.second
        }
    }
    return result
}
// vi: se ts=4 sw=4 et:
