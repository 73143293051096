package net.oc_soft.mswp.settings


/**
 * line session 
 */
data class LineSession( 
    /**
     * share dialog opening status
     */
    val openShareDialog: Boolean
    ) 

// vi: se ts=4 sw=4 et:
