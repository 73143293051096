package net.oc_soft.mswp.ui


import kotlin.collections.MutableList
import kotlin.collections.HashMap
import net.oc_soft.mswp.storage.Codec

/**
 * icon setting
 */
class IconSetting(
    ngIcon: Codec.Icon = Codec.Icon("fas", "skull"),
    okIcon: Codec.Icon = Codec.Icon("fas", "star"),
    flagIcon: Codec.Icon = Codec.Icon("fas", "flag"),
    lightMarkerIcon: Codec.Icon = Codec.Icon("fas", "sun")) {

    /**
     * class instance
     */
    companion object {
        /**
         * NG icon property name
         */
        val NG_ICON = "ng"
        /**
         * OK icon property name
         */ 
        val OK_ICON = "ok"

        /**
         * flag icon property name
         */
        val FLAG_ICON = "flag"

        /**
         * light origin marker
         */
        val LIGHT_MARKER = "light-marker"

        /**
         * all icon property names
         */
        val allIcons : Array<String>
            get() {
                val result = arrayOf(
                    NG_ICON,
                    OK_ICON,
                    FLAG_ICON, 
                    LIGHT_MARKER)
                return result
            }
    }

    /**
     * NG icon
     */
    var ngIcon: Codec.Icon = ngIcon
        set(value) {
            if (field != value) {
                field = value
                notifyChange(NG_ICON)
            }
        }
     
    /**
     * OK icon
     */
    var okIcon: Codec.Icon = okIcon
        set(value) {
            if (field != value) {
                field = value
                notifyChange(OK_ICON)
            }
        }
    /**
     * Flag icon
     */
    var flagIcon: Codec.Icon = flagIcon
        set(value) {
            if (field != value) {
                field = value
                notifyChange(FLAG_ICON)
            }
        }
 
    /**
     * light origin marker icon
     */
    var lightMarkerIcon: Codec.Icon = lightMarkerIcon
        set(value) {
            if (field != value) {
                field = value
                notifyChange(LIGHT_MARKER)
            }
        } 

    /**
     * snap short of icon mapping
     */
    val icons: Map<String, Codec.Icon>
        get() {
            val result = HashMap<String, Codec.Icon>()
            result[OK_ICON] = okIcon
            result[NG_ICON] = ngIcon
            result[FLAG_ICON] = flagIcon
            result[LIGHT_MARKER] = lightMarkerIcon
            return result
        }
    
    /**
     * event listeners
     */
    private val listeners: MutableList<(Any?, String)->Unit>
        = ArrayList<(Any?, String)->Unit>()

    /**
     * operator
     */
    operator fun set(key: String, icon: Codec.Icon) {
        when (key) {
            OK_ICON -> okIcon = icon
            NG_ICON -> ngIcon = icon
            FLAG_ICON -> flagIcon = icon
            LIGHT_MARKER -> lightMarkerIcon = icon
        }    
    }

    /**
     * add listener
     */ 
    fun addListener(listener: (Any?, String)->Unit) {
        listeners.add(listener) 
    }
    /**
     * remove listener
     */
    fun removeListener(listener: (Any?, String)->Unit) {
        val idx = listeners.indexOfLast { it == listener }
        if (idx >= 0) {
            listeners.removeAt(idx)
        }
    }

    /**
     * replace icons
     */
    fun replaceIcons(icons: Map<String, Codec.Icon?>) {
        allIcons.forEach {
            val icon = icons[it]
            if (icon != null) {
                this[it] = icon
            }
        }

    }

    /**
     * notify change
     */
    private fun notifyChange(name: String) {
        val listeners = ArrayList(this.listeners)
        listeners.forEach { it(this, name) }
    }
}
// vi: se ts=4 sw=4 et:
