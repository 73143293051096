package net.oc_soft.mswp

class ColorLightConvert(
    val specularScale: Float,
    val ambientScale: Float,
    val diffuseScale: Float) {

    /**
     * convert from color to light
     */ 
    fun convert(color: FloatArray): Light {
        val srcColor = color.copyOf(3)


        val specular = FloatArray(srcColor.size) {
            srcColor[it] * specularScale
        }
        val ambient = FloatArray(srcColor.size) {
            srcColor[it] * ambientScale
        }
        val diffuse = FloatArray(srcColor.size) {
            srcColor[it] * diffuseScale
        }
        return Light(specular, ambient, diffuse) 
    }
    
    /**
     * function operator
     */
    operator fun invoke(color: FloatArray): Light {
        return convert(color)
    }

}
    

// vi: se ts=4 sw=4 et:
