package line

/**
 * flex message
 */
external interface FlexMessage : Message {
    /**
     * alternative text
     */
    val altText: String


    /**
     * contents
     */
    val contents: FlexContainer 
}


/**
 * create flex message
 */
fun createFlexMessage(
    altText: String,
    contents: FlexContainer) : FlexMessage {
    
    val result: dynamic = object { }

    result["type"] = "flex"
    result["altText"] = altText
    result["contents"] = contents

    return result
}


// vi: se ts=4 sw=4 et:
