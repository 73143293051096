package net.oc_soft.mswp

import kotlin.js.Promise
import org.khronos.webgl.ArrayBuffer


import net.oc_soft.mswp.storage.External

/**
 * manage assets
 */
class Assets {
    /**
     * class instance
     */
    companion object {
        /**
         * load line liff id
         */
        fun loadLiffId(): Promise<String?> {
            val result = External.loadLiffId()
            return result
        }

        /**
         * load glrs web assembly
         */
        fun loadGlrsWasm(): Promise<ArrayBuffer?> {
            val result = External.loadGlrsWasm()
            return result
        }
    }
}
// vi: se ts=4 sw=4 et:
