package net.oc_soft.mswp.storage

import kotlin.js.Promise
import kotlin.collections.Map
import kotlin.collections.HashMap
import kotlinx.browser.window
import kotlin.text.toIntOrNull
import kotlin.math.roundToInt
import kotlin.math.min
import kotlin.math.max
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.xhr.FormData
import org.khronos.webgl.ArrayBuffer


import net.oc_soft.mswp.ColorScheme
import net.oc_soft.mswp.ColorMaterialConvert
import net.oc_soft.mswp.ui.Effector
import net.oc_soft.mswp.ui.Bloom
import net.oc_soft.mswp.ui.ColorMaterial
import net.oc_soft.mswp.ui.ShaderPrograms
import net.oc_soft.mswp.Logic

/**
 * manage user interface persistence data.
 */
class External {

   
    /**
     * class instance
     */
    companion object {
        
        /**
         * load icon
         */
        fun loadIcon(): Promise<Map<String, Codec.Icon>?> {
            val result = Promise<Map<String, Codec.Icon>?> {
                resolve, reject ->  

                val body = FormData()
                body.append("icon", "read")
                var prm = window.fetch("persistence.php",
                    RequestInit(
                        method = "POST",
                        body = body)) 
                prm.then(
                    { it.json() }
                ).then({
                    res ->
                    val res0: dynamic = res 
                    var iconData: Map<String, Codec.Icon>? = null
                    if (res0.data != null) { 
                        val tmpIconData = JSON.parse<dynamic>(res0.data)
                        iconData = Codec.dynamicToIconMap(tmpIconData)
                    }
                    resolve(iconData) 
                }).catch({
                    reason ->
                    reject(Throwable(reason))
                })
            } 
            return result 
        }

        /**
         * save icon setting
         */
        fun saveIcon(data: Map<String, Codec.Icon>): Promise<Any?> {
            val strData = JSON.stringify(Codec.iconMapToDynamic(data)) 
            val result = Promise<Any?>({
                resolve, reject ->
                val body = FormData()
                body.append("icon", "write")
                body.append("data", strData)

                window.fetch("persistence.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                        res ->
                        res.json()
                    }).then({
                        res ->
                        resolve(res) 
                    }).catch({
                        reason ->
                        reject(Throwable(reason))
                    })
            })
            return result
        }

        /**
         * save color scheme 
         */
        fun saveColorScheme(data: ColorScheme): Promise<Any?> {
            val strData = JSON.stringify(Codec.colorSchemeToDynamic(data)) 
            val result = Promise<Any?>({
                resolve, reject ->
                val body = FormData()
                body.append("color-scheme", "write")
                body.append("data", strData)

                window.fetch("persistence.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                        res ->
                        res.json()
                    }).then({
                        res ->
                        resolve(res) 
                    }).catch({
                        reason ->
                        reject(Throwable(reason))
                    })
            })
            return result
        }


        /**
         * save point light
         */
        fun savePointLight(
            pointLight: net.oc_soft.mswp.PointLight): Promise<Any?> {

            val strData = JSON.stringify(Codec.pointLightToDynamic(pointLight)) 
            val result = Promise<Any?>({
                resolve, reject ->
                val body = FormData()
                body.append("point-light", "write")
                body.append("data", strData)

                window.fetch("persistence.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    res ->
                    res.json()
                }).then({
                    res ->
                    resolve(res) 
                }).catch({
                    reason ->
                    reject(Throwable(reason))
                })
            })
            return result
        }


        /**
         * save logic
         */
        fun saveLogic(logic: Logic): Promise<Map<String, Any?>> {
            val strData = JSON.stringify(Codec.logicToDynamic(logic)) 
            val result = Promise<Map<String, Any?>> {
                resolve, reject ->
                val body = FormData()
                body.append("game-setting", "write")
                body.append("data", strData)

                window.fetch("persistence.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                   it.json() 
                }).then({
                    resolve(Codec.dynamicToMap(it))
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result
        }

         
        /**
         * load icon map from json encoded string
         */
        fun loadIconMap(str: String) : Map<String, Codec.Icon>? {
            var result: Map<String, Codec.Icon>? = null
            val tmpIconDataMap = JSON.parse<HashMap<String, Any>>(str)

            if (tmpIconDataMap.size > 0) {
                result = HashMap<String, Codec.Icon>()

                tmpIconDataMap.forEach {
                    if (it.value is Map<*, *>) {
                        @Suppress("UNCHECKED_CAST")
                        val iconData : Map<String, Any> =
                            it.value as Map<String, Any>
                        if ("prefix" in iconData
                            && "iconName" in iconData) {
                            result[it.key] = Codec.Icon(
                                iconData["prefix"] as String,
                                iconData["iconName"] as String)
                        }
                    }
                }
            }
            return result
        }

       
        /**
         * load point light location.
         */
        fun loadPointLight() : Promise<net.oc_soft.mswp.PointLight?> {
            val result = Promise<net.oc_soft.mswp.PointLight?> {
                resolve, reject ->

                val body = FormData()
                body.append("point-light", "read") 
                var prm = window.fetch("persistence.php",
                   RequestInit(
                    method = "POST",
                    body = body)) 
                prm.then({ it.json() }
                ).then({
                    val res: dynamic = it
                    var pointLight : net.oc_soft.mswp.PointLight?
                    pointLight = null
                    if (res.data != null) {
                        val pointLightData = JSON.parse<dynamic>(res.data) 
                        pointLight = Codec.dynamicToPointLight(pointLightData)
                    } 
                    resolve(pointLight)
                }).catch({
                    reason ->
                    reject(Throwable(reason))
                })
                
            }
            return result
        }
        /**
         * load logic.
         */
        fun loadLogic() : Promise<Logic?> {
            val result = Promise<Logic?> {
                resolve, reject ->

                val body = FormData()
                body.append("game-setting", "read") 
                var prm = window.fetch("persistence.php",
                   RequestInit(
                    method = "POST",
                    body = body)) 
                prm.then({ it.json() }
                ).then({
                    val res: dynamic = it
                    var logic: Logic?
                    logic = null
                    if (res.data != null) {
                        val logicData = JSON.parse<dynamic>(res.data) 
                        logic = Codec.dynamicToLogic(logicData)
                    } 
                    resolve(logic)
                }).catch({
                    reason ->
                    reject(Throwable(reason))
                })
                
            }
            return result
        }


        /**
         * load color scheme
         */
        fun loadColorScheme(): Promise<ColorScheme?> {
            val result = Promise<ColorScheme?> {
                resolve, reject ->
                val body = FormData()
                body.append("color-scheme", "read")
                var prm = window.fetch("persistence.php", 
                    RequestInit(
                        method = "POST",
                        body = body))
                prm.then({
                    it.json()
                }).then({  
                    val res0: dynamic = it
                    var colorScheme: ColorScheme? = null
                    if (res0.data != null) {
                        val tmpColorScheme = JSON.parse<dynamic>(res0.data)
                        colorScheme = Codec.dynamicToColorScheme(
                            tmpColorScheme)
                    }
                    resolve(colorScheme)
                }).catch({
                    reject(Throwable(it))
                })
            } 
            return result
        }

        /**
         * load effector
         */
        fun loadEffector(): Promise<Effector?> {
            val result = Promise<Effector?> {
                resolve, reject ->
                val body = FormData()
                body.append("rendering", "read")
                var prm = window.fetch("persistence.php", 
                    RequestInit(
                        method = "POST",
                        body = body))
                prm.then({
                    it.json()
                }).then({  
                    val res0: dynamic = it
                    var effector: Effector? = null
                    if (res0.data != null) {
                        val tmpEffector = JSON.parse<dynamic>(res0.data)
                        effector = Codec.dynamicToEffector(tmpEffector)
                    }
                    resolve(effector)
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result 
        }

        /**
         * load bloom effector
         */
        fun loadBloom(): Promise<Bloom?> {
            val result = Promise<Bloom?> {
                resolve, reject ->
                val body = FormData()
                body.append("rendering", "read")
                var prm = window.fetch("persistence.php", 
                    RequestInit(
                        method = "POST",
                        body = body))
                prm.then({
                    it.json()
                }).then({  
                    val res0: dynamic = it
                    var bloom: Bloom? = null
                    if (res0.data != null) {
                        val tmpBloom = JSON.parse<dynamic>(res0.data)
                        bloom = Codec.dynamicToBloom(tmpBloom)
                    }
                    resolve(bloom)
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result 
        }

        /**
         * load color material
         */
        fun loadColorMaterial(): Promise<ColorMaterial?> {
            val result = Promise<ColorMaterial?> {
                resolve, reject ->
                val body = FormData()
                body.append("rendering", "read")
                var prm = window.fetch("persistence.php", 
                    RequestInit(
                        method = "POST",
                        body = body))
                prm.then({
                    it.json()
                }).then({  
                    val res0: dynamic = it
                    var colorMaterial: ColorMaterial? = null
                    if (res0.data != null) {
                        val tmpColorMaterial = JSON.parse<dynamic>(res0.data)
                        colorMaterial = 
                            Codec.dynamicToColorMaterial(tmpColorMaterial)
                    }
                    resolve(colorMaterial)
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result 
        }

        

        /**
         * load shader programs
         */
        fun loadShaderPrograms(): Promise<ShaderPrograms?>  {      
            val result = Promise<ShaderPrograms?> {
                resolve, reject ->
                val body = FormData()
                body.append("shader-programs", "read")
                var prm = window.fetch("persistence.php", 
                    RequestInit(
                        method = "POST",
                        body = body))
                prm.then({
                    it.json()
                }).then({
                    val res0: dynamic = it
                    var shaderPrograms : ShaderPrograms? = null
                    if (res0.data != null) {
                        val tmpShaderPrograms = res0.data
                        shaderPrograms = Codec.dynamicToShaderPrograms(
                            tmpShaderPrograms)
                    }
                    resolve(shaderPrograms)
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result     
        }
        /**
         * load line liff id
         */
        fun loadLiffId(): Promise<String?> {
            val result = Promise<String?> {
                resolve, reject ->
                val body = FormData()
                body.append("liff-id", "read")
                window.fetch("assets.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    it.text()
                }).then({
                    resolve(it)
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            }

            return result
        }


        /**
         *  load glrs web assembly
         */
        fun loadGlrsWasm(): Promise<ArrayBuffer> {
            val result = Promise<ArrayBuffer> {
                resolve, reject ->
                val body = FormData()
                body.append("glrs-wasm", "read")
                window.fetch("assets.php",
                    RequestInit(
                        method = "POST",
                        body = body)).then({
                    it.arrayBuffer()
                }).then({
                    resolve(it)
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result
        }
    }
}

// vi: se ts=4 sw=4 et: 
