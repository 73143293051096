package net.oc_soft.mswp.line

/**
 * helper class create line message 
 */
class Message {


    /**
     * class instance
     */
    companion object {
        /**
         * create message for sharing
         */
        fun createForSharing(
            imageUri: String,
            message: String,
            action: line.Action,
            wrapText: Boolean = true,
            imageAnimated: Boolean = false,
            textDirection: String = "ltr"): line.Message? {

            val hero = line.createFlexImage(
                url = imageUri,
                action = action,
                animated = imageAnimated) 

            val bodyContents = line.createFlexText(
                text = message,
                wrap = wrapText,
                action = action)

            @Suppress("UNCHECKED_CAST")
            val body = line.createFlexBox(
                layout = "vertical",
                contents = arrayOf(
                    bodyContents as line.FlexComponent),
                action = action) 

            val bubbleContainer = line.createFlexBubbleContainer(
                hero = hero,
                body = body,
                action = action,
                direction = textDirection)
            val result = line.createFlexMessage(
                "mine sweeper message",
                bubbleContainer) 
            return result 
        }
    }


}

// vi: se ts=4 sw=4 et:
