package net.oc_soft.mswp.ui

import org.khronos.webgl.*
import org.khronos.webgl.WebGLUniformLocation
import net.oc_soft.mswp.*

/**
 * button to cover mine
 */
@Suppress("UNUSED_PARAMETER")
class MineButton(
    colorScheme: ColorScheme,
    colorMaterialConvert: ColorMaterialConvert = ColorMaterialConvert(
        1f, 0.01f, 0.05f, 0.8f),
    buttonSize : FloatArray = floatArrayOf(1f, 1f),
    thickness: Float = 0.001f) {


   
    /**
     * cache of vertices
     */
    private var verticesCache : FloatArray? = null
   
    /**
     * vertices
     */
    val vertices : FloatArray
        get() {
            if (verticesCache == null) {
                verticesCache = createPolygons(
                    polygonFactor[0].toInt(), polygonFactor[1].toInt())
            }
            return verticesCache!!
        }
    
    val verticesAsFloat32 : Float32Array
        get() {
            val vertices = this.vertices 
            val result = Float32Array(Array<Float>(vertices.size) {
                i -> vertices[i]
            })
            return result
        }
    
    /**
     * texture coordinates
     */
    var textureCoordinatesCache : FloatArray? = null

    /**
     * texture coordinates
     */
    val textureCoordinates : FloatArray
        get() {
            if (textureCoordinatesCache == null) {
                textureCoordinatesCache = createTextureCoordinates(
                    polygonFactor[0].toInt(), polygonFactor[1].toInt())
            }
            return textureCoordinatesCache!!
        }

    val textureCoordinatesAsFloat32 : Float32Array
        get() {
            val textureCoordinates = this.textureCoordinates
            val result = Float32Array(Array<Float>(textureCoordinates.size) {
                textureCoordinates[it]
            })
            return result
        }
     
    /**
     * normal vector's cache
     */
    private var normalVecCache : FloatArray? = null
    
    /**
     * normal vectors
     */
    val normalVectors : FloatArray
        get() {
            if (normalVecCache == null) {
                normalVecCache = createNormalVectors()
            }
            return normalVecCache!!
        }
    
    val normalVectorsAsFloat32 : Float32Array
        get() {
            val normalVectors = this.normalVectors
            return Float32Array(
                Array<Float>(normalVectors.size) { 
                   i -> normalVectors[i] 
                })
        } 

    /**
     * vertex colors
     */
    val verticesSpecular: FloatArray
        get() {
            return createColorVertices(this.frontSpecular, this.backSpecular)
        }
    val verticesSpecularAsFloat32 : Float32Array
        get() {
            val verticesSpecular = this.verticesSpecular
            val result = Float32Array(Array<Float>(verticesSpecular.size) {
                i -> verticesSpecular[i]
            })
            return result 
        }

    /**
     * vertex ambient
     */
    val verticesAmbient: FloatArray
        get() {
            return createColorVertices(this.frontAmbient, this.backAmbient)
        }
    val verticesAmbientAsFloat32 : Float32Array
        get() {
            val verticesAmbient = this.verticesAmbient
            val result = Float32Array(Array<Float>(verticesAmbient.size) {
                verticesAmbient[it]
            })
            return result 
        }

    /**
     * vertex diffuse 
     */
    val verticesDiffuse: FloatArray
        get() {
            return createColorVertices(this.frontDiffuse, this.backDiffuse)
        }
    val verticesDiffuseAsFloat32 : Float32Array
        get() {
            val verticesDiffuse = this.verticesDiffuse
            val result = Float32Array(Array<Float>(verticesDiffuse.size) {
                verticesDiffuse[it]
            })
            return result 
        }

    /**
     * vertex shiness 
     */
    val verticesShiness: FloatArray
        get() {
            val vertices = this.vertices
            val shiness  = floatArrayOf(this.frontShiness, this.backShiness)
            var faceVertexCount = vertices.size / 2 
            faceVertexCount /= 3
            return FloatArray(faceVertexCount * shiness.size) {
                val idx = it / faceVertexCount
                shiness[idx] 
            }
        }
    val verticesShinessAsFloat32 : Float32Array
        get() {
            val verticesShiness = this.verticesShiness
            val result = Float32Array(Array<Float>(verticesShiness.size) {
                verticesShiness[it]
            })
            return result 
        }


    /**
     * drawing mode
     */
    val drawingMode = WebGLRenderingContext.TRIANGLES
  
    /**
     * texture index
     */ 
    val textureIndex0 : Int
        get() {
            return Textures.ButtonTextureIndex 
        }
    /**
     * polygon factor
     */
    val polygonFactor = shortArrayOf(1, 1)
    /**
     * button size
     */
    val buttonSize : FloatArray = floatArrayOf(1f, 1f)
    /**
     * thicness of button
     */
    val thickness : Float = thickness

    /**
     * front face color
     */
    var frontSpecular: FloatArray
        get() {
            return frontMaterial.specular
        }
        set(value) {
            frontMaterial.specular = value
        }

    /**
     * back bace color
     */
    var backSpecular: FloatArray
        get() {
            return backMaterial.specular
        }
        set(value) {
            backMaterial.specular = value
        }

    /**
     * front face ambient
     */
    var frontAmbient: FloatArray
        get() {
            return frontMaterial.ambient
        }
        set(value) {
            frontMaterial.ambient = value
        }

    /**
     * back bace ambient 
     */
    var backAmbient: FloatArray
        get() {
            return backMaterial.ambient
        }
        set(value) {
            backMaterial.ambient = value
        }

    /**
     * front face diffuse
     */
    var frontDiffuse: FloatArray
        get() {
            return frontMaterial.diffuse
        }
        set(value) {
            frontMaterial.diffuse = value
        }

    /**
     * back face diffuse 
     */
    var backDiffuse: FloatArray
        get() {
            return backMaterial.diffuse
        }
        set(value) {
            backMaterial.diffuse = value
        }

    /**
     * front face shiness 
     */
    var frontShiness: Float
        get() {
            return frontMaterial.shiness
        }
        set(value) {
            frontMaterial.shiness = value
        }

    /**
     * back face shiness 
     */
    var backShiness: Float
        get() {
            return backMaterial.shiness
        }
        set(value) {
            backMaterial.shiness = value
        }

    /**
     * color material converter
     */
    var colorMaterialConvert: ColorMaterialConvert = colorMaterialConvert

    /**
     * front material
     */
    var frontMaterial: Material = colorMaterialConvert(
        colorScheme[ColorScheme.ButtonFront])

    /**
     * back material
     */
    var backMaterial: Material = colorMaterialConvert(
        colorScheme[ColorScheme.ButtonBack])

 
    /**
     * update color
     */
    fun updateColorScheme(colorScheme: ColorScheme) {

        val frontColor = colorScheme[ColorScheme.ButtonFront]
        val backColor = colorScheme[ColorScheme.ButtonBack]

        frontMaterial = colorMaterialConvert(frontColor) 
        backMaterial = colorMaterialConvert(backColor)
    }


    /**
     * create triangle polygons
     */
    fun createPolygons(xDivider: Int, yDivider: Int): FloatArray {
        val thickness = buttonSize.minOrNull()!! * this.thickness
        val frontV = Polygon.divideSquare2(buttonSize, 
            xDivider, yDivider, false, thickness)
        val backV = Polygon.divideSquare2(buttonSize, 
            xDivider, yDivider, true) 
        val result = frontV.copyOf(frontV.size + backV.size)
        backV.forEachIndexed({ i, elem -> result[frontV.size + i] = elem })   
        return result
    }

    /**
     * create texture coordinates
     */
    fun createTextureCoordinates(xDivider : Int, yDivider : Int): FloatArray {
        val texSize = floatArrayOf(1f, .5f)
        val textures = arrayOf(
            Polygon.divideSquare2d2(texSize, xDivider, yDivider),
            Polygon.divideSquare2d2(texSize, xDivider, yDivider, true))
       

        val offset = arrayOf(
            floatArrayOf(.5f, .5f + .25f),
            floatArrayOf(.5f, .25f))


        val result = FloatArray(textures[0].size + textures[1].size) {
            val texIdx0 = it / textures[0].size
            val texIdx1 = it % textures[0].size
            val offsetIdx = it % offset[0].size
            textures[texIdx0][texIdx1] + offset[texIdx0][offsetIdx]  
        }

        return result
    }    
    
    /**
     * create normal vectors
     */
    fun createNormalVectors(): FloatArray {
        return Polygon.createNormalVectorsForTriangles(vertices)
    }


    /**
     * setup for rendering
     */
    fun setup(grid: Grid,
        gl: WebGLRenderingContext) {

        setupVertices(grid, gl)
        setupNormalVector(grid, gl)
        setupTextureCoordinate(grid, gl)
        setupMaterial(grid, gl)
    }


    /**
     * setup vertices
     */
    fun setupVertices(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.buttonBuffer = createVerticesBuffer(gl)

    }
    /**
     * setup normal vector 
     */
    fun setupNormalVector(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.buttonNormalVecBuffer = createNormalVectorBuffer(gl)
    }

    /**
     * setup texture coordinate
     */
    fun setupTextureCoordinate(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.buttonTextureCoordinatesBuffer =
            createTextureCoordinateBuffer(gl)
 
    }
     
    /**
     * setup material
     */
    fun setupMaterial(grid: Grid,
        gl: WebGLRenderingContext) {
        updateMaterial(grid, gl)
    }
    
    /**
     * update button material
     */
    fun updateMaterial(
        grid: Grid,
        gl: WebGLRenderingContext) {

        val renderingCtx = grid.renderingCtx
        val specularBuffer = gl.createBuffer()
        if (specularBuffer != null) {
            attachBufferData(gl, specularBuffer, verticesSpecularAsFloat32)
        }
        val ambientBuffer = gl.createBuffer()
        if (ambientBuffer != null) {
            attachBufferData(gl, ambientBuffer, verticesAmbientAsFloat32)
        }
        val diffuseBuffer = gl.createBuffer()
        if (diffuseBuffer != null) {
            attachBufferData(gl, diffuseBuffer, verticesDiffuseAsFloat32)
        }
        val shinessBuffer = gl.createBuffer()
        if (shinessBuffer != null) {
            attachBufferData(gl, shinessBuffer, verticesShinessAsFloat32)
        }
           
        renderingCtx.setButtonMaterialbuffer(gl,
            specularBuffer, ambientBuffer, diffuseBuffer, shinessBuffer)
    }

    /**
     * create vertices
     */
    fun createVerticesBuffer(gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result, verticesAsFloat32)
        }
        return result
    }

    /**
     * create vertices
     */
    fun createNormalVectorBuffer(gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result, normalVectorsAsFloat32)
        }
        return result
    }

    /**
     * create texture coordinate buffer
     */
    fun createTextureCoordinateBuffer(gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result, textureCoordinatesAsFloat32)
        }
        return result
     }


    /**
     * attach buffer data
     */
    fun attachBufferData(gl: WebGLRenderingContext,
        buffer: WebGLBuffer,
        bufferData: Float32Array) {

        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, buffer)
        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            bufferData,
            WebGLRenderingContext.STATIC_DRAW)

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer)
    }

    /**
     * create color vertices
     */
    internal fun createColorVertices(
        frontColor: FloatArray,
        backColor: FloatArray): FloatArray {
        val vertices = this.vertices
        val compCount = kotlin.math.min(frontColor.size, backColor.size)
        val colors = arrayOf(
            frontColor.copyOf(compCount), 
            backColor.copyOf(compCount))
        var faceVertexCount = vertices.size / 2 
        faceVertexCount /= 3
        val faceCompCount = faceVertexCount * colors[0].size 
        return FloatArray(faceCompCount * colors.size) {
            val idx = it / faceCompCount
            colors[idx][it % colors[0].size] 
        }
    }
}
// vi: se ts=4 sw=4 et:
