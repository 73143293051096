package net.oc_soft.mswp.ui

import kotlin.collections.MutableList
import kotlin.collections.ArrayList
import kotlin.text.replaceFirstChar
import kotlin.text.titlecaseChar

/**
 * effect something to game scene.
 */
class Effector {

    /**
     * gaussian blur data
     */
    class GaussianBlur(
        /**
         * standard deviation
         */
        val standardDeviation: Float = 1f,
        /**
         * sample size
         */
        val size: Int = 3
    ) {  
        override fun hashCode(): Int {
            var result: Int
            result = 0
            result = result xor standardDeviation.hashCode()
            result = result xor size
            return result
        }
        
        override fun equals(other: Any?): Boolean {
            var result = this === other
            if (!result) {
                result = other is GaussianBlur
                if (result) {
                    val otherBlur = other as GaussianBlur
                    result = standardDeviation == otherBlur.standardDeviation
                    if (result) {
                        result = size == otherBlur.size 
                    }
                }
            }
            return result
        }
    }

    /**
     * post processing data
     */
    class PostRendering(
        blur: GaussianBlur) {

        /**
         * blur property
         */
        var blur: GaussianBlur = blur
            set(value) {
                if (field != value) {
                    field = value
                    notify("blur")
                }
            }
        /**
         * event listeners
         */
        private val listeners: MutableList<
            ((sender: PostRendering, propertyName:String)->Unit)> = ArrayList()

        /**
         * add event listener
         */
        fun addListener(
            listener: ((sender: PostRendering, propertyName: String)->Unit)) {
            listeners.add(listener)
        }

        /**
         * remove event listener
         */
        fun removeListener(
            listener: ((sender: PostRendering, propertyName: String)->Unit)) {
            listeners.remove(listener)
        }

        /**
         * notify event
         */
        private fun notify(propertyName: String) {
            val sender = this
            listeners.forEach { it(sender, propertyName) }
        }
    } 
     

    /**
     * post rendering option
     */
    val postRendering: PostRendering = PostRendering(GaussianBlur())
     

    /**
     * event listeners
     */
    private val listeners: MutableList<
        ((sender: Effector, propertyName:String)->Unit)> = ArrayList()


    /**
     * event listener for post-rendering
     */
    private var postRenderingListener: 
        ((sender: PostRendering, propertyName: String)->Unit)? = null



    /**
     * activate event notification
     */
    var activateEventNotification
        get() = postRenderingListener != null

        set(value: Boolean) {
            val oldValue = activateEventNotification 
            if (oldValue != value) {
                if (oldValue) {
                    postRendering.removeListener(postRenderingListener!!) 
                    postRenderingListener = null
                }
                if (value) {
                    postRenderingListener = { 
                        sender, propertyName ->
                        onChangedPostRendering(sender, propertyName)
                    } 
                    postRendering.addListener(postRenderingListener!!)
                }
            }
        }


    /**
     * add event listener
     */
    fun addListener(
        listener: ((sender: Effector, propertyName: String)->Unit)) {
        listeners.add(listener)
    }

    /**
     * remove event listener
     */
    fun removeListener(
        listener: ((sender: Effector, propertyName: String)->Unit)) {
        listeners.remove(listener)
    }

    /**
     * notify event
     */
    private fun notify(propertyName: String) {
        val sender = this
        listeners.forEach { it(sender, propertyName) }
    }


    /**
     * handle post-rendering event
     */
    @Suppress("UNUSED_PARAMETER")
    private fun onChangedPostRendering(
        sender: PostRendering, propertyName: String) {
        val capName = propertyName.replaceFirstChar { it.titlecaseChar() }
        notify("postRendering${capName}")
    }

}

// vi: se ts=4 sw=4 et:
