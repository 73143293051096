package net.oc_soft.mswp.ui

import net.oc_soft.ui.Dropdown

import kotlinx.browser.document
import kotlinx.browser.window
import kotlin.js.Promise
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.coroutines.MainScope
import kotlin.text.lastIndexOf
import kotlin.text.substring


import org.w3c.files.Blob
import org.w3c.files.FileReader
import org.w3c.xhr.FormData
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.dom.url.URL
import org.w3c.dom.Location
import org.w3c.dom.events.Event

import net.oc_soft.mswp.settings.LineSession
import net.oc_soft.mswp.storage.Session
import net.oc_soft.mswp.storage.UserStorage
import net.oc_soft.mswp.line.ui.Message as LineUiMessage
import net.oc_soft.mswp.line.Message as LineMessage
import net.oc_soft.mswp.Logic

/**
 * share other friend the game by sns or email
 */
class Share(val option: Option)  {


    /**
     * class instance
     */
    companion object {
        /**
         * generage game id
         */
        fun generateGameId(): Promise<String?> {
            val result = Promise<String?> {
                resolve, reject ->
                val body = FormData()
                body.append("assign-game-id", "")

                window.fetch(
                    "sharing.php",
                    RequestInit(
                        method = "POST",
                        body = body
                )).then({
                    it.json()
                }).then({
                    val dataMap: dynamic = it 
                    val gameId = dataMap["game-id"]
                    var res: String? = null
                    if (gameId != null) {
                        res = gameId.toString()
                    }
                    resolve(res) 
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result
        }
 
        /**
         * save game setting and get game id
        */
        fun saveGameSetting(logic: Logic): Promise<String?> {
            val result = Promise<String?> {
                resolve, reject ->
                Persistence.saveLogic(logic).then({
                    val gameIdObj = it["game-id"]   
                    var gameId: String? = null
                    if (gameIdObj is String) {
                        gameId = gameIdObj as String 
                    } 
                    var res: String? = null
                    if (gameId != null) {
                        res = gameId.toString()
                    }
                    resolve(res) 
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            }
            return result
        }


        /**
         * copy current game setting
         */
        fun copySetting(gameId: String): Promise<Unit> {
            val result = Promise<Unit> {
                resolve, reject ->
                val body = FormData()
                body.append("duplicate-game", "")
                body.append("game-id", gameId)
                window.fetch(
                    "sharing.php",
                    RequestInit(
                        method = "POST",
                        body = body
                )).then({
                    resolve(Unit)
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            } 
            return result 
        }  
        
        /**
         * delete game setting
         */
        fun deleteGameSetting(gameId: String): Promise<Unit> {
            val result = Promise<Unit> {
                resolve, reject ->
                val body = FormData()
                body.append("delete-game", gameId)
                window.fetch(
                    "sharing.php",
                    RequestInit(
                        method = "POST",
                        body = body
                )).then({
                    resolve(Unit)
                    Unit
                }).catch({
                    reject(Throwable(it))
                })
            } 
            return result 
        }
    }

    /**
     * option for share
     */
    data class Option(
        /**
         * html node query for share button
         */
        val sharing: String,
        /**
         * html node query for drop down
         */
        val dropdown: String,
        /**
         * html node query for share by line.
         */
        val shareByLine: String,
        /**
         * html node query for login to line
         */
        val loginLine: String,
        /**
         * html node query for logout from line
         */
        val logoutLine: String,
        /**
         * line ui mesasage option
         */
        val lineUiOption: LineUiMessage.Option) 


    /**
     * manage dropdown operation
     */
    val dropdown = Dropdown()
    
    /**
     * share setting by line
     */
    var lineSharingHandler: ((Event)->Unit)? = null

    /**
     * log in LINE
     */
    var lineLoginHandler: ((Event)->Unit)? = null

    /**
     * log out LINE
     */
    var lineLogoutHandler: ((Event)->Unit)? = null


    /**
     * dropdown visibility handler
     */
    var dropdownVisibilityHandler: ((String, Dropdown)->Unit)? = null

    /**
     * application settings
     */
    var appSettings: AppSettings? = null
    
    /**
     * visibility line login ui
     */
    var visibleLineLoginUi: Boolean?
        get() {
            return document.querySelector(option.loginLine)?.let {
                !it.classList.contains("hidden")  
            }
        }
        set(value) {
            value?.let {
                if (it != visibleLineLoginUi) {
                    document.querySelector(option.loginLine)?.let {
                        it.classList.toggle("hidden")  
                    }
                }
            }
        }



    /**
     * visibility line logout ui
     */
    var visibleLineLogoutUi: Boolean?
        get() {
            return document.querySelector(option.logoutLine)?.let {
                !it.classList.contains("hidden")  
            }
        }
        set(value) {
            if (value != null) {
                if (value != visibleLineLogoutUi) {
                    document.querySelector(option.logoutLine)?.let {
                        it.classList.toggle("hidden")  
                    }
                }
            }
        }

    /**
     * visibility line sharing ui
     */
    var visibleLineSharingUi: Boolean?
        get() {
            return document.querySelector(option.shareByLine)?.let {
                it.classList.contains("hidden")  
            }
        }
        set(value) {
            if (value != null) {
                if (value != visibleLineSharingUi) {
                    document.querySelector(option.shareByLine)?.let {
                        it.classList.toggle("hidden")  
                    }
                }
            }
        }

    /**
     * get screen image
     */
    val screenImage: Blob?
        get() {
            var result: Blob? = null
            val appSettings = this.appSettings
            if (appSettings != null) {
                val capture = appSettings.capture
                if (capture != null) {
                    result = capture.screen()
                } 
            }
            return result
        }


    /**
     * bind html element and this event handler
     */
    fun bind(appSettings: AppSettings) {
        this.appSettings = appSettings

        lineSharingHandler = { onLineSharing(it) }
        lineLogoutHandler = { onLineLogout() }

        lineLoginHandler = { onLineLogin() }

        dropdownVisibilityHandler = { _, _ ->  onVisibleDropdown() }
        document.querySelector(option.shareByLine)?.let {
            it.addEventListener("click", lineSharingHandler!!)
        }
        document.querySelector(option.loginLine)?.let {
            it.addEventListener("click", lineLoginHandler!!)
            it.addEventListener("click", lineLogoutHandler!!)
        }
        dropdown.bind(option.sharing, option.dropdown)
        dropdown.addListener("visible", dropdownVisibilityHandler!!)
 
     }


    /**
     * unbind html element and ths event handler
     */
    fun unbind() {
        dropdown.unbind()

        dropdownVisibilityHandler?.let {
            dropdown.removeListener("visible", it) 
            dropdownVisibilityHandler = null
        }
        lineLogoutHandler?.let  {
            val hdlr = it
            document.querySelector(option.logoutLine)?.let {
                it.removeEventListener("click", hdlr)
            }
            lineLogoutHandler = null
        }
        lineLoginHandler?.let {
            val hdlr = it
            document.querySelector(option.loginLine)?.let {
                it.removeEventListener("click", hdlr)
            }
            lineLoginHandler = null
        }
        lineSharingHandler?.let {
            val hdlr = it
            document.querySelector(option.shareByLine)?.let {
                it.removeEventListener("click", hdlr)
            }
            lineSharingHandler = null
        }
        this.appSettings = null
    }

    /**
     * handle line sharing event
     */
    fun onLineSharing(event: Event) {
        startSharingWithLineLogin() 
    }



    /**
     * login, initialize liff and share
     */
    fun startSharingWithLineLogin() {
        if (line.liff.id != null) {
            if (!line.liff.isLoggedIn()) {
                saveLineSession()
                line.liff.login()
            }
            if (line.liff.isLoggedIn()) {
                shareWithLine0() 
            }
        } else {
            lineLiffInit().then {
                startSharingWithLineLogin()
            }
        } 
    }

    /**
     * line init liff
     */
    fun lineLiffInit(): Promise<Unit> {
        val result = Promise<Unit> {
            resolve, reject ->
            val appSettings = this.appSettings
            if (appSettings != null) {
                val liffId = appSettings.liffId
                if (liffId != null) { 
                    line.liff.init(object: line.liff.Config {
                        override val liffId: String = liffId
                    }).then({
                        resolve(Unit)
                    }).catch({
                        reject(it)
                    })
                } else {
                    reject(Throwable("no liff id"))
                }
            } else {
               reject(Throwable("no application setting")) 
            }
        } 
        return result
    }

    /**
     * save game state and run
     */
    fun saveStateAndRun(command: ()->Unit) {
        command()
    }

    /**
     * save line session
     */
    fun saveLineSession() {
    }

    /**
     * share game with line
     */
    private fun shareWithLine0() {
        val screenImage = this.screenImage
        if (screenImage != null) {
            UserStorage.saveStorage(screenImage, "png").then({
                val storageId = it
                
                val imagePath = URL(
                    "storage.php?read=${storageId}&fallback=png"
                    + "&file-name=no-image-black-1024.png",
                    URL(window.location.basePath, window.location.origin).href)
                confirmMessageAndShareLine(
                    imagePath.href,
                    screenImage,
                    {
                        UserStorage.updateGameId(storageId, it) 
                    },
                    {
                        UserStorage.deleteStorage(storageId)
                    }) 
            }).catch({
            
            })
        }
    }

    /**
     *  confirm message and share the game
     */
    private fun confirmMessageAndShareLine(
        imageUrl: String,
        imageBlob: Blob,
        resourceUpdate: (String)->Promise<Unit>,
        resourceDelete: ()->Unit) {

        val messageUi = LineUiMessage(option.lineUiOption)

        val uiHandler = Array<((String, LineUiMessage)->Unit)?>(1) {
            null
        }
        
        uiHandler[0] = {
            kind, sender ->
            when (kind) {
                "sendMessage" -> { 
                    saveAndShareWithLine1(imageUrl,
                        sender.messageUi, 
                        resourceUpdate,
                        resourceDelete)
                    sender.removeEventListener(uiHandler[0]!!)
                }
                else -> {
                    resourceDelete() 
                    sender.removeEventListener(uiHandler[0]!!)
                }
            }
        }

        messageUi.show(LineUiMessage.ShowingOption(imageUrl))
        messageUi.addEventListener(uiHandler[0]!!)
    }

    /**
     * asign game id, copy game setting and share it.
     */ 
    private fun saveAndShareWithLine1(
        imageUrl: String,
        message: String?,
        resourceUpdate: (String)->Promise<Unit>,
        resourceDelete: ()->Unit) {

        val appSettings = this.appSettings
        var logic: Logic? = null
        if (appSettings != null) {
            logic = appSettings.logic
        }
        if (logic != null) {
            val scope = MainScope()
            scope.launch {
                var srcGameId = saveGameSetting(logic).await()
                var gameId: String? = null
                if (srcGameId != null) {
                    gameId = generateGameId().await()
                }

                if (gameId != null) {
                    resourceUpdate(gameId).await()
                    copySetting(gameId).await()
                    shareWithLine1(
                        gameId, imageUrl, message,
                        resourceDelete)
                } else {
                    resourceDelete()
                }
            }
        }
    }

    /**
     * share game
     */
    private fun shareWithLine1(
        gameId: String,
        imageUrl: String,
        message: String?,
        resourceDelete: ()->Unit) {
        val liffId = appSettings!!.liffId!!
        val url = line.liff.URL.endPointUrl(liffId,
            null,
            mapOf("game-id" to gameId))

        val lineMessage = LineMessage.createForSharing(
            imageUrl, 
            if (message != null) { message } else { "" },
            line.createURIAction(null, url))
        val textMessage = line.createTextMessage(
            if (message != null) { message } else { "" })

        if (lineMessage != null) {
            line.liff.shareTargetPicker(arrayOf(lineMessage!!)).then({
                if (it != null) {
                    showPayment()
                } else {
                    cancelSharingGame(gameId)
                }
            }).catch({
                cancelSharingGame(gameId)
            })
        }
    }
   

    /**
     * show payment dialog
     */
    private fun showPayment() {
    }

    /**
     * share game
     */
    private fun doShareGame(
        gameId: String,
        expiration: Int) {
        
    }

    /**
     * cancel sharing game
     */
    private fun cancelSharingGame(gameId: String) {
        deleteGameSetting(gameId) 
    }

    /**
     * login handler
     */
    fun onLineLogin() {
        startSharingWithLineLogin()
        // shareWithLine0() 
    }

    /**
     * handle the event to log into LINE
     */
    fun onLineLogin0() {
        if (!line.liff.isLoggedIn()) {
            lineLiffInit().then({
                line.liff.login()
            })
        }
    }
    

    /**
     * handle log out LINE
     */
    fun onLineLogout() {
        if (line.liff.isLoggedIn()) {
            line.liff.logout()
        }

    }


    /**
     * visible drop down
     */
    private fun onVisibleDropdown() {
        syncLineItemsOnDropdown()
    }


    /**
     * synchronize logout menu item on dropdown with line login status
     */
    private fun syncLineItemsOnDropdown() {
        if (line.liff.id != null) {
            if (!line.liff.isInClient()) {
                val loggedIn = line.liff.isLoggedIn()
                visibleLineLogoutUi = loggedIn
                visibleLineSharingUi = loggedIn
                visibleLineLoginUi = !loggedIn
            } else {
                visibleLineLogoutUi = false
                visibleLineLoginUi = false
                visibleLineSharingUi = true
            }
        } else {
            lineLiffInit().then {
                syncLineItemsOnDropdown()
            }
        }
    }
}

/**
 * extension to find base path
 */
val Location.basePath: String
    get() {
        val idx = this.pathname.lastIndexOf("/")
        val result = if (idx > 0) {
            this.pathname.substring(0, idx + 1) 
        } else {
            "/"  
        }
        return result
    }
// vi: se ts=4 sw=4 et:
