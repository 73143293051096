package net.oc_soft.mswp.ui

import kotlin.math.min
import kotlin.math.ceil

import org.khronos.webgl.WebGLRenderingContext

import net.oc_soft.mswp.ui.grid.Buttons
import net.oc_soft.mswp.Matrix
/**
 * managing animation
 */
class Animation(
    val openingButton: OpeningButton = OpeningButton(),
    val framePerSec: Float = 40f) {

    companion object {

        /**
         * setup buttons at begining of bame
         */
        private fun startButtonsBlinking(
            renderingCtx: RenderingCtx) {
            val hintButtonBlinking = renderingCtx.hintButtonBlinking
            if (hintButtonBlinking != null) {
                hintButtonBlinking.active = true    
            }
        }


        /**
         * animate buttons blinking
         */
        private fun doButtonsBlinking(
            gl: WebGLRenderingContext, 
            renderingCtx: RenderingCtx) {
            val hintButtonBlinking = renderingCtx.hintButtonBlinking
            if (hintButtonBlinking != null) {
                val colorBuffer = renderingCtx.hintButtonColorBuffer
                if (colorBuffer != null) {
                    hintButtonBlinking.nextFrame(gl, colorBuffer)
                }
            }
        }

        /**
         * upate button glowing buffer with current frame
         */ 
        private fun updateButtonsGlowingBufferWithCurrentFrame(
            gl: WebGLRenderingContext,
            renderingCtx: RenderingCtx) {
            val hintButtonBlinking = renderingCtx.hintButtonBlinking
            if (hintButtonBlinking != null) {
                val colorBuffer = renderingCtx.hintButtonColorBuffer
                if (colorBuffer != null) {
                    hintButtonBlinking.syncColorsWithColorIndex(gl, colorBuffer)
                }
            }
        }

        /**
         * stop buttons blinking lately
         */
        @Suppress("UNUSED_PARAMETER")
        private fun postStopButtonsBlinking(
            gl: WebGLRenderingContext,
            renderingCtx: RenderingCtx) {
            val hintButtonBlinking = renderingCtx.hintButtonBlinking
            if (hintButtonBlinking != null) {
                hintButtonBlinking.stopAtDark()
            }
        }

        /**
         * you get true if buttons blinking is running
         */
        private fun isButtonsBlinking(
            renderingCtx: RenderingCtx): Boolean {
            val hintButtonBlinking = renderingCtx.hintButtonBlinking
            return if (hintButtonBlinking != null) {
                hintButtonBlinking.active
            } else {
                false
            }
        }
    }

    /**
     * blinking buttons animation id
     * this is used by request animation
     */
    var buttonsBlinkingId: Int? = null

    /**
     * opening buttons animation id
     */
    var openingButtonsId: Int? = null


    var openingButtonDuration: Float 
        get() = openingButton.openingDuration

        set(value) {
             openingButton.openingDuration = value
        }
    /**
     * millisecond / frame
     */
    val frameDuration: Double
        get() = 1000f / framePerSec.toDouble()
        

    /**
     *  calculate blinking gradation count
     */
    fun calcBlinkingGradationCount(
        duration: Float): Int {
        return ceil(framePerSec * duration).toInt()
    }

    /**
     * setup buttons at begining of bame
     */
    fun startButtonsBlinking(
        renderingCtx: RenderingCtx) {
        Animation.startButtonsBlinking(renderingCtx)
    }

    /**
     * animate buttons blinking
     */
    fun doButtonsBlinking(
        gl: WebGLRenderingContext, 
        renderingCtx: RenderingCtx) {
        Animation.doButtonsBlinking(gl, renderingCtx)
    }
    /**
     * upate button glowing buffer with current frame
     */ 
    fun updateButtonsGlowingBufferWithCurrentFrame(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        Animation.updateButtonsGlowingBufferWithCurrentFrame(
            gl, renderingCtx)
    }

    /**
     * stop buttons blinking lately
     */
    fun postStopButtonsBlinking(
        gl: WebGLRenderingContext,
        renderingCtx: RenderingCtx) {
        Animation.postStopButtonsBlinking(gl, renderingCtx)
    }

    /**
     * you get true if buttons blinking is running
     */
    fun isButtonsBlinking(
        renderingCtx: RenderingCtx): Boolean {
        return Animation.isButtonsBlinking(renderingCtx)
    }

    /**
     * You get true if the game has a button in opening motion.
     */
    @Suppress("UNUSED_PARAMETER")
    fun isOpeningButtons(
        renderingCtx: RenderingCtx): Boolean {
        return openingButton.hasNextFrame()
    }

    /**
     * create matrices for each frame
     */
    fun setupButtons(buttons : Buttons,
        openedButtonIndices: Array<IntArray>,
        buttonIndices : Array<IntArray>,
        renderingCtx : RenderingCtx) {
        openingButton.setupButtons(
            buttons, openedButtonIndices, buttonIndices, renderingCtx)
    }
    /**
     * start animation if rendering context has animation matrices
     */
    fun doAnimate(
        gl: WebGLRenderingContext,
        renderingCtx : RenderingCtx) {
        if (isButtonsBlinking(renderingCtx)) {
            doButtonsBlinking(gl, renderingCtx) 
        }  

        openingButton.doAnimate(gl, renderingCtx)
    }

    /**
     * You get true if rendering context has next frame to animate.
     */
    fun hasNextFrame(renderingCtx: RenderingCtx): Boolean {
        var result = isOpeningButtons(renderingCtx)
        if (!result) {
            result = isButtonsBlinking(renderingCtx)
        }
        return result
    }
    
}

// vi: se ts=4 sw=4 et:
