
package net.oc_soft.ui

import kotlinx.browser.document
import kotlin.collections.MutableList
import kotlin.collections.ArrayList
import kotlin.collections.MutableMap
import kotlin.collections.HashMap

import org.w3c.dom.HTMLElement
import org.w3c.dom.Element
import org.w3c.dom.events.Event
import popper.Popper

/**
 * manage drop down user interface
 */
class Dropdown {

    /**
     * handle source clicked
     */
    var sourceClickHdlr: ((e: Event)->Unit)? = null

    /**
     * handle target clicked
     */
    var targetClickHdlr: ((e: Event)->Unit)? = null


    /**
     * handle drowdown background
     */
    var backgroundClickHdlr: ((e: Event)->Unit)? = null

    /**
     * dropdown query string
     */
    var dropdownQuery: String? = null

    /**
     * event source node
     */
    var sourceQuery: String? = null
    /**
     * dropdown target
     */
    val dropdownTarget: Element? 
        get() {
            return dropdownQuery?.let {
                document.querySelector(it)
            }

        }

    /**
     * visibility about dropdown
     */
    var visibledDropdown: Boolean? 
        get() {
            return dropdownTarget?.let {
                (it as HTMLElement).style.visibility != "hidden"
            }
        }
        set(value) {
            var doSet = false 
            val oldState = visibledDropdown
            if (oldState != null) {
                if (value != null) {
                    doSet = oldState != value
                } else {
                    doSet = true
                }
            } else {
                if (value != null) {
                    doSet = true
                }
            }
            if (doSet) {
                if (value != null) {
                    var css: String
                    if (value) {
                        dropdownTarget?.let {
                            val elem = it as HTMLElement
                            elem.style.visibility = "visible"
                            elem.style.setProperty("pointer-events", "auto")
                            notify("visible")
                        }
                    } else { 
                        dropdownTarget?.let {
                            val elem = it as HTMLElement
                            elem.style.visibility = "hidden"
                            elem.style.setProperty("pointer-events", "none")
                            notify("visible")
                        }
                    }
                }
            }
        }
    
    /**
     * popper instance
     */
    var popperInstance : popper.Instance? = null


    /**
     * event listeners
     */
    private val listeners: 
        MutableMap<String, MutableList<(String, Dropdown)->Unit>> = 
            HashMap<String, MutableList<(String, Dropdown)->Unit>>()

    /**
     * bind html node 
     */
    fun bind(eventSource: String, dropdownTarget: String) {
        
        

        document.querySelector(eventSource)?.let {
            val reference = it as HTMLElement
            sourceClickHdlr = { handleSourceNodeClick(it) }
            reference.addEventListener("click", sourceClickHdlr!!)
            sourceQuery = eventSource
            document.querySelector(dropdownTarget)?.let {
                val target = it as HTMLElement
                targetClickHdlr = { handleTargetNodeClick(it) }
                target.addEventListener("click", targetClickHdlr!!)
                val popperOption = popper.Css.readOption(target)
                popperInstance = Popper.createPopper(reference, target, 
                    popperOption as Any)
                dropdownQuery = dropdownTarget
                attachHandlerToHideDropdown()
            }
        }
    }

    /**
     * detach this object from html node
     */
    fun unbind() {
        detachHandlerToHideDropdown()
        if (targetClickHdlr != null) {
            document.querySelector(dropdownQuery!!)?.let {
                it.removeEventListener("click", targetClickHdlr!!)
            }
            targetClickHdlr = null
        }
        if (sourceClickHdlr != null) {
            document.querySelector(sourceQuery!!)?.let {
                it.removeEventListener("click", sourceClickHdlr!!)
            }
            sourceClickHdlr = null
        }
        popperInstance?.let {
            it.destroy()
            popperInstance = null
        }
        sourceQuery = null 
        dropdownQuery = null
     }

    /**
     * handle source node event
     */ 
    fun handleSourceNodeClick(event: Event) {
        visibledDropdown?.let {
            visibledDropdown = !it
        }
        event.stopPropagation()
    }
    
    /**
     * handle target node event
     */
    fun handleTargetNodeClick(event: Event) {
        event.stopPropagation()
    }

    /**
     * this procedure hide dropdown if user click some location.
     */
    fun attachHandlerToHideDropdown() {
        if (this.backgroundClickHdlr == null) {
            this.backgroundClickHdlr = { 
                this.visibledDropdown = false 
                Unit 
            }
            document.addEventListener("click",
                this.backgroundClickHdlr!!)
        }
    } 


    /**
     * this procedure hide dropdown if user click some location.
     */
    fun detachHandlerToHideDropdown() {
        this.backgroundClickHdlr?.let  {
            document.removeEventListener("click", it)
            this.backgroundClickHdlr = null 
        }
    } 

    /**
     * add listener
     */
    fun addListener(kind: String, listener: (String, Dropdown)->Unit) {
        var listeners = this.listeners[kind]

        if (listeners == null) {
            listeners = ArrayList<(String, Dropdown)->Unit>()
            this.listeners[kind] = listeners  
        }
        listeners.add(listener)
    }

    /**
     * remove listener
     */
    fun removeListener(kind: String, listener: (String, Dropdown)->Unit) {
        val listeners = this.listeners[kind]

        if (listeners != null) {
            listeners.remove(listener)
        }
    }


    /**
     * notify event
     */
    fun notify(kind: String) {
        val listeners = arrayOf(
            this.listeners[kind],
            this.listeners["any"])

        listeners.forEach {
            if (it != null) {
                it.forEach {
                    it(kind, this)
                }
            }
        }
    }

}
// vi: se ts=4 sw=4 et:
