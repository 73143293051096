package net.oc_soft.mswp.ui

/**
 * manage open gl shader programs
 */
class ShaderPrograms(
    val vertexShader : String,
    val fragmentShader : String,
    val locReaderVertexShader: String,
    val locReaderFragmentShader: String,
    val pointVertexShader: String,
    val pointFragmentShader: String,
    val depthVertexShader: String,
    val depthFragmentShader: String,
    val screenVertexShader: String,
    val screenFragmentShader: String,
    val glowVertexShader: String,
    val glowFragmentShader: String,
    val blurVertexShader: String,
    val blurFragmentShader: String) {
}

/* vi: se ts=4 sw=4 et: */
