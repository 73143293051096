package net.oc_soft.mswp.ui

import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLRenderbuffer
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.WebGLFramebuffer
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLUniformLocation
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get

/**
 * location reader
 */
class LocReader {


    /**
     * set up for gl
     */
    fun setup(grid: Grid,
        gl: WebGLRenderingContext) {
        setupWorkingFramebuffer(grid, gl)
    }

    /**
     * set up frame buffer for picking
     */
    fun setupWorkingFramebuffer(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.workableFramebuffer = gl.createFramebuffer() 
        val savedFramebuffer = gl.getParameter(
            WebGLRenderingContext.FRAMEBUFFER_BINDING) as
                WebGLFramebuffer?
  
        gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
            renderingCtx.workableFramebuffer) 
        setupRenderbufferForPicking(grid, gl)

        renderingCtx.buttonPickingColorBuffer =
            gl.createBuffer()
        renderingCtx.boardPickingColorBuffer =
            createBoardColorBufferForPicking(grid, gl) 
 
        gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
            savedFramebuffer) 
    }

    /**
     * boad color buffer for picking
     */
    private fun createBoardColorBufferForPicking(
        grid: Grid,
        gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, result)
            
            gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
                grid.board.createVerticesColor(floatArrayOf(0f,0f, 0f, 0f)),
                WebGLRenderingContext.STATIC_DRAW)
        }
        return result
    }


    /**
     * setup render buffer for picking
     */
    fun setupRenderbufferForPicking(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.pickingBuffer = createPickingbuffer(gl)
        renderingCtx.depthBufferForWorking = 
            createDepthBufferForWorking(gl) 
    }
 
    /**
     * create picking buffer
     */
    private fun createPickingbuffer(
        gl: WebGLRenderingContext): WebGLRenderbuffer? {
        val result = gl.createRenderbuffer()
        if (result != null) {

            val savedBuffer = gl.getParameter(
                WebGLRenderingContext.RENDERBUFFER_BINDING) 
                    as WebGLRenderbuffer?
 
            gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
                result)
            syncPickingBufferSizeWithSceneBufferI(gl)

            gl.framebufferRenderbuffer(WebGLRenderingContext.FRAMEBUFFER,
                WebGLRenderingContext.COLOR_ATTACHMENT0, 
                WebGLRenderingContext.RENDERBUFFER, result)
 
            gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
                savedBuffer) 
        }  
        return result
    }

    /**
     * synchronize buffer size with screen size
     */
    @Suppress("UNUSED_PARAMETER")
    internal fun syncSizeWithScreen(
        grid: Grid,
        gl: WebGLRenderingContext) {

    }

    /**
     * synchronize buffer size with screen size
     */
    internal fun syncSizeWithScene(
        grid: Grid,
        gl: WebGLRenderingContext) {
        syncPickingBufferSizeWithSceneBuffer(grid, gl)
        syncWorkingDepthBufferSizeWithSceneBuffer(grid, gl)
    }
  
    /**
     * synchronize picking buffer size with main scene buffer
     */  
    internal fun syncPickingBufferSizeWithSceneBuffer(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderBuffer = grid.renderingCtx.pickingBuffer
        if (renderBuffer != null) {
            syncPickingBufferSizeWithSceneBuffer(gl, renderBuffer) 
        }
    }
 
    /**
     * synchronize picking buffer size with main scene buffer
     */  
    private fun syncPickingBufferSizeWithSceneBuffer(
        gl: WebGLRenderingContext,
        renderBuffer: WebGLRenderbuffer) {
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.RENDERBUFFER_BINDING) 
                as WebGLRenderbuffer?
        gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
            renderBuffer)
        syncPickingBufferSizeWithSceneBufferI(gl)

        gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
            savedBuffer) 
    }
    /**
     * synchronize picking buffer size with main scene buffer
     */  
    internal fun syncPickingBufferSizeWithSceneBufferI(
        gl: WebGLRenderingContext) {

        val size = Scene.calcFramebufferSizeForScene(gl)

        gl.renderbufferStorage(WebGLRenderingContext.RENDERBUFFER,
            WebGLRenderingContext.RGB5_A1,
            size[0], size[1])
    }

    /**
     * create depth buffer for working
     */
    private fun createDepthBufferForWorking(
        gl: WebGLRenderingContext): WebGLRenderbuffer? {
        val result = gl.createRenderbuffer()
        if (result != null) {
            gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
                result)
            syncWorkingDepthbufferSizeWithSceneBufferI(gl)

            gl.framebufferRenderbuffer(WebGLRenderingContext.FRAMEBUFFER,
                WebGLRenderingContext.DEPTH_ATTACHMENT, 
                WebGLRenderingContext.RENDERBUFFER, result)
        }  
        return result
    }
    /**
     * synchronize the size of depth buffer for working  with scene buffer
     */
    internal fun syncWorkingDepthBufferSizeWithSceneBuffer(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx 
        val renderBuffer = renderingCtx.depthBufferForWorking
        if (renderBuffer != null) {
            syncWorkingDepthBufferSizeWithSceneBuffer(
                gl, renderBuffer)
        }

    }
 
    /**
     * synchronize the size of depth buffer for working  with scene buffer
     */
    private fun syncWorkingDepthBufferSizeWithSceneBuffer(
        gl: WebGLRenderingContext,
        renderBuffer: WebGLRenderbuffer) {
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.RENDERBUFFER_BINDING) 
                as WebGLRenderbuffer?
        gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
            renderBuffer)
        syncWorkingDepthbufferSizeWithSceneBufferI(gl)
        gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
            savedBuffer) 
          
    }

    /**
     * synchronize the size of depth buffer for working  with scene buffer
     */
    private fun syncWorkingDepthbufferSizeWithSceneBufferI(
        gl: WebGLRenderingContext) {

        val size = Scene.calcFramebufferSizeForScene(gl)

        gl.renderbufferStorage(WebGLRenderingContext.RENDERBUFFER,
            WebGLRenderingContext.DEPTH_COMPONENT16,
            size[0], size[1])
    }

    /**
     * find cell
     */
    fun findCell(
        grid: Grid,
        gl: WebGLRenderingContext,
        x: Int, y: Int): IntArray? {
        val renderingCtx = grid.renderingCtx
        val framebuffer = renderingCtx.workableFramebuffer
        var result: IntArray? = null
        if (framebuffer != null) {
            val savedFramebuffer = gl.getParameter(
                WebGLRenderingContext.FRAMEBUFFER_BINDING) as WebGLFramebuffer?

            val sceneWinCoord = grid.screen.windowToSceneWindowCoordinate(
                grid, gl, x, y)


            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                framebuffer)

            val buffer = Uint8Array(4)
            gl.readPixels(
                kotlin.math.round(sceneWinCoord[0]).toInt(), 
                kotlin.math.round(sceneWinCoord[1]).toInt(), 
                1, 1,
                WebGLRenderingContext.RGBA, 
                WebGLRenderingContext.UNSIGNED_BYTE, 
                buffer)
            

            val colorVal = ByteArray(buffer.length) { buffer[it] } 

            result = grid.buttons.findPositionByPickingColor(colorVal)
            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                savedFramebuffer)

        }
        return result 
    }
    

    /**
     * draw color picking scene
     */
    fun draw(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        val framebuffer = renderingCtx.workableFramebuffer
        if (framebuffer != null) {
            val savedFramebuffer = gl.getParameter(
                WebGLRenderingContext.FRAMEBUFFER_BINDING) as WebGLFramebuffer?

            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                framebuffer)

            draw0(grid, gl)
            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                savedFramebuffer)
        }
    }


    /**
     * setup for drawing scene
     */
    fun setupEnv(grid: Grid,
        gl: WebGLRenderingContext) {
        grid.setupEnv(gl, floatArrayOf(0f, 0f, 0f, 0f))
    }


    /**
     * draw location reader scene
     */
    fun draw0(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        val prog = renderingCtx.locReaderProgram
        if (prog != null) {
            val savedProgram = gl.getParameter(
                WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
            gl.useProgram(prog)
            draw1(grid, gl)
            gl.useProgram(savedProgram)
        }
    }

    /**
     * draw location reader scene
     */
    fun draw1(grid: Grid,
        gl: WebGLRenderingContext) {
        grid.attachCameraToProjectionMatrix(gl)        

        val prog = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        var uColorLoc: WebGLUniformLocation? = null
        if (prog != null) {
            uColorLoc = gl.getUniformLocation(prog, "uColor")
        }

        if (uColorLoc != null) {
            val buttons = grid.buttons
            val display = grid.display


            setupEnv(grid, gl)
            display.bindButtonVerticesBuffer(gl) 

            for (rowIndex in 0 until grid.rowCount) {
                for (colIndex in 0 until grid.columnCount) {
                    val color = buttons.getPickingColor(rowIndex, colIndex) 
                    gl.uniform4f(uColorLoc,
                        color[0], color[1], color[2], color[3])
                    display.drawButtonI(gl, rowIndex, colIndex)        
                }
            }
        }
    }
}

// vi: se ts=4 sw=4 et:
