package net.oc_soft.mswp.ui

import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLFramebuffer
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLTexture
import org.khronos.webgl.WebGLBuffer
import org.khronos.webgl.Float32Array

import net.oc_soft.filter.Gaussian

/**
 * managing glow effect for rendering
 */
class Bloom(countOfBlur: Int = 10,
    glowButtonVerticesScale: Float = 1.15f,
    var countOfGradation: Int = 15) {


    /**
     * class instance
     */
    companion object {

        /**
         * attach buffer data
         */
        fun attachBufferData(gl: WebGLRenderingContext,
            buffer: WebGLBuffer,
            bufferData: Float32Array) {

            val savedBuffer = gl.getParameter(
                WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?

            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, buffer)
            gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
                bufferData,
                WebGLRenderingContext.STATIC_DRAW)

            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer)
        }

    }

    /**
     * effect texture index
     */
    val effectTextureIndex: Int
        get() = Textures.bloomEffectTextureIndex


    /**
     * count of blur effect
     */
    var countOfBlur = countOfBlur


    /**
     * glowing vertices scale
     */
    var glowButtonVerticesScale = glowButtonVerticesScale

    /**
     * setup glow scene
     */
    fun setup(grid : Grid,
        gl: WebGLRenderingContext) {
        setupGlow(grid, gl)
        setupEffect(grid, gl)
        setupBlinking(grid)
    }

    /**
     * setup glow scene
     */
    private fun setupGlow(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
        val renderingCtx = grid.renderingCtx
        val texture = Scene.createSceneTexture(gl,
            framebufferSize[0], framebufferSize[1]) 
        val depthBuffer = Scene.createRenderDepthBuffer(gl,
            framebufferSize[0], framebufferSize[1])
       

        renderingCtx.setButtonEffect(gl,
            createGlowVerticesBuffer(gl, grid.mineButton),
            createVerticesDarkColor(gl, grid.mineButton),
            createVerticesDarkColor(gl, grid.mineButton))
        
        

        renderingCtx.updateGlowTexture(gl, texture)
        renderingCtx.updateGlowDepthBuffer(gl, depthBuffer)
        renderingCtx.updateGlowFramebuffer(gl, 
            Scene.createFramebuffer(gl, texture!!, depthBuffer!!))
  
    }
    
    /**
     * synchronize color setting with grid settting
     */
    fun syncColorSetting(grid: Grid) {
        updateBlinkingGradientColor(grid)
    }
    
    /**
     * setup blinking
     */
    fun setupBlinking(grid: Grid) {
        val renderingCtx = grid.renderingCtx
        
        renderingCtx.hintButtonBlinking = Blinking()
        renderingCtx.openingButtonBlinking = Blinking() 
        updateBlinkingGradientColor(grid)
    }


    /**
     * update blinking gradient color
     */
    fun updateBlinkingGradientColor(
        grid: Grid) {
        val renderingCtx = grid.renderingCtx
        
        val blinkings = arrayOf(
            renderingCtx.hintButtonBlinking,
            renderingCtx.openingButtonBlinking)
        val vertexColors = createDarkToBrightVertexColors(
                    grid.mineButton, countOfGradation)

        blinkings.forEach {
            if (it != null) {
                it.darkBrightColors = vertexColors
            }
        }
    }

    



    /**
     * setup glow scene
     */
    private fun setupEffect(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
        val renderingCtx = grid.renderingCtx
        val texture = Scene.createSceneTexture(gl,
            framebufferSize[0], framebufferSize[1]) 
        val depthBuffer = Scene.createRenderDepthBuffer(gl,
            framebufferSize[0], framebufferSize[1])
       
        renderingCtx.updateEffectTexture(gl, texture)
        renderingCtx.updateEffectDepthBuffer(gl, depthBuffer)
        renderingCtx.updateEffectFramebuffer(gl, 
            Scene.createFramebuffer(gl, texture!!, depthBuffer!!))
  
    }
    /**
     * update scene size with screen 
     */
    fun syncSizeWithScene(
        grid: Grid,
        gl: WebGLRenderingContext) {
        syncEffectSizeWithScene(grid, gl)
        syncGlowSizeWithScene(grid, gl) 
    }


    /**
     * update scene size with screen 
     */
    private fun syncEffectSizeWithScene(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
        val renderingCtx = grid.renderingCtx
        val effectTexture = renderingCtx.effectTexture
        val effectDepthbuffer = renderingCtx.effectDepthbuffer
        if (effectTexture != null) {
            Scene.updateSceneTextureSize(gl,
                effectTexture, framebufferSize[0], framebufferSize[1])
        }
        if (effectDepthbuffer != null) {
            Scene.updateDepthBufferSize(gl,
                effectDepthbuffer, framebufferSize[0], framebufferSize[1])
        }
    } 


    /**
     * update scene size with screen 
     */
    private fun syncGlowSizeWithScene(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
        val renderingCtx = grid.renderingCtx
        val glowTexture = renderingCtx.glowTexture
        val glowDepthbuffer = renderingCtx.glowDepthbuffer
        if (glowTexture != null) {
            Scene.updateSceneTextureSize(gl,
                glowTexture, framebufferSize[0], framebufferSize[1])
        }
        if (glowDepthbuffer != null) {
            Scene.updateDepthBufferSize(gl,
                glowDepthbuffer, framebufferSize[0], framebufferSize[1])
        }
    } 


    /**
     * draw bloom effect
     */
    fun draw(grid: Grid,
        gl: WebGLRenderingContext) {
        drawGlow(grid, gl)
        drawEffect(grid, gl)
    }

    /**
     * draw effect
     */
    fun drawEffect(grid: Grid,
        gl: WebGLRenderingContext) {
        drawBlur(grid, gl)
    }


    
    /**
     * draw blur scene
     */
    fun drawBlur(grid: Grid,
        gl: WebGLRenderingContext) {
        val savedFramebuffer = gl.getParameter(
            WebGLRenderingContext.FRAMEBUFFER_BINDING) as WebGLFramebuffer?
        val renderingCtx = grid.renderingCtx
        
        val framebuffer = renderingCtx.effectFramebuffer
        
        if (framebuffer != null) {
            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                framebuffer)
            val textures = arrayOf(
                renderingCtx.effect0Texture!!,
                renderingCtx.effect1Texture!!)
            drawBlur0(grid, gl, textures)
        }

        gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
            savedFramebuffer)
    }

    /**
     * apply blur effect
     */
    private fun drawBlur0(grid: Grid,
        gl: WebGLRenderingContext,
        textures: Array<WebGLTexture>) {
        val savedProgram = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        val renderingCtx = grid.renderingCtx
        
        val program = renderingCtx.blurShaderProgram

        if (program != null) {
            gl.useProgram(program) 
            drawBlur1(grid, gl, textures)
        }
        gl.useProgram(savedProgram)
    }

    /**
     * apply blur effect
     */
    private fun drawBlur1(grid: Grid,
        gl: WebGLRenderingContext,
        textures: Array<WebGLTexture>) {
        val prog = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (prog != null) {
            val uTexelSizeLoc = gl.getUniformLocation(prog, "uTexelSize");
            if (uTexelSizeLoc != null) {
                val framebufferSize = Scene.calcFramebufferSizeForScene(gl) 
                val texelSize = Array<Float>(framebufferSize.size) {
                    1f / framebufferSize[it]
                }
                gl.uniform2fv(uTexelSizeLoc, texelSize)
            }
            
            val uCoefficientLoc = gl.getUniformLocation(
                prog, "uSampleCoefficient")
            if (uCoefficientLoc != null) {
                val blur = grid.effector.postRendering.blur 
                val curve = Gaussian(blur.standardDeviation)
                val coefficients = curve.calculate2(blur.size)
            
                gl.uniform1fv(uCoefficientLoc,
                    Array<Float>(coefficients.size, 
                        { coefficients[it].toFloat() }))
            }
            drawBlur2(grid, gl, prog, textures)
        }
    }


    /**
     * render blur effect
     */
    fun drawBlur2(grid: Grid,
        gl: WebGLRenderingContext,
        prog: WebGLProgram,
        textures: Array<WebGLTexture>) {

        val uCoordIndexLoc = gl.getUniformLocation(prog, "uCoordIndex")
        val uSamplerLoc = gl.getUniformLocation(prog, "uSampler");
        if (uCoordIndexLoc != null && uSamplerLoc != null) {
            val savedActiveTexture = gl.getParameter(
                WebGLRenderingContext.ACTIVE_TEXTURE)
            var effectTexNum = effectTextureIndex
            gl.activeTexture(effectTexNum) 
            effectTexNum -= WebGLRenderingContext.TEXTURE0
            gl.uniform1i(uSamplerLoc, effectTexNum)

            for (i in 0 until countOfBlur) {  
                for (direction in 0..1) {
                    val srcTexture = textures[direction % 2]
                    val dstTexture = textures[(direction + 1) % 2]
                    gl.framebufferTexture2D(WebGLRenderingContext.FRAMEBUFFER,
                        WebGLRenderingContext.COLOR_ATTACHMENT0,
                        WebGLRenderingContext.TEXTURE_2D,
                        dstTexture, 0)
                    gl.bindTexture(WebGLRenderingContext.TEXTURE_2D,
                        srcTexture)
                    grid.setupEnv(gl, floatArrayOf(0f, 0f, 0f, 0f))
                    gl.uniform1i(uCoordIndexLoc, direction)
                    grid.screen.drawI(grid, gl) 
                }
            }
            gl.activeTexture(savedActiveTexture as Int)
        }
    }

         


    /**
     * draw glow
     */ 
    fun drawGlow(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx 
        
        val savedFramebuffer = gl.getParameter(
            WebGLRenderingContext.FRAMEBUFFER_BINDING) as WebGLFramebuffer?

        val framebuffer = renderingCtx.glowFramebuffer
        if (framebuffer != null) {
            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER, framebuffer)
            drawGlow0(grid, gl)
        }

        gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER, savedFramebuffer)
        
    }

    /**
     * setup program for glow and draw scene
     */
    internal fun drawGlow0(grid: Grid,
        gl: WebGLRenderingContext) {
        val savedProgram = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        val renderingCtx = grid.renderingCtx
        
        val program = renderingCtx.glowShaderProgram

        if (program != null) {
            gl.useProgram(program) 
            drawGlow1(grid, gl)
        }

        gl.useProgram(savedProgram)
    } 

    /**
     * draw glowing object in scene
     */
    internal fun drawGlow1(grid: Grid,
        gl: WebGLRenderingContext) {
        grid.attachCameraToProjectionMatrix(gl) 
        grid.setupEnv(gl, floatArrayOf(0f, 0f, 0f, 0f))

        drawGlow2(grid, gl)
    }
    
    fun drawGlow2(grid: Grid,
        gl: WebGLRenderingContext) {
        val prog = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        val display = grid.display
        val buttons = grid.buttons
        val renderingCtx = grid.renderingCtx
        if (prog != null) {
            val savedArrayBuffer = gl.getParameter(
                WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?
 
            val aColorLoc = gl.getAttribLocation(prog,
                "aColor")
            val aTexLoc = gl.getAttribLocation(prog,
                "aTextureCoord")
            val uTexSamplerLoc = gl.getUniformLocation(prog,
                "uSampler")

            gl.enableVertexAttribArray(aColorLoc)

            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, 
                renderingCtx.buttonTextureCoordinatesBuffer)
            gl.vertexAttribPointer(
                aTexLoc, 2,
                WebGLRenderingContext.FLOAT,
                false, 0, 0)


            val savedTex = gl.getParameter(
                WebGLRenderingContext.TEXTURE_BINDING_2D) as WebGLTexture?
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D,
                renderingCtx.buttonTexture)
            
            val savedTexNum = gl.getParameter(
                WebGLRenderingContext.ACTIVE_TEXTURE) as Int?
            gl.activeTexture(
                buttons.mineButton.textureIndex0)
            var txtNumber = buttons.mineButton.textureIndex0
            txtNumber -= WebGLRenderingContext.TEXTURE0
            gl.uniform1i(uTexSamplerLoc, txtNumber)
            display.bindButtonVerticesBuffer(gl,
                renderingCtx.buttonGlowBuffer!!)


            val hintColorBuffer = renderingCtx.hintButtonColorBuffer
            val openingColorBuffer = renderingCtx.openingButtonColorBuffer
            if (hintColorBuffer != null && openingColorBuffer != null) {
                for (rowIndex in 0 until display.rowCount) {
                    for (colIndex in 0 until display.columnCount) { 
                        if (buttons.isUserInputingLocation(
                            rowIndex, colIndex)) {
                            updateButtonColor(gl, aColorLoc,
                                openingColorBuffer)
                            drawButton(grid, gl, rowIndex, colIndex) 
                        } else if (buttons.isHintLocation(
                            rowIndex, colIndex)) {
                            updateButtonColor(gl, aColorLoc, 
                                hintColorBuffer)
                            drawButton(grid, gl, rowIndex, colIndex) 
                        }
                    }
                }
            }
            
            if (savedTexNum != null) {
                gl.activeTexture(
                    savedTexNum)
            }
 
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D,
                savedTex)

            display.unbindButtonVerticesBuffer(gl)
            gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER,
                savedArrayBuffer)
        }
    } 

    
    /**
     * draw button
     */
    fun drawButton(grid: Grid,
        gl: WebGLRenderingContext,
        rowIndex: Int,
        colIndex: Int) {
        val display = grid.display
        display.buttonTextureBind(gl, rowIndex, colIndex)
        display.drawButtonI(gl, rowIndex, colIndex)
    } 

    /**
     * set button color
     */
    fun updateButtonColor(gl: WebGLRenderingContext,
        colorLoc: Int,
        buffer: WebGLBuffer) {
        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER,
            buffer)  
              
        gl.vertexAttribPointer( 
                colorLoc,
                3,
                WebGLRenderingContext.FLOAT,
                false,
                0, 0)
 
    }

    /**
     * glow vertices
     */
    fun createGlowVertices(mineButton: MineButton): FloatArray {
        val vertices = mineButton.vertices
        var result = FloatArray(vertices.size) {
            vertices[it] * glowButtonVerticesScale
        }
        return result
    }
            
    /**
     * create glow vertices as float array
     */
    fun createGlowVerticesAsFloat32(mineButton: MineButton): Float32Array {
        val vertices = createGlowVertices(mineButton)
        val result = Float32Array(Array<Float>(vertices.size) {
            vertices[it]
        })
        return result
    }

    /**
     * create glowing vertices buffer
     */
    fun createGlowVerticesBuffer(
        gl: WebGLRenderingContext,
        mineButton: MineButton): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result,
                createGlowVerticesAsFloat32(mineButton))
        }
        return result
    }

    /**
     * create vertices bright color
     */
    fun createVerticesBrightColor(
        gl: WebGLRenderingContext,
        mineButton: MineButton): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result,
                createVerticesBrightColorAsFloat32(mineButton))
        }
        return result
    }

    /**
     * create vertices dark color
     */
    fun createVerticesDarkColor(
        gl: WebGLRenderingContext,
        mineButton: MineButton): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachBufferData(gl, result,
                createVerticesDarkColorAsFloat32(mineButton))
        }
        return result
    }
     

    /**
     * get front glowing color for button
     */  
    fun getFrontGlowingColor(button: MineButton): FloatArray {
        var result = button.frontSpecular
        return result
    }

    /**
     * get back face glowing color for button
     */
    fun getBackGlowingColor(button: MineButton): FloatArray {
        var result = button.backSpecular
        return result
    }

    /**
     * create bright vertices color
     */
    fun createVerticesBrightColor(
        button: MineButton): FloatArray {
        val frontColor = getFrontGlowingColor(button)
        val backColor = getBackGlowingColor(button)
        val verticesColor = button.createColorVertices(frontColor, backColor)

        return verticesColor
    }
    /**
     * create bright vertices color
     */
    fun createVerticesBrightColorAsFloat32(
        button: MineButton): Float32Array {
        val verticesColor = createVerticesBrightColor(button)
        val result = Float32Array(Array<Float>(verticesColor.size) {
            verticesColor[it]
        })

        return result
    }

    /**
     * create dark vertices color
     */
    fun createVerticesDarkColor(
        button: MineButton): FloatArray {
        val frontColor = getFrontGlowingColor(button)
        val backColor = getBackGlowingColor(button)
        val compSize = kotlin.math.min(frontColor.size, backColor.size)

        val darkColor = FloatArray(compSize) { 0f }  

        val verticesColor = button.createColorVertices(darkColor, darkColor)

        return verticesColor
    }
    /**
     * create dark vertices color
     */
    fun createVerticesDarkColorAsFloat32(
        button: MineButton): Float32Array {
        val verticesColor = createVerticesDarkColor(button)
        val result = Float32Array(Array<Float>(verticesColor.size) {
            verticesColor[it]
        })

        return result
    }



    /**
     * create vertex colors from dark to bright
     */
    fun createDarkToBrightVertexColors(
        button: MineButton,
        steps: Int = 30): Array<Float32Array>? {

        var result: Array<Float32Array>? = null
        if (steps > 1) {
            var colors = createDarkToBrightColors(button, steps)!!
            result = Array<Float32Array>(colors.size) {
                val colorVertices = button.createColorVertices(
                    colors[it][0], colors[it][1])
                Float32Array(
                    Array<Float>(colorVertices.size) { colorVertices[it] })
            }  
        } 
        return result 
    }

    /**
     * create colors from dark to bright
     */
    fun createDarkToBrightColors(
        button: MineButton,
        steps: Int = 30): 
        Array<Array<FloatArray>>? {

        var result: Array<Array<FloatArray>>? = null
        if (steps > 1) {
            val frontColor = getFrontGlowingColor(button)
            val backColor = getBackGlowingColor(button)
            val compSize = kotlin.math.min(frontColor.size, backColor.size)

            val darkColor = FloatArray(compSize) { 0f }  
            val colors = arrayOf(
                createGradientColor(darkColor, 
                    frontColor.copyOf(compSize), steps)!!,
                createGradientColor(darkColor,
                    backColor.copyOf(compSize), steps)!!)

            result = Array<Array<FloatArray>>(steps) {
                idx0 ->
                Array<FloatArray>(colors.size) {
                    idx1 ->
                    colors[idx1][idx0] 
                }
            }
        }
        return result
    }
    

    /**
     * create gradient color
     */
    fun createGradientColor(
        color0: FloatArray,
        color1: FloatArray,
        stepCount: Int): Array<FloatArray>? {

       
        var result: Array<FloatArray>? = null
        
        if (stepCount > 1) {
            val componentSize = kotlin.math.min(color0.size, color1.size) 
        
            val colorDisp = FloatArray(componentSize) {
                (color1[it] - color0[it]) / (stepCount - 1).toFloat()
            }
            result = Array<FloatArray>(stepCount) {
                idx0 ->
                FloatArray(componentSize) {
                    color0[it] + colorDisp[it] * idx0
                }
            }
        }
        return result 
    }

}


// vi: se ts=4 sw=4 et:
