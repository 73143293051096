package net.oc_soft.mswp.ui

import org.khronos.webgl.WebGLRenderingContext
import org.khronos.webgl.WebGLTexture
import org.khronos.webgl.WebGLRenderbuffer
import org.khronos.webgl.WebGLFramebuffer
import org.khronos.webgl.WebGLProgram
import org.khronos.webgl.WebGLUniformLocation
import org.khronos.webgl.Float32Array

import net.oc_soft.mswp.ui.grid.Display


/**
 * main scene management
 */
class Scene {

    /**
     * class instance
     */
    companion object {
        /**
         * calculate frame buffer size
         */
        fun calcFramebufferSizeForScene(
            gl: WebGLRenderingContext): IntArray {
            // return calcFramebufferSizeWithCanvas(gl)
            return calcFramebufferSizeWithDrawingBufferSize(gl)
        }
 
        /**
         * calculate frame buffer size
         */
        fun calcFramebufferSizeWithCanvas(
            gl: WebGLRenderingContext): IntArray {
            val canvas = gl.canvas 

            return intArrayOf(
                Display.calcPower2Value(canvas.width),
                Display.calcPower2Value(canvas.height))
        }
        /**
         * calculate frame buffer size
         */
        fun calcFramebufferSizeWithDrawingBufferSize(
            gl: WebGLRenderingContext): IntArray {

            return intArrayOf(
                Display.calcPower2Value(gl.drawingBufferWidth),
                Display.calcPower2Value(gl.drawingBufferHeight))
        }

        /**
         * get scene viewport
         */
        fun getSceneViewport(
            gl: WebGLRenderingContext): IntArray {
            val sceneSize = Scene.calcFramebufferSizeForScene(gl)
            return intArrayOf(0, 0, sceneSize[0], sceneSize[1])
        }

    
        /**
         * calculate viewport to locate a bounds at center in scene viewport.
         */
        fun calcCenterViewport(
            width: Int,
            height: Int,
            gl: WebGLRenderingContext): IntArray {
            val sceneViewport = getSceneViewport(gl)

            val size = intArrayOf(width, height)
            val sceneCenter = FloatArray(2) {
                var res = sceneViewport[it].toFloat()
                res += sceneViewport[it + 2].toFloat() / 2f
                res
            }
            val leftBottom = IntArray(size.size) {
                var res = sceneCenter[it] - size[it].toFloat() / 2f
                kotlin.math.round(res).toInt()
            } 
            val result = leftBottom.copyOf(4)
            result[2] = size[0]
            result[3] = size[1]
            return result
        }

        /**
         * create frame buffer for main scene
         */
        fun createFramebuffer(
            gl: WebGLRenderingContext,
            texture: WebGLTexture,
            depthBuffer: WebGLRenderbuffer): WebGLFramebuffer? {
            val result = gl.createFramebuffer()
            
            val savedFramebuffer = gl.getParameter(
                WebGLRenderingContext.FRAMEBUFFER_BINDING) as WebGLFramebuffer?

            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER, result)
            
            gl.framebufferTexture2D(WebGLRenderingContext.FRAMEBUFFER,
                WebGLRenderingContext.COLOR_ATTACHMENT0,
                WebGLRenderingContext.TEXTURE_2D,
                texture, 0)

            gl.framebufferRenderbuffer(WebGLRenderingContext.FRAMEBUFFER,
                WebGLRenderingContext.DEPTH_ATTACHMENT,
                WebGLRenderingContext.RENDERBUFFER, depthBuffer)
             

            gl.bindFramebuffer(
                WebGLRenderingContext.FRAMEBUFFER,
                savedFramebuffer)

            return result
        }

    

        /**
         * create main scene texture
         */
        fun createSceneTexture(
            gl: WebGLRenderingContext,
            width: Int, height: Int): WebGLTexture? {

            val savedTexture = gl.getParameter(
                WebGLRenderingContext.TEXTURE_BINDING_2D) as WebGLTexture?
            val savedActiveTexture = gl.getParameter(
                WebGLRenderingContext.ACTIVE_TEXTURE) as Int  
            val result = gl.createTexture()
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D, result)
            updateSceneTextureSize(gl, result!!, width, height)

            gl.texParameteri(WebGLRenderingContext.TEXTURE_2D,
                WebGLRenderingContext.TEXTURE_WRAP_S,
                WebGLRenderingContext.CLAMP_TO_EDGE)
            gl.texParameteri(WebGLRenderingContext.TEXTURE_2D,
                WebGLRenderingContext.TEXTURE_WRAP_T,
                WebGLRenderingContext.CLAMP_TO_EDGE)
            gl.texParameteri(WebGLRenderingContext.TEXTURE_2D,
                WebGLRenderingContext.TEXTURE_MIN_FILTER,
                WebGLRenderingContext.LINEAR)

            gl.activeTexture(savedActiveTexture)
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D, savedTexture)
            return result
        }

        /**
         * update scene texture size
         */
        fun updateSceneTextureSize(
            gl: WebGLRenderingContext,
            texture: WebGLTexture,
            width: Int,
            height: Int) {
            val savedTexture = gl.getParameter(
                WebGLRenderingContext.TEXTURE_BINDING_2D) as WebGLTexture?
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D, texture)
            updateSceneTextureSizeI(gl, width, height)
            gl.texImage2D(WebGLRenderingContext.TEXTURE_2D, 0,
                WebGLRenderingContext.RGBA, width, height,
                0, WebGLRenderingContext.RGBA,
                WebGLRenderingContext.UNSIGNED_BYTE, null)
            gl.bindTexture(WebGLRenderingContext.TEXTURE_2D, savedTexture)
        }
        /**
         * update scene texture size
         */
        private fun updateSceneTextureSizeI( 
            gl: WebGLRenderingContext,
            width: Int, height: Int) {
            gl.texImage2D(WebGLRenderingContext.TEXTURE_2D, 0,
                WebGLRenderingContext.RGBA, width, height,
                0, WebGLRenderingContext.RGBA,
                WebGLRenderingContext.UNSIGNED_BYTE, null)
        }

        /**
         * create render depth buffer for main scene
         */
        fun createRenderDepthBuffer(
            gl: WebGLRenderingContext,
            width: Int,
            height: Int): WebGLRenderbuffer? {
            val result = gl.createRenderbuffer()
            if (result != null) {
                updateDepthBufferSize(gl, result, width, height)
            } 
            return result
        }
        /**
         * update depth buffer size
         */
        fun updateDepthBufferSize(
            gl: WebGLRenderingContext,
            depthBuffer: WebGLRenderbuffer,
            width: Int,
            height: Int) {

            val savedRenderbuffer = gl.getParameter(
                WebGLRenderingContext.RENDERBUFFER_BINDING) as
                    WebGLRenderbuffer?
            gl.bindRenderbuffer(WebGLRenderingContext.RENDERBUFFER,
                depthBuffer)
            updateDepthbufferSizeI(gl, width, height)
            gl.bindRenderbuffer(
                WebGLRenderingContext.RENDERBUFFER,
                savedRenderbuffer) 
     
        }

        /**
         * update depth buffer size
         */
        fun updateDepthbufferSizeI(
            gl: WebGLRenderingContext,
            width: Int,
            height: Int) {
            
            gl.renderbufferStorage(WebGLRenderingContext.RENDERBUFFER,
                WebGLRenderingContext.DEPTH_COMPONENT16,
                width, height)  
        }

    }


    /**
     * setup main scene 
     */
    fun setup(
        grid: Grid,
        gl: WebGLRenderingContext) {
       
        val framebufferSize = calcFramebufferSizeForScene(gl) 

        val renderingCtx = grid.renderingCtx
        val texture = createSceneTexture(gl,
            framebufferSize[0], framebufferSize[1])
        val depthBuffer = createRenderDepthBuffer(gl,
            framebufferSize[0], framebufferSize[1])
       
        renderingCtx.updateSceneTexture(gl, texture)
        renderingCtx.updateSceneDepthBuffer(gl, depthBuffer)
        renderingCtx.updateSceneFramebuffer(gl, 
            createFramebuffer(gl, texture!!, depthBuffer!!))
    }

    /**
     * update scene size with screen 
     */
    fun syncSizeWithScreen(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val framebufferSize = calcFramebufferSizeForScene(gl) 
        val renderingCtx = grid.renderingCtx
        val sceneTexture = renderingCtx.sceneTexture
        val sceneDepthbuffer = renderingCtx.sceneDepthbuffer
        if (sceneTexture != null) {
            updateSceneTextureSize(gl,
                sceneTexture, framebufferSize[0], framebufferSize[1])
        }
        if (sceneDepthbuffer != null) {
            updateDepthBufferSize(gl,
                sceneDepthbuffer, framebufferSize[0], framebufferSize[1])
        }
    }


    /**
     * update view
     */
    fun draw(
        grid: Grid,
        gl: WebGLRenderingContext) {

        val renderingCtx = grid.renderingCtx
        val framebuffer = renderingCtx.sceneFramebuffer
        if (framebuffer != null) {
            val savedFramebuffer = gl.getParameter(
                WebGLRenderingContext.FRAMEBUFFER_BINDING)
                as WebGLFramebuffer?

            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                framebuffer)

            draw0(grid, gl)
            drawEdingPoint(grid, gl)
            gl.bindFramebuffer(WebGLRenderingContext.FRAMEBUFFER,
                savedFramebuffer)
        }
    }


    /**
     * draw scene
     */
    fun draw0(grid: Grid,
        gl: WebGLRenderingContext) {
        val shaderProg = grid.renderingCtx.shaderProgram
        if (shaderProg != null) {
            val savedProgram = gl.getParameter(
                WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?;
 
            gl.useProgram(shaderProg) 
            draw1(grid, gl)
            gl.useProgram(savedProgram)
        }
    }

    /**
     * draw pointing light marker if you are editing light point.
     */
    fun drawEdingPoint(grid: Grid,
        gl: WebGLRenderingContext) {
        if (grid.isEditingPointLight) {
            val pointLightEdit = grid.pointLightEdit
            val renderingCtx = grid.renderingCtx 
            val pointShaderProg = renderingCtx.pointShaderProgram
            val glrs = grid.glrs
            val glyph = grid.glyph
            val textures = grid.textures
            if (pointShaderProg != null && glrs != null) {
                val savedProgram = gl.getParameter(
                    WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
 
                gl.useProgram(pointShaderProg)
                attachCameraToProjectionMatrix(grid, gl)
                pointLightEdit.attachModelMatrix(gl, renderingCtx) 
                pointLightEdit.drawScene(gl, renderingCtx,
                    glrs, glyph, textures)
                gl.useProgram(savedProgram)
            }
        }
    }
    /**
     * draw scene
     */
    fun draw1(grid: Grid,
        gl: WebGLRenderingContext) {

        enableLightingAttrib(grid, gl)
        enableCameraAttrib(grid, gl)
        attachCameraToProjectionMatrix(grid, gl)
        enableLightingContext(gl, true)
        enableShadowDepth(gl, true)
        setupShadowSettingForDrawing(grid, gl)
        draw2(grid, gl)
        
    }
    /**
     * draw scene
     */
    fun draw2(grid: Grid,
        gl: WebGLRenderingContext) {
        val display = grid.display
        grid.setupEnv(gl)
        display.drawScene(gl)
    }
    /**
     * enable lighting related Attribute
     */
    private fun enableLightingAttrib(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?

        var pointLight = grid.pointLight 
 
        if (shaderProg != null && pointLight != null) {
            val lightPosLoc = gl.getUniformLocation(shaderProg,
                "uLightPosition")
        
            if (lightPosLoc != null) {
                gl.uniform3f(lightPosLoc, 
                    pointLight.point[0], 
                    pointLight.point[1],
                    pointLight.point[2])
            }

            val light = pointLight.light
            val uLightAmbientLoc = gl.getUniformLocation(shaderProg,
                "uLightAmbient")

            if (uLightAmbientLoc != null) {
                val ambient = light.ambient
                gl.uniform3f(uLightAmbientLoc,
                    ambient[0], ambient[1], ambient[2]) 
            }
            val uLightDiffuseLoc = gl.getUniformLocation(shaderProg,
                "uLightDiffuse")
            if (uLightDiffuseLoc != null) {
                val diffuse = light.diffuse
                gl.uniform3f(uLightDiffuseLoc,
                    diffuse[0], diffuse[1], diffuse[2]) 
            }
            val uLightSpecularLoc = gl.getUniformLocation(shaderProg,
                "uLightSpecular")

            if (uLightSpecularLoc != null) {
                val specular = light.specular
                gl.uniform3f(uLightSpecularLoc,
                    specular[0], specular[1], specular[2])
            }
        }
    } 
    /**
     * enable camera related Attribute
     */
    private fun enableCameraAttrib(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val cam = grid.sceneCamera
        val prog = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (prog != null && cam != null) {
            val eyeLoc = gl.getUniformLocation(prog,
                "uEyePosition")
            if (eyeLoc != null) {
                gl.uniform3f(eyeLoc, 
                    cam.eye[0], 
                    cam.eye[1],
                    cam.eye[2])
            }
        }
    } 

    /**
     * attach camera matrix into projection matrix in shader program
     */
    internal fun attachCameraToProjectionMatrix(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val camMatrix = grid.createCameraMatrix()
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val uProjMat = gl.getUniformLocation(shaderProg, 
                "uProjectionMatrix")    
            if (camMatrix != null) { 
                gl.uniformMatrix4fv(uProjMat, false,
                    camMatrix)
            }
        }
    }

    /**
     * crewate camera matrix
     */
    fun createCameraMatrix(
        grid: Grid): Float32Array? {
        val cam = grid.sceneCamera
        val glrs = grid.glrs
        var result: Float32Array? = null
        if (cam != null && glrs != null) {
            val camCenter = cam.center
            val camEye = cam.eye
            val camUp = cam.up

            val projMat = glrs.matrix_new_perspective(
                cam.fieldOfView, cam.aspect, cam.zNear, cam.zFar)

            glrs.matrix_look_at_mut(projMat,
                camEye[0], camEye[1], camEye[2],
                camCenter[0], camCenter[1], camCenter[2],
                camUp[0], camUp[1], camUp[2])

            result = glrs.matrix_get_components_col_order_32(projMat)

            glrs.matrix_release(projMat)
        }
        return result
    }

    /**
     * enable lighting
     */
    private fun enableLightingContext(
        gl: WebGLRenderingContext,
        enabled: Boolean) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val enableLoc = gl.getUniformLocation(shaderProg,
                "uEnableLighting")
            if (enableLoc != null) {
                fun Boolean.toInt() = if (this) 1 else 0 
                gl.uniform1i(enableLoc, 
                    enabled.toInt());
            }
        }
    } 

    /**
     * enable shadow depth rendering
     */
    private fun enableShadowDepth(
        gl: WebGLRenderingContext,
        enable: Boolean) {
        val shaderProg = gl.getParameter(
            WebGLRenderingContext.CURRENT_PROGRAM) as WebGLProgram?
        if (shaderProg != null) {
            val enableLoc = gl.getUniformLocation(shaderProg,
                "uEnableShadowDepth")
            fun Boolean.toInt() = if (this) 1 else 0 
            gl.uniform1i(enableLoc as WebGLUniformLocation, 
                enable.toInt());
        }
    }
    /**
     * setup shadow setting for drawing
     */
    fun setupShadowSettingForDrawing(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val shadowMap = grid.shadowMap
        shadowMap.setupShadowSettingForDrawing(grid, gl) 
    }
}

// vi: se ts=4 sw=4 et:

