package net.oc_soft.mswp.ui

import kotlin.js.Promise
import kotlin.collections.Map
import kotlin.collections.HashMap
import kotlinx.browser.window
import kotlin.text.toIntOrNull
import kotlin.math.roundToInt
import kotlin.math.min
import kotlin.math.max
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.xhr.FormData
import org.w3c.files.Blob

import net.oc_soft.mswp.ColorScheme
import net.oc_soft.mswp.ColorMaterialConvert
import net.oc_soft.mswp.storage.External
import net.oc_soft.mswp.storage.Codec
import net.oc_soft.mswp.storage.UserStorage
import net.oc_soft.mswp.storage.Session

import net.oc_soft.mswp.Logic

/**
 * manage user interface persistence data.
 */
class Persistence {

   
    /**
     * class instance
     */
    companion object {
        
        /**
         * load icon
         */
        fun loadIcon(): Promise<Map<String, Codec.Icon>?> {
            val result = External.loadIcon()
            return result 
        }

        /**
         * save icon setting
         */
        fun saveIcon(data: Map<String, Codec.Icon>): Promise<Any?> {
            val result = External.saveIcon(data)
            return result
        }

        /**
         * save color scheme 
         */
        fun saveColorScheme(data: ColorScheme): Promise<Any?> {
            val result = External.saveColorScheme(data)
            return result
        }


        /**
         * save point light
         */
        fun savePointLight(
            pointLight: net.oc_soft.mswp.PointLight): Promise<Any?> {
            val result = External.savePointLight(pointLight)
            return result
        }
         
        /**
         * load icon map from json encoded string
         */
        fun loadIconMap(str: String) : Map<String, Codec.Icon>? {
            var result = External.loadIconMap(str) 
            return result
        }

       
        /**
         * load point light location.
         */
        fun loadPointLight() : Promise<net.oc_soft.mswp.PointLight?> {
            val result = External.loadPointLight()
            return result
        }

        /**
         * load color scheme
         */
        fun loadColorScheme(): Promise<ColorScheme?> {
            val result = External.loadColorScheme()
            return result
        }

        /**
         * load effector
         */
        fun loadEffector(): Promise<Effector?> {
            val result = External.loadEffector()
            return result 
        }

        /**
         * load bloom effector
         */
        fun loadBloom(): Promise<Bloom?> {
            val result = External.loadBloom()
            return result 
        }

        /**
         * load color material
         */
        fun loadColorMaterial(): Promise<ColorMaterial?> {
            val result = External.loadColorMaterial()
            return result 
        }
        /**
         * load shader programs
         */
        fun loadShaderPrograms(): Promise<ShaderPrograms?> {
            val result = External.loadShaderPrograms()
            return result
        }

        /**
         * save user screen image
         */
        fun saveUserScreenImage(
            screenImage: Blob,
            type: String = "png"): Promise<String?> {

            val result = Promise<String?> {
                resolve, reject ->
                var imageId = Session.getScreenImageId()
                if (imageId == null) {
                    imageId = ""
                }
                UserStorage.saveStorage(
                    screenImage,
                    type,
                    imageId).then({
                    Session.setScreenImageId(it)
                    resolve(it)
                }).catch({
                    reject(Throwable(it)) 
                })
            }
            return result
        }
        /**
         * save logic
         */
        fun saveLogic(logic: Logic): Promise<Map<String, Any?>> {
            return External.saveLogic(logic)
        }

    }
}

// vi: se ts=4 sw=4 et: 
