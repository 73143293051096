(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bootstrap"), require("@line/liff"), require("@popperjs/core"), require("webfontloader"));
	else if(typeof define === 'function' && define.amd)
		define(["bootstrap", , , ], factory);
	else if(typeof exports === 'object')
		exports["mswp"] = factory(require("bootstrap"), require("@line/liff"), require("@popperjs/core"), require("webfontloader"));
	else
		root["mswp"] = factory(root["bootstrap"], root["liff"], root["Popper"], root["WebFont"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_bootstrap__, __WEBPACK_EXTERNAL_MODULE__line_liff__, __WEBPACK_EXTERNAL_MODULE__popperjs_core__, __WEBPACK_EXTERNAL_MODULE_webfontloader__) {
return 