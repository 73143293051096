
package net.oc_soft.mswp.ui

data class GridSettings(val canvasId : String,
    val glyphCanvasId : String,
    val gameOverModalId : String,
    val playerWonModalId : String,
    val backToMainQuery: String,
    val mainMenuItemQuery: String,
    val iconSetting: IconSetting) {
}

// vi: se ts=4 sw=4 et:
