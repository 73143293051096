package net.oc_soft.mswp
/**
 * light color component
 */
class Light( 
    specular: FloatArray = floatArrayOf(1.0f, 1.0f, 1.0f),
    ambient: FloatArray = floatArrayOf(0.1f, 0.1f, 0.1f),
    diffuse: FloatArray = floatArrayOf(0.1f, 0.1f, 0.1f)) {

    /**
     * ambient
     */
    var ambient: FloatArray = ambient.copyOf(3)    
        get() {
            return field.copyOf()
        }
        set(value) {
            field = value.copyOf(3) 
        }

    /**
     * diffuse
     */
    var diffuse: FloatArray = diffuse.copyOf(3)
        get() {
            return field.copyOf()
        }
        set(value) {
            field = value.copyOf(3) 
        }


    /**
     * specular
     */
    var specular: FloatArray = specular.copyOf(3)
        get() {
            return field.copyOf()
        }
        set(value) {
            field = value.copyOf(3) 
        }
    
    /**
     * constructor
     */
    constructor(other: Light)
        : this(other.specular, other.ambient, other.diffuse) {
    }
    
    /**
     * calculate hash code
     */
    override fun hashCode(): Int {
        val specular = this.specular
        val ambient = this.ambient
        val diffuse = this.diffuse
        
        var result: Int = 0
        result = result xor specular.contentHashCode()
        result = result xor ambient.contentHashCode() 
        result = result xor diffuse.contentHashCode() 
        return result
    }


    /**
     * equals
     */
    override fun equals(other: Any?): Boolean {
        var result =  this === other
        if (!result) {
            result = other is Light
            if (result) {
                val otherLight = other as Light
                val comparisonFields = arrayOf(
                    Pair(this.specular, otherLight.specular),
                    Pair(this.ambient, otherLight.ambient),
                    Pair(this.diffuse, otherLight.diffuse))

                for (i in 0 until comparisonFields.size) {
                    val first = comparisonFields[i].first
                    val second = comparisonFields[i].second
                    result = first contentEquals second
                    if (!result) {
                        break
                    }
                }
            }
        } 
        return result
    }

    
    /**
     * copy each field
     */
    fun copy(other: Light) {
        if (this !== other) {
            this.specular = other.specular
            this.ambient = other.ambient
            this.diffuse = other.diffuse
        }
    }
    
}

// vi: se ts=4 sw=4 et:
