package net.oc_soft.mswp.ui

import kotlin.js.Json
import net.oc_soft.mswp.ColorSchemeContainer
import net.oc_soft.mswp.Environment
import net.oc_soft.mswp.Logic
import net.oc_soft.mswp.LogicContainer

/**
 * setting user interface
 */
class AppSettings(val option : Option) {
    /**
     * option
     */
    data class Option(
        val gameSettingsOption: GameSettings.Option, 
        val environmentOption: Environment.Option,
        val iconOption : IconSelector.Option,
        val colorOption: ColorSelector.Option,
        val pointLightSettingOption: PointLightSetting.Option,
        val usageOption: Usage.Option,
        val flagOption: Flag.Option,
        val shareOption: Share.Option)

    /**
     * runtime configuration
     */
    var runtimeConfig : dynamic = null;

    /**
     * icon selector
     */
    var iconSelector : IconSelector? = null


    /**
     * color selector
     */
    var colorSelector: ColorSelector? = null


    /**
     * usage 
     */
    var usage: Usage? = null

    /**
     * game setting
     */
    var gameSettings: GameSettings? = null


    /**
     * sharing setting
     */
    var share: Share? = null

    /**
     * handle to edit lighting
     */
    var handleToEditLighting: (()->Unit)? = null 

    /**
     * this is called when color selector is visible
     */
    var colorSchemeContainer: ColorSchemeContainer? = null


    /**
     * capture handler
     */
    var capture: Capture? = null


    /**
     * logic container
     */
    var logicContainer: LogicContainer? = null


    /**
     * logic
     */
    val logic: Logic?
        get() {
            var result: Logic? = null
            val logicContainer = this.logicContainer
            if (logicContainer != null) {
                result = logicContainer.logic
            }
            return result
        }

    /**
     * line liff id
     */
    var liffId: String? = null
     
    /**
     * bind to html
     */
    fun bind() {
        unbind()
        gameSettings = GameSettings(option.gameSettingsOption)
        gameSettings?.bind(this)
        iconSelector = IconSelector(option.iconOption)
        iconSelector?.runtimeConfig = runtimeConfig
        iconSelector?.bind()

        share = Share(option.shareOption)
        share?.bind(this)

        colorSelector = ColorSelector(option.colorOption)

        usage = Usage(option.usageOption)
    }

    /**
     * unbind from html
     */
    fun unbind() {
        share?.unbind()
        share = null
        gameSettings?.unbind()
        gameSettings = null
        iconSelector?.unbind()
        iconSelector = null
        colorSelector = null 
        usage = null
    }


    /**
     * edit point light
     */
    fun editPointLight() {
        val handler = handleToEditLighting       
        if (handler != null) {
            handler()
        }
    }

    /**
     * edit color scheme
     */
    fun editColorScheme() {
        colorSelector?.colorSchemeContainer = colorSchemeContainer 
        colorSelector?.show()
    }


    /**
     * make usage dialog visible
     */
    fun visibleUsage() {
        usage?.show()
    }


    /**
     * save start up sequence for line
     */
    fun saveStartupSequenceForLineSharing() {
     
    }
}
// vi: se ts=4 sw=4 et: 
