package net.oc_soft.mswp.ui

import org.w3c.files.Blob

import kotlin.collections.MutableList
import kotlin.collections.ArrayList


/**
 * manage game capture image
 */
class ImageCapture(captureSize: Int = 30) {


    /**
     * images
     */
    val images: MutableList<Blob> = ArrayList<Blob>()


    /**
     * capture handler
     */
    var captureHandler: ((Grid)->Unit)? = null

    /**
     * capture size
     */
    var captureSize: Int = captureSize

    /**
     * image size
     */
    val size: Int get() = images.size


    /**
     * state of capturing
     */
    var capturing: Boolean 
        get() {
            return captureHandler != null
        }
        set(value) {
            if (capturing != value) {
                var newCaptureHandler: ((Grid)->Unit)? = null
                if (value) {
                    newCaptureHandler = {
                        capture1(it)
                    }
                }
                this.captureHandler = newCaptureHandler
            }
        } 

    /**
     * last image
     */
    val lastImage: Blob?
        get() {
            return this[this.size - 1]
        }

    /**
     * index access
     */
    operator fun get(idx: Int): Blob? {
        var result: Blob? = null
        if (0 <= idx && idx < size) {
            result = images[idx]
        }
        return result
    }

    /**
     * capture grid
     */
    fun caputure(grid: Grid) {
        val captureHandler = this.captureHandler  

        if (captureHandler != null) {
            captureHandler(grid)
        }
    }


    /**
     * capture grid phase 1
     */
    fun capture0(grid: Grid) {
        
        if (images.size < captureSize) {
            grid.captureScene().then({
                if (it!= null) {
                    if (this.size < captureSize) {
                        images.add(it)
                    } else {
                        this.captureHandler = {
                            capture1(it)
                        } 
                    }
                } 
                Unit
            })
        } else {
            this.captureHandler = {
                capture1(it)
            } 
        }
    }
    /**
     * capture grid phase 1
     */
    @Suppress("UNUSED_PARAMETER")
    fun capture1(grid: Grid) {
        if (images.size < captureSize) {
            this.captureHandler = {
                capture0(it)
            }
        }
    }
}


// vi: se ts=4 sw=4 et:
