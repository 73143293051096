package line

/**
 * uri action
 */
external interface URIAction: Action {
    
    /**
     * url
     */
    val uri: String


    /**
     * alternative uri
     */
    val altUri: AltURI?
}


/**
 * create URI action
 */
fun createURIAction(
    label: String?,
    uri: String,
    desktopUri: String? = null): URIAction {

    val result: dynamic = object {}
    result["type"] = "uri"
    result["uri"] = uri
    if (label != null) {
        result["label"] = label
    }
    if (desktopUri != null) {
        result["altUri"] = createAltURI(desktopUri)
    }

    return result
}

    
// vi: se ts=4 sw=4 et:
