package net.oc_soft.mswp
import net.oc_soft.mswp.ui.GridSettings
import net.oc_soft.mswp.ui.AppSettings
import net.oc_soft.mswp.ui.IconSelector
import net.oc_soft.mswp.ui.ColorSelector
import net.oc_soft.mswp.ui.PointLightSetting
import net.oc_soft.mswp.ui.IconSetting
import net.oc_soft.mswp.ui.GameSettings
import net.oc_soft.mswp.ui.Flag
import net.oc_soft.mswp.ui.Usage
import net.oc_soft.mswp.ui.Share
import net.oc_soft.mswp.line.ui.Message
/**
 * main page configuration
 */
data class MainPageConfig(
    /** game grid settings */
    val gridSettings : GridSettings = GridSettings("#game_grid", 
            "#glyph_workarea",
            "#game-over-modal",
            "#player-won-modal",
            "#back-to-main",
            ".setting.menu .menu.item",
            IconSetting()),
    /** splash pane */
    val splashPaneId : String = "#splash-pane",
    /** application settings */
    val appSettings : AppSettings.Option = AppSettings.Option(
        GameSettings.Option(
            GameSettings.Option.Queries(
                ".setting.menu .menu.item", 
                ".setting.menu.contents",
                "#label-setting",
                "#color-setting",
                "#lighting-setting",
                "#usage-setting")),
        Environment.Option(
            "#game_grid",
            "body" ,
            ".setting.menu",
            ".menu-bar .flags .flag-icon", 
            arrayOf(".menu-bar .flags .flag-icon",
                ".menu-bar .flags .flag-count"),
            "color-to-transparent-1",
            "#back-to-main svg"),
        IconSelector.Option("#icon-list",
            "#icon-list .icons", 
            "#icon-list .icons li",
            "#icon-list .pagination.container",
            "#icon-list .ok",
            "#icon-kind-selector",
            "svg.svg-inline--fa",
            "#icon_item_tmpl",
            "#blank_icon_item_tmpl",
            "#icons_paginating_full_tmpl",
            "#icons_paginating_middle_tmpl",
            "#icons_paginating_simple_tmpl",
            "#icons_paginating_item_tmpl",
            "#synchronizing-icon",
            gridSettings.iconSetting),
        ColorSelector.Option(
            "#color-selector",
            "#color-selector .oc-color-circle",
            "#color-selector .ok",
            "#color-selector .initial-setting",
            ".colors.container",
            ".color-item-0",
            ".color-item-1",
            ".color-item-3",
            ".color-item-4",
            ".color-item-env-0",
            ".color-item-env-1",
            "color-scheme",
            ".description",
            "#synchronizing-icon"),
        PointLightSetting.Option("#synchronizing-icon-2"),
        Usage.Option("#usage-dialog"),
        Flag.Option(".menu-bar .flags",
            ".flag-icon",
            ".flag-count",
            "blink-color"),
        Share.Option(".menu.share",
            ".share.menu.contents",
            "#line-share",
            "#line-login",
            "#line-logout",
            Message.Option(
                "#line-message-dialog",
                "#line-message-dialog .modal-footer button.btn-primary",
                "#line-message-dialog .modal-content "
                    + ".modal-body .screen-image",
                "#line-message-dialog .modal-content "
                    + ".modal-body textarea.form-control")))) {
}

// vi: se ts=4 sw=4 et: 
