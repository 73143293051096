package net.oc_soft.mswp.ui

import kotlin.text.Regex
import kotlin.text.toInt
import kotlin.math.min
import kotlin.math.max
import kotlin.math.roundToInt
import net.oc_soft.mswp.ColorScheme
import net.oc_soft.mswp.ColorSchemeContainer
import net.oc_soft.mswp.Activity
import popper.Popper
import org.w3c.dom.Node
import org.w3c.dom.ChildNode
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLTemplateElement
import org.w3c.dom.get

import org.w3c.dom.events.Event
import kotlinx.browser.window
import kotlinx.browser.document
/**
 * user interface to select color
 */
class ColorSelector(
    /**
     * option
     */
    val option : Option) {

    /**
     * class instance
     */
    companion object {
        /**
         * rgb to float array
         */
        fun rgbStrToFloatArray(rgb: String): FloatArray? {
            val reg = Regex(
                "^rgb\\s*\\(\\s*"
                + "(\\d+)\\s*,\\s*"
                + "(\\d+)\\s*,\\s*"
                + "(\\d+)\\s*\\)")
            val matchRes = reg.find(rgb)
            var result: FloatArray? = null
            if (matchRes != null) {
                val values = matchRes.groupValues 
                result = FloatArray(3) {
                    values[it + 1].toInt().toFloat() / 255f
                }
            }
            return result
        }
        /**
         * float array to rgb string
         */
        fun floatArrayToRgbStr(value: FloatArray): String? {
            var result: String? = null

            if (value.size > 2) {
                result = "rgb("
                result += "${floatToRgbInt(value[0])},"
                result += "${floatToRgbInt(value[1])},"
                result += "${floatToRgbInt(value[2])}"
                result += ")"
            }
            return result
        }
        /**
         * float value to rgb integer
         */
        fun floatToRgbInt(value: Float): Int {
            return (min(max(value, 0f), 1f) * 255f).roundToInt()
        } 
    }

    /**
     * setting
     */
    data class Option(val modalQuery: String,
        val canvasQuery: String,
        val okQuery: String,
        val initialSettingQuery: String,
        val itemsContainerQuery: String,
        val item0Query: String,
        val item1Query: String,
        val item3Query: String,
        val item4Query: String,
        val itemEnv0Query: String,
        val itemEnv1Query: String,
        val itemClassName: String,
        val descriptionQuery: String,
        val syncIconTemplateQuery: String)

    /**
     * color picker user interface
     */
    var colorPicker: net.oc_soft.color.picker.UI? = null


    /**
     * poppper instances
     */
    var poppers: Array<popper.Instance?>? = null

    /**
     * called this method when modal is hidden
     */
    var modalHiddenHdlr: ((Event)->Unit)? = null

    /**
     * ok button handler
     */
    var okHdlr: ((Event)->Unit)? = null


    /**
     * initial setting button handler
     */
    var initialSettingHdlr: ((Event)->Unit)? = null

    /**
     * called this method when modal is showing.
     */
    var modalShownHdlr: ((Event)-> Unit)? = null

    /**
     * color item click handler
     */
    var colorItemClickHdlr: ((Event)->Unit)? = null

 
    /**
     * handle event form color picker user interface
     */
    var colorPickerHdlr: ((net.oc_soft.color.picker.`T$13`) -> Unit)? = null
   
    /**
     * contens of ok ui control, this list is keeping while ajax request. 
     */
    var okContents: MutableList<Node>? = null 


    /**
     * color scheme
     */
    var colorScheme: ColorScheme?
        get() {
            var result : ColorScheme? = null
            val container = this.colorSchemeContainer
            if (container != null) {
                result = container.colorScheme
            }
            return result
        }
        set(value) {
            val container = this.colorSchemeContainer
            if (container != null) {
                if (value != null) {
                    container.colorScheme = value
                }
            }
        }

    /**
     * color scheme container
     */
    var colorSchemeContainer: ColorSchemeContainer? = null
    

    /**
     * color scheme which is kept in user interface
     */
    var colorSchemeUi: ColorScheme?
        get() {
            var result: ColorScheme? = null

            val colorScheme = this.colorScheme
            if (colorScheme != null) { 
                val color0 = color0Ui
                val color1 = color1Ui
                val color3 = color3Ui
                val color4 = color4Ui
                val colorEnv0 = colorEnv0Ui
                val colorEnv1 = colorEnv1Ui
                if (color0 != null
                    && color1 != null
                    && color3 != null
                    && color4 != null
                    && colorEnv0 != null
                    && colorEnv1 != null) {
                    result = ColorScheme(colorScheme)
                    result[0] = color0                   
                    result[1] = color1
                    result[3] = color3
                    result[4] = color4
                    result.setEnvironment(0, colorEnv0)
                    result.setEnvironment(1, colorEnv1)
                }
            }
            return result
        }
        set(value) {
            if (value != null) {
                color0Ui = value[0]
                color1Ui = value[1]
                color3Ui = value[3]
                color4Ui = value[4]
                colorEnv0Ui = value.getEnvironment(0)
                colorEnv1Ui = value.getEnvironment(1)
            }
        }
    
    /**
     * item 0 color value
     */
    var color0Ui: FloatArray?
        get() {
            return colorItem0Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor)
            }
        }
        set(value) {
            if (value != null) {
                floatArrayToRgbStr(value)?.let {
                    val bgStr = it
                    colorItem0Ui?.let {
                        it.style.backgroundColor = bgStr
                    }
                }
            }
        } 
    /**
     * item 1 color value
     */
    var color1Ui: FloatArray?
        get() {
            return colorItem1Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor) 
            }
        }
        set(value) {
            if (value != null) {
                colorItem1Ui?.let {
                    val elem = it
                    floatArrayToRgbStr(value)?.let {
                        elem.style.backgroundColor = it
                    }
                }
            }
        } 

    /**
     * item 3 color value
     */
    var color3Ui: FloatArray?
        get() {
            return colorItem3Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor) 
            }
        }
        set(value) {
            if (value != null) {
                colorItem3Ui?.let {
                    val elem = it
                    floatArrayToRgbStr(value)?.let {
                        elem.style.backgroundColor = it
                    }
                }
            }
        } 


    /**
     * item 4 color value
     */
    var color4Ui: FloatArray?
        get() {
            return colorItem4Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor) 
            }
        }
        set(value) {
            if (value != null) {
                colorItem4Ui?.let {
                    val elem = it
                    floatArrayToRgbStr(value)?.let {
                        elem.style.backgroundColor = it
                    }
                }
            }
        } 


    /**
     * item 0 environment color value
     */
    var colorEnv0Ui: FloatArray?
        get() {
            return colorItemEnv0Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor) 
            }
        }
        set(value) {
            if (value != null) {
                colorItemEnv0Ui?.let {
                    val elem = it
                    floatArrayToRgbStr(value)?.let {
                        elem.style.backgroundColor = it
                    }
                }
            }
        } 


    /**
     * item 1 environment color value
     */
    var colorEnv1Ui: FloatArray?
        get() {
            return colorItemEnv1Ui?.let {
                rgbStrToFloatArray(it.style.backgroundColor) 
            }
        }
        set(value) {
            if (value != null) {
                colorItemEnv1Ui?.let {
                    val elem = it
                    floatArrayToRgbStr(value)?.let {
                        elem.style.backgroundColor = it
                    }
                }
            }
        } 


    /**
     * selected color scheme
     */
    var selectedSchemeColor: FloatArray?
        get() {
            return selectedColorSchemeItem?.let {
                rgbStrToFloatArray(it.style.backgroundColor)            
            }
        }
        set(value) {
            if (value != null) {
                floatArrayToRgbStr(value)?.let {
                    val bgStr = it
                    selectedColorSchemeItem?.let {
                        it.style.backgroundColor = bgStr
                    }
                }
            }
        }
    /**
     * description
     */
    var descriptionUi: String?
        get() {
            return descriptionItemUi?.let {
                it.innerText
            }
        }
        set(value) {
            descriptionItemUi?.let {
                if (value == null) {
                    it.innerText = ""
                } else {
                    it.innerText = value
                }
            }
        }
    /**
     * description
     */
    val descriptionItemUi: HTMLElement?
        get() {
            return document.querySelector(option.modalQuery)?.let {
                it.querySelector(option.descriptionQuery)?.let{
                    it as HTMLElement   
                }
            }
        }
 
    /**
     * color item 0 user interface
     */
    val colorItem0Ui : HTMLElement?
        get() {
            return getColorItem(option.item0Query)
        }
    /**
     * color item 1 user interface
     */
    val colorItem1Ui : HTMLElement?
        get() {
            return getColorItem(option.item1Query)
        }
    /**
     * color item 3 user interface
     */
    val colorItem3Ui : HTMLElement?
        get() {
            return getColorItem(option.item3Query)
        }
    /**
     * color item 4 user interface
     */
    val colorItem4Ui : HTMLElement?
        get() {
            return getColorItem(option.item4Query)
        }
    /**
     * color item environment 0 user interface
     */
    val colorItemEnv0Ui : HTMLElement?
        get() {
            return getColorItem(option.itemEnv0Query)
        }
    /**
     * color item environment 1 user interface
     */
    val colorItemEnv1Ui : HTMLElement?
        get() {
            return getColorItem(option.itemEnv1Query)
        }

    /**
     * selected color item
     */
    val selectedColorSchemeItem: HTMLElement?
        get() {
            return document.querySelector(option.modalQuery)?.let {
                val modal = it as HTMLElement
                modal.querySelector(option.itemsContainerQuery)?.let {
                    val container = it as HTMLElement
                    container.querySelector(".selected")?.let {
                        it as HTMLElement
                    }
                }
            }
        }

    /**
     * get color item
     */
    fun getColorItem(className: String): HTMLElement? {
        return document.querySelector(option.modalQuery)?.let {
            it.querySelector(className)?.let {
                it as HTMLElement
            } 
        }
    }


    /**
     * visible modal color selector
     */
    fun show() {
        bindModal()
        document.querySelector(option.modalQuery)?.let {
            bootstrap.Modal.getInstance(it as HTMLElement)?.let { it.show() }
        }
    } 

    /**
     * hide modal color selector
     */
    fun hide() {
        document.querySelector(option.modalQuery)?.let {
            bootstrap.Modal.getInstance(it as HTMLElement)?.let { it.hide() }
        }
    }

    /**
     * bind color picker user interface into html element
     */
    fun bindColorPicker() {
        val colorPicker = net.oc_soft.color.picker.UI() 
        colorPicker.template = null
        this.colorPicker = colorPicker
         
         
    }


    /**
     * this is called when modal dailog is showing
     */
    @Suppress("UNUSED_PARAMETER")
    fun onShownModal(event: Event) {
        
        document.querySelector(option.canvasQuery)?.let {
            syncCanvasWithClientSize(it as HTMLCanvasElement)
        }

        document.querySelector(option.modalQuery)?.let {
            this.colorPicker?.bind(it as HTMLElement) 
            this.colorPicker?.addEventListener("pickerLocation", 
                colorPickerHdlr!!)
            this.colorSchemeUi = this.colorScheme 
        }

        val colorItem = colorItem0Ui
        if (colorItem != null) {
            selectColorItem(colorItem)
        }
        return Unit
     }

    


    /**
     * unbind color picker user interface
     */
    fun unbindColorPicker() {
        val colorPicker = this.colorPicker
        colorPicker?.unbind()
        this.colorPicker = null
    }

    /**
     * connect functions into modal user interface.
     */
    fun bindModal() {
        modalHiddenHdlr = { onModalHidden(it) }
        modalShownHdlr = { onShownModal(it) }
        okHdlr = { onOk(it) }
        initialSettingHdlr = { onInitialSetting(it) }
        colorItemClickHdlr = { onColorItemClick(it) }
        colorPickerHdlr = { onMarkColorChanged(it) }
        
        document.querySelector(option.okQuery)?.let {
            (it as HTMLElement).addEventListener("click", okHdlr!!)
        }

        document.querySelector(option.initialSettingQuery)?.let {
            (it as HTMLElement).addEventListener("click", initialSettingHdlr!!)
        }

        document.querySelector(option.modalQuery)?.let {
            val modal = it as HTMLElement    
            modal.addEventListener("hidden.bs.modal", modalHiddenHdlr!!)
            modal.addEventListener("shown.bs.modal", this.modalShownHdlr!!)
            modal.querySelector(option.itemsContainerQuery)?.let {
                (it as HTMLElement).addEventListener("click",
                    colorItemClickHdlr!!)
            }
            bootstrap.Modal(modal)
        }
        setupContents()
    }
    /**
     * detach functions from modal user interface.
     */
    fun unbindModal() {
        teardownContents()

        document.querySelector(option.modalQuery)?.let {
            val modal = it as HTMLElement
            modalHiddenHdlr?.let { 
                modal.removeEventListener("hidden.bs.modal", it)
                modalHiddenHdlr = null
            }
            modalShownHdlr?.let {
                modal.removeEventListener("shown.bs.modal", it)
                modalShownHdlr = null
            }

            colorItemClickHdlr?.let {
                val hdlr = it
                modal.querySelector(option.itemsContainerQuery)?.let {
                    (it as HTMLElement).removeEventListener("click", hdlr)
                }
                colorItemClickHdlr = null
            }
            bootstrap.Modal.getInstance(modal)?.let {
                it.dispose()
            }
        }
        selectedColorSchemeItem?.let {
            it.classList.remove("selected")
        }

        colorSchemeContainer = null

        initialSettingHdlr?.let  {
            val hdlr = it
            document.querySelector(
                option.initialSettingQuery)?.let {
                (it as HTMLElement).removeEventListener("click", hdlr)
            }
            initialSettingHdlr = null
        }
        okHdlr?.let {
            val hdlr = it
            document.querySelector(option.okQuery)?.let {
                (it as HTMLElement).removeEventListener("click", hdlr) 
            }
            okHdlr = null
        }
        if (colorPickerHdlr != null) {
            val colorPicker = this.colorPicker  
            colorPicker?.removeEventListener(null, 
                colorPickerHdlr!!)
            colorPickerHdlr = null
        }
    }

    /**
     * setup modal dialog contents
     */
    fun setupContents() {
        bindColorPicker()
    }

    /**
     * tear down modal dialog contents
     */
    fun teardownContents() {
        unbindColorPicker()
    }

    /**
     * handle the event to hide modal
     */
    @Suppress("UNUSED_PARAMETER")
    fun onModalHidden(e: Event) {
        unbindModal()
    }

    /**
     * handle the click event on color item
     */
    @Suppress("UNUSED_PARAMETER")
    fun onColorItemClick(e: Event) {
        val target = e.target as HTMLElement
        if (target.classList.contains(option.itemClassName)) {
            window.setTimeout({ 
                selectColorItem(target) 
            })           
            Activity.record()
        } 
    }


    /**
     * handle picker marker changed event
     */
    fun onMarkColorChanged(e: net.oc_soft.color.picker.`T$13`) {
        when (e.type) {
            "pickerLocation",
            "indexValue" -> {
                window.setTimeout(
                    { syncSeletectedSchemeColorWithColorPicker() })  
                Activity.record()
            }
        }
    }

    /**
     * select color item
     */
    fun selectColorItem(colorItem: HTMLElement) {
        if (!colorItem.classList.contains("selected")) {
            colorItem.parentElement?.let {
                val elems = it.children
                for (idx in 0 until elems.length) {
                    elems[idx]?.let { it .classList.remove("selected") }
                }
            }

            colorItem.classList.add("selected")
            window.setTimeout({
                syncColorPickerWithSelectedSchemeColor()
            })  
            window.setTimeout({
                syncDescriptionWithSelection()
            })
        }
    }

    /**
     * synchronize description with selection
     */
    fun syncDescriptionWithSelection() {
        document.querySelector(option.modalQuery)?.let {
            val modal = it as HTMLElement
            descriptionUi = modal.querySelector(
                option.itemsContainerQuery)?.let {
                val container = it as HTMLElement
                container.querySelector(".selected")?.let {
                    (it as HTMLElement).dataset["tooltip"]
                }
            }
        }
    }

    /**
     * synchronize color picker with selected scheme color
     */
    fun syncColorPickerWithSelectedSchemeColor() {
        val color = selectedSchemeColor
        val picker = colorPicker
        if (picker != null && color != null) {
            val color255 = Array<Number>(color.size) {
                color[it] * 255
            }
            val savedPickerHdlr = colorPickerHdlr
            picker.removeEventListener("pickerLocation", colorPickerHdlr!!)
            picker.markColor = color255
            picker.addEventListener(null, savedPickerHdlr!!)
        }
    }

    /**
     * synchronize selected scheme color with color picker
     */
    fun syncSeletectedSchemeColorWithColorPicker() {
        val picker = colorPicker
        if (picker != null) {
            val color255 = picker.markColor
            if (color255 != null) {
                val color = FloatArray(color255.size) {
                    color255[it].toFloat() / 255f 
                }
                 
                selectedSchemeColor = color
            }
        }
    }

    /**
     * handle ok
     */
    @Suppress("UNUSED_PARAMETER")
    fun onOk(e: Event) {
        postSave()
        // update session
        Activity.record()
    }

    /**
     * handle initial setting
     */
    @Suppress("UNUSED_PARAMETER")
    fun onInitialSetting(e: Event) {
        val colorScheme = ColorScheme()
        this.colorSchemeUi = colorScheme
        window.setTimeout({
            syncColorPickerWithSelectedSchemeColor()
        })
        // update session
        Activity.record()

        return Unit
    }

    /**
     * do save setting
     */
    private fun postSave() {
        window.setTimeout({ doSave() })
    }

    /**
     * save color scheme
     */
    fun doSave() {
        val colorScheme = this.colorSchemeUi
        if (colorScheme != null) {
            changeUiOkToSync()
            Persistence.saveColorScheme(colorScheme).then({
                this.colorScheme = colorScheme
                restoreOkFromSync()
                hide()
            }, {
                restoreOkFromSync()
            })
        }
    }

    /**
     * change ok to synchronizing icon
     */
    fun changeUiOkToSync() {
 
        document.querySelector(option.syncIconTemplateQuery)?.let {
            val tmp = it as HTMLTemplateElement 
            tmp.content.firstElementChild?.let {
                val newElement = it.cloneNode(true)
                document.querySelector(option.okQuery)?.let {
                    val savedContents = ArrayList<Node>()
                    val ok = it as HTMLElement  
                    val nodes = ok.childNodes
                    for (idx in 0 until nodes.length) {
                        @Suppress("UNCHECKED_CAST")
                        val childNode = nodes[idx] as ChildNode
                        savedContents.add(nodes[idx]!!)
                        childNode.remove()
                    }
                    ok.append(newElement)
                    okContents = savedContents 
                }
            }
        }
    }

    /**
     * restore ok from synchronizing icon
     */
    fun restoreOkFromSync() {
        okContents?.let {
            val savedOkNodes = it
            document.querySelector(option.okQuery)?.let {
                val elem = it 
                while (elem.childElementCount > 0) {
                    elem.firstElementChild?.let {
                        it.remove()
                    }
                }
                savedOkNodes.forEach {
                    elem.appendChild(it)
                }
            }
            okContents = null
        } 
    }
 
    /**
     * synchronize canvas size with client size.
     */
    private fun syncCanvasWithClientSize(
        canvas: HTMLCanvasElement) {

        var doResize: Boolean
        doResize = canvas.width != canvas.clientWidth
        if (!doResize) {
            doResize = canvas.height != canvas.clientHeight
        }
        if (doResize) {
            canvas.width = canvas.clientWidth
            canvas.height = canvas.clientHeight
        }  
    }
}

// vi: se ts=4 sw=4 et:
