
package net.oc_soft.mswp

/**
 * context for this app
 */
object Context {

    var mainPage: MainPage? = null
 
}
