package net.oc_soft.mswp.line.ui

import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event

import kotlin.collections.ArrayList
import kotlin.collections.MutableList


class Message(
    /**
     * option for user interface
     */
    option: Option) {

    /**
     * message option
     */
    data class Option(
        /**
         * modal html element query
         */
        val modalQuery: String,
        /**
         * send button html element query
         */
        val sendButtonQuery: String,
        /**
         * modal content img html element 
         */
        val imageQuery: String,
        /**
         * message text html element  query
         */
        val messageTextQuery: String)

    /**
     * the option when you show dialog
     */
    data class ShowingOption(
        val imageUrl: String)

    /**
     * option
     */
    var option: Option = option


    /**
     * handler to respond the shown event
     */
    var shownModalHdlr: ((Event)->Unit)? = null
    
    /**
     * handler to respond the event at instanciating modal
     */
    var showModalHdlr: ((Event)->Unit)? = null

    /**
     * handler to respond the event to send message 
     */
    var sendMessageHdlr: ((Event)->Unit)? = null
    

    /**
     * event listeners
     */
    val eventListeners: MutableList<(String, Message)->Unit> =
        ArrayList<(String, Message)->Unit>()

    /**
     * dialog content image element
     */
    val contentImageElementUi: HTMLElement?
        get() {
            return document.querySelector(option.imageQuery)?.let {
                it as HTMLElement
            }
        }

    /**
     * html element which contains message for sharing
     */
    val messageElementUi: HTMLElement?
        get() {
            return document.querySelector(option.messageTextQuery)?.let {
                it as HTMLElement
            }
        }

    /**
     * message element
     */
    var messageUi: String?
        get() {
            return messageElementUi?.let {
                if (it is HTMLTextAreaElement) {
                    it.value
                } else {
                    null
                }
            }
        }
        set(value) {
            this.messageElementUi?.let {
                if (it is HTMLTextAreaElement) {
                    it.value = if (value != null) { value } else { "" }
                }
            }
         }


    /**
     * handler to respond the event at hidden modal
     */
    var hiddenModalHdlr: ((Event)->Unit)? = null


    /**
     * add event listener
     */
    fun addEventListener(eventListener: (String, Message)->Unit) {
        eventListeners.add(eventListener)
    }

    /**
     * remove event listener
     */
    fun removeEventListener(eventListener: (String, Message)->Unit) {
        eventListeners.remove(eventListener)
    }


    /**
     * notify event
     */
    fun notify(event: String) {
          
        val listeners = ArrayList<(String, Message)->Unit>(eventListeners)
        listeners.forEach {
            it(event, this)
        }
    }

    /**
     * visible modal usage dialog
     */
    fun show(
        showingOption: ShowingOption) {
        bindModal(showingOption)
        document.querySelector(option.modalQuery)?.let {
            bootstrap.Modal.getInstance(it as HTMLElement)?.let {
                it.show()
            }
        }
    }

    /**
     * hide modal color selector
     */
    fun hide() {
        document.querySelector(option.modalQuery)?.let {
            bootstrap.Modal.getInstance(it as HTMLElement)?.let {
                it.hide()
            }
        }    
    }


    /**
     * connect this object into html elements
     */
    fun bindModal(
        option: ShowingOption) {
        showModalHdlr = { onShowModal(it, option) }
        shownModalHdlr = { onShownModal(it) }
        hiddenModalHdlr = { onHiddenModal(it) }
        sendMessageHdlr = { onSendMessage(it) }
        document.querySelector(this.option.modalQuery)?.let {
            it.addEventListener("hidden.bs.modal", hiddenModalHdlr!!)
            it.addEventListener("shown.bs.modal", this.shownModalHdlr!!)
            it.addEventListener("show.bs.modal", this.showModalHdlr!!) 
            bootstrap.Modal(it as HTMLElement)
        }

        document.querySelector(this.option.sendButtonQuery)?.let {
            it.addEventListener("click", sendMessageHdlr!!)
        }
    }

    /**
     * disconnect this object from html elements
     */
    fun unbindModal() {
        messageUi = null
        document.querySelector(option.modalQuery)?.let {
            val modalElem = it as HTMLElement
            shownModalHdlr?.let {
                modalElem.removeEventListener("shown.bs.modal", it)
            }
            showModalHdlr?.let  {
                modalElem.removeEventListener("show.bs.modal", it)
            }

            hiddenModalHdlr?.let {
                modalElem.removeEventListener("hidden.bs.modal", it)
            }
            bootstrap.Modal.getInstance(modalElem)?.let {
                it.dispose()
            } 

        }
        shownModalHdlr = null
        showModalHdlr = null
        hiddenModalHdlr = null
        sendMessageHdlr?.let {
            val hdlr = it
            document.querySelector(option.sendButtonQuery)?.let {
                it.removeEventListener("click", hdlr) 
            }
            sendMessageHdlr = null
        }
    }


    /**
     * called when the modal is instantiated
     */
    @Suppress("UNUSED_PARAMETER")
    fun onShowModal(event: Event, option: ShowingOption)  {
        this.contentImageElementUi?.let {
            val imageElement = it as HTMLImageElement 
            imageElement.src = option.imageUrl
        }
    }

    
    /**
     * this is called when modal dailog is showing
     */
    @Suppress("UNUSED_PARAMETER")
    fun onShownModal(event: Event) {
    }

    /**
     * handle the event to hide modal
     */
    @Suppress("UNUSED_PARAMETER")
    fun onHiddenModal(e: Event) {
        notify("close")
        unbindModal()
    }
 

    /**
     * handle the event to send message
     */
    @Suppress("UNUSED_PARAMETER")
    fun onSendMessage(e: Event) {
        notify("sendMessage")
        this.hide()
    }
}

// vi: se ts=4 sw=4 et:
