package net.oc_soft.mswp.ui

import org.khronos.webgl.*
import net.oc_soft.mswp.*
import kotlin.math.min

/**
 * game board
 */
class Board(
    colorScheme: ColorScheme,
    colorMaterialConvert: ColorMaterialConvert = ColorMaterialConvert(
        1f, 0.01f, 0.05f, 0.8f)) {
    /**
     * cache of vertices
     */
    private var verticesCache : FloatArray? = null
   
    val vertices : FloatArray
        get() {
            if (verticesCache == null) {
                verticesCache = 
                    Polygon.divideSquare2(size,
                        polygonFactor[0].toInt(), 
                        polygonFactor[1].toInt())
            }
            return verticesCache!!
        }
 
    val verticesAsFloat32 : Float32Array
        get() {
            val vertices = this.vertices
            val result = Float32Array(
                Array<Float>(vertices.size) { i -> vertices[i] })
            return result
        }

    /**
     * normal vector's cache
     */
    private var normalVecCache : FloatArray? = null
    
    /**
     * normal vectors
     */
    val normalVectors : FloatArray
        get() {
            if (normalVecCache == null) {
                normalVecCache = 
                    Polygon.createNormalVectorsForTriangles(vertices)
            }
            return normalVecCache!!
        }
    val normalVectorsAsFloat32 : Float32Array
        get() {
            val normalVectors = this.normalVectors 
            val result = Float32Array(Array<Float>(normalVectors.size) {
                i -> normalVectors[i]
            })
            return result 
        }

    /**
     * normal vector
     */ 
    val normalVector: FloatArray
        get() {
            return normalVectors.sliceArray(0..2)
        }

    /**
     * specular
     */
    var specular: FloatArray
        get() {
            return material.specular
        }
        set(value) {
            material.specular = value
        }

    /**
     * ambient 
     */
    var ambient: FloatArray
        get() {
            return material.ambient
        }
        set(value) {
            material.ambient = value
        }

    /**
     * diffuse 
     */
    var diffuse: FloatArray
        get() {
            return material.diffuse
        }
        set(value) {
            material.diffuse = value
        }

    /**
     * shiness 
     */
    var shiness: Float
        get() {
            return material.shiness
        }
        set(value) {
            material.shiness = value
        }

    /**
     * color material converter
     */
    var colorMaterialConvert = colorMaterialConvert
 
    /**
     * material
     */
    var material: Material =
        colorMaterialConvert(colorScheme[ColorScheme.Board])
    

    /**
     * vertex speculars
     */
    val verticesSpecular: FloatArray
        get() {
            val vertices = this.vertices
            val specular = this.specular
            return FloatArray((vertices.size / 3) * specular.size) {
                i -> specular[i % specular.size] 
            }
        }
    val verticesSpecularAsFloat32 : Float32Array
        get() {
            val verticesSpecular = this.verticesSpecular
            val result = Float32Array(Array<Float>(verticesSpecular.size) {
                i -> verticesSpecular[i]
            })
            return result 
        }

    /**
     * vertex ambient 
     */
    val verticesAmbient: FloatArray
        get() {
            val vertices = this.vertices
            val ambient = this.ambient
            return FloatArray((vertices.size / 3) * ambient.size) {
                ambient[it % ambient.size] 
            }
        }
    val verticesAmbientAsFloat32 : Float32Array
        get() {
            val verticesAmbient = this.verticesAmbient
            val result = Float32Array(Array<Float>(verticesAmbient.size) {
                verticesAmbient[it]
            })
            return result 
        }

    /**
     * vertex diffuse 
     */
    val verticesDiffuse: FloatArray
        get() {
            val vertices = this.vertices
            val diffuse = this.diffuse
            return FloatArray((vertices.size / 3) * diffuse.size) {
                diffuse[it % diffuse.size] 
            }
        }
    val verticesDiffuseAsFloat32 : Float32Array
        get() {
            val verticesDiffuse = this.verticesDiffuse
            val result = Float32Array(Array<Float>(verticesDiffuse.size) {
                verticesDiffuse[it]
            })
            return result 
        }

    /**
     * vertex shiness 
     */
    val verticesShiness: FloatArray
        get() {
            val vertices = this.vertices
            val shiness = this.shiness
            return FloatArray(vertices.size / 3)  { shiness }
        }
    val verticesShinessAsFloat32 : Float32Array
        get() {
            val verticesShiness = this.verticesShiness
            val result = Float32Array(Array<Float>(verticesShiness.size) {
                verticesShiness[it]
            })
            return result 
        }


    
    /**
     * board size
     */
    val size : FloatArray = floatArrayOf(1.0f, 1.0f)

    /**
     * (left, bottom) - (right, bottom) - (right, top) - (left, top)  on board
     */
    val bounds: Array<FloatArray>
        get() {
            return arrayOf(
                floatArrayOf(
                    - size[0] / 2.0f, - size[1] / 2.0f, 0.0f),
                floatArrayOf(
                    size[0] / 2.0f, - size[1] / 2.0f, 0.0f),
                floatArrayOf(
                    size[0] / 2.0f, size[1] / 2.0f, 0.0f),
                floatArrayOf(
                    - size[0] / 2.0f, size[1] / 2.0f, 0.0f)) 
        }

    /**
     * polygon factor
     */
    val polygonFactor = shortArrayOf(1, 1)

    /**
     * drawing mode
     */
    val drawingMode = WebGLRenderingContext.TRIANGLES

    /**
     * texture coordinates
     */
    var textureCoordinatesCache : FloatArray? = null

    /**
     * texture coordinates
     */
    val textureCoordinates : FloatArray
        get() {
            if (textureCoordinatesCache == null) {
                textureCoordinatesCache = createTextureCoordinates()
            }
            return textureCoordinatesCache!!
        }

    val textureCoordinatesAsFloat32 : Float32Array
        get() {
            val textureCoordinates = this.textureCoordinates
            val result = Float32Array(Array<Float>(textureCoordinates.size) {
                textureCoordinates[it]
            })
            return result
        }
 
    /**
     * textures
     */
    var textures: Textures? = null

    /**
     * transpanent texture
     */
    val transparentTexture: WebGLTexture?
        get() {
            return getTransparentTexture()
        }

    /**
     * update color scheme
     */
    fun updateColorScheme(colorScheme: ColorScheme) {
        val color = colorScheme[ColorScheme.Board]
        material = colorMaterialConvert(color)
    }

    /**
     * create vertices color
     */
    fun createVerticesColor(color: FloatArray) : Float32Array {
        val verticesColor = Array<Float>((vertices.size / 3) * color.size) {
            i ->color[i % color.size]
        }
        return Float32Array(verticesColor)
    }

    /**
     * setup board
     */
    internal fun setup(
        grid: Grid,
        gl: WebGLRenderingContext) {
        setupVertices(grid, gl)
        setupNormalVector(grid, gl)
        setupMaterial(grid, gl)
        setupTextureCoordinate(grid, gl)
    }

    /**
     * setup material
     */
    private fun setupMaterial(
        grid: Grid,
        gl: WebGLRenderingContext) {
        updateMaterial(grid, gl)
    }
    /**
     * update material
     */
    internal fun updateMaterial(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        var specularBuffer = gl.createBuffer()
        if (specularBuffer != null) {
            attachDataToBuffer(gl, specularBuffer, verticesSpecularAsFloat32)
        }
        val ambientBuffer = gl.createBuffer()
        if (ambientBuffer != null) {
            attachDataToBuffer(gl, ambientBuffer, verticesAmbientAsFloat32)
        }
        val diffuseBuffer = gl.createBuffer()
        if (diffuseBuffer != null) {
            attachDataToBuffer(gl, diffuseBuffer, verticesDiffuseAsFloat32)
        }
        val shinessBuffer = gl.createBuffer()
        if (shinessBuffer != null) {
            attachDataToBuffer(gl, shinessBuffer, verticesShinessAsFloat32)
        }
 
        renderingCtx.setBoardMaterialbuffer(gl,
            specularBuffer, ambientBuffer, diffuseBuffer, shinessBuffer)
    }

    /**
     * setup vertices
     */
    fun setupVertices(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx  

        renderingCtx.boardBuffer = createVerticesBuffer(grid, gl)
    }

    /**
     * create vertices buffer
     */
    @Suppress("UNUSED_PARAMETER")
    fun createVerticesBuffer(grid: Grid,
        gl: WebGLRenderingContext): WebGLBuffer? {
        var result = gl.createBuffer()
        if (result != null) {
            attachDataToBuffer(gl, result, verticesAsFloat32)
        }
        return result
    }

    /**
     * setup normal vectors 
     */
    fun setupNormalVector(grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.boardNormalVecBuffer = createNormalVectorBuffer(
            grid, gl)
    }
 
    /**
     * setup normal vector
     */
    @Suppress("UNUSED_PARAMETER")
    fun createNormalVectorBuffer(grid: Grid,
        gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachDataToBuffer(gl, result, normalVectorsAsFloat32)
        }
        return result
    }


    /**
     * setup texture coordinate
     */
    private fun setupTextureCoordinate(
        grid: Grid,
        gl: WebGLRenderingContext) {
        val renderingCtx = grid.renderingCtx
        renderingCtx.boardTextureCoordinateBuffer = 
            createTextureCoordinateBuffer(grid, gl)
         
    }


    /**
     * setup texture coordinate
     */
    @Suppress("UNUSED_PARAMETER")
    private fun createTextureCoordinateBuffer(
        grid: Grid,
        gl: WebGLRenderingContext): WebGLBuffer? {
        val result = gl.createBuffer()
        if (result != null) {
            attachDataToBuffer(gl, result, textureCoordinatesAsFloat32)
        }
        return result
    }


    /**
     * attach buffer data to gl-buffer
     */
    private fun attachDataToBuffer(
        gl: WebGLRenderingContext,
        buffer: WebGLBuffer,
        bufferData: Float32Array) {
        val savedBuffer = gl.getParameter(
            WebGLRenderingContext.ARRAY_BUFFER_BINDING) as WebGLBuffer?

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, buffer)
        gl.bufferData(WebGLRenderingContext.ARRAY_BUFFER,
            bufferData,
            WebGLRenderingContext.STATIC_DRAW)

        gl.bindBuffer(WebGLRenderingContext.ARRAY_BUFFER, savedBuffer)
    }
 
    /**
     * create texture coordinates
     */
    fun createTextureCoordinates(): FloatArray {
        val result = Polygon.divideSquare2d2(floatArrayOf(1f, 1f), 
                polygonFactor[0].toInt(), 
                polygonFactor[1].toInt())
        return result
    }    
     
    /**
     * get transparent texture
     */
    fun getTransparentTexture() : WebGLTexture? {
        var textures = this.textures
        var result : WebGLTexture? = null
        if (textures != null) {
            result = textures.blackTransparentTexture 
        }
        return result
    }
}

// vi: se ts=4 sw=4 et:
